import AnalyticsContext from "../AnalyticsContext";

import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import GraphUtil from "./Graphs/GraphUtil";

class AdLatency_Daily_Line {
    static getId() {
        return "AdLatency_Daily_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "Ad-Latency",
            xAxis: "time",
            yAxis: "time"
        };
    }

    static getRequiredProps() {
        return ["startDate","endDate","filter"];
    }

    static getGraphData(_props, _cb) {
        let ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        mergedFilters.day = { from : _props.startDate, to: _props.endDate };
        ctx.sendAsync("latency", {type:"analytics_v3", subType: "es_adLatency_v3", group: ["day",_props.group], filter: mergedFilters});
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let report = GraphUtil.generateTimeSeries(_data.latency.result.result, "day","latency", _props.group);
                return _cb(null, report);
            }
        });
    }

}

export default CachedGraph(AdLatency_Daily_Line, LineGraph);