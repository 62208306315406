import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import AdImpressionsPilLine from "./AdImpressions_Pil_Line";
import AdViewersBar from "./AdViewers_Bar";
import ABAdsDetailed from "./AB_AdsDetailed";
import RevenuePie from "./Revenue_Pie";


class AB_Ads extends React.Component {
    render() {


        return (
            <Fragment>
                <Row>
                    <h3 className="text-center mt-3">Ad Statistics</h3>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <AdImpressionsPilLine group="ab_group" filter={this.props.filter} average/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <AdViewersBar group="ab_group" filter={this.props.filter} />
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <RevenuePie onlyAds group="ab_group" filter={this.props.filter} legend/>
                    </Col>
                </Row>
                <ABAdsDetailed filter={this.props.filter}/>
            </Fragment>
        );
    }
}

export default AB_Ads;