import AnalyticsContext from "../AnalyticsContext";
import PieGraph from "./Graphs/PieGraph";
import CachedGraph from "./Graphs/CachedGraph";

class Rating_Pie {
    static getId() {
        return "Rating_Pie";
    }

    static getGraphProps(_props) {
        const title = _props.title ? _props.title : "Rating";
        return {
            title: title,
            yAxis: "Users",
            small: true,
            legend: _props.legend
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("rating",{type: "analytics_v3", subType : "es_rating_v3", group: ["rating",_props.group], filter: mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let graphData = [{name: "default", data : []}];
                for (let i = 0; i < _data.rating.result.result.length; i++) {
                    const entry = _data.rating.result.result[i];
                    graphData[0].data.push({name: entry.rating, y: parseInt(entry.samples, 10)})
                }
                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(Rating_Pie, PieGraph);