import React, {Fragment} from "react";
import AnalyticsContext from "./AnalyticsContext";
import {formatDate} from "../common/TimeUtil";
import KeyGenerator from "../common/KeyGenerator";
import CampaignPL from "./Components/CampaignPL";
import LoadingBannerUI from "../common/LoadingBannerUI";
import withURLParams from "../common/SiteWithUrlParams";
import withFilter from "./SiteWithFilter";
import GraphCache from "./Components/Graphs/GraphCache";
import ErrorBannerUI from "../common/ErrorBannerUI";
import Toggle from "react-toggle";
import {Col, Row} from "reactstrap";

class CampaignPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : true,
            error : null,
            updateCounter : 1,
            showActive: true,
            campaigns : [],
        };

        this.updateCampaigns = this.updateCampaigns.bind(this);
        this.loadingBanner = this.loadingBanner.bind(this);
        this.errorBanner = this.errorBanner.bind(this);
        this.onShowActiveChanged = this.onShowActiveChanged.bind(this);
    }

    componentDidMount() {
        this.updateCampaigns();
    }

    onShowActiveChanged() {
        this.setState((_state, _props) => {
            return {
                updateCounter : _state.updateCounter +1,
                showActive : !_state.showActive
            };
        });
    }



    updateCampaigns() {
        const hash = GraphCache.getHash("CampaignPage", {game_id : this.props.urlParams.gameId, dateSelection: this.props.dateSelection});
        const cachedData = GraphCache.getData(hash);
        if (cachedData) {
            return this.setState((_state, _props) => {
                return {
                    isLoading: false,
                    campaigns : cachedData,
                    error: null
                };
            });
        }
        const from = formatDate(this.props.dateSelection.start);
        const to = formatDate(this.props.dateSelection.end);
        this.setState(() => {
            return {
                isLoading : true,
                campaigns : [],
                error : null};
        });
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("campaigns", {type : "analytics_v3", subType: "es_user_v3", group: ["install_date","ua_campaign"],filter: {game_id : this.props.urlParams.gameId, install_date : {from: from, to: to}}});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                this.setState({error: { title : "Couldn't retrieve Campaigns", text : _err.code+": "+_err.message}, isLoading: false});
            } else {
                let campaigns = {};
                const tokens = to.split("-");
                const lastDay = Date.UTC(parseInt(tokens[0],10),parseInt(tokens[1],10)-1, parseInt(tokens[2],10));
                for (let i = 0; i < _data.campaigns.result.result.length; i++) {
                    const entry = _data.campaigns.result.result[i];
                    if (!campaigns[entry.ua_campaign]) {
                        campaigns[entry.ua_campaign] = {users : 0, cost:0, isActive : false};
                    }
                    const date = Date.parse(entry.install_date);
                    const users = parseFloat(entry.installs);
                    const cost = entry.cpi;
                    campaigns[entry.ua_campaign].users += users;
                    campaigns[entry.ua_campaign].cost += cost;
                    if(date === lastDay && entry.cpi > 0) {
                        campaigns[entry.ua_campaign].isActive = true;
                    }
                }

                let sortedCampaigns = [];
                for(let campaignId in campaigns) {
                    if (campaigns[campaignId].cost > 0) {
                        sortedCampaigns.push({campaignId : campaignId, users : campaigns[campaignId].users, isActive : campaigns[campaignId].isActive});
                    }
                }
                sortedCampaigns.sort((_a,_b) => {return _b.users - _a.users;});
                GraphCache.storeData(hash, sortedCampaigns);
                this.setState(() => {
                    return {
                        isLoading: false,
                        campaigns : sortedCampaigns,
                        error: null
                    };
                });
            }
        });
    }

    componentWillUnmount() {
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Loading Campaigns"/>);
        }
        return null;
    }


    errorBanner() {
        if(this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>);
        }
        else return null;
    }

    render() {
        let from = formatDate(this.props.dateSelection.start);
        let to = formatDate(this.props.dateSelection.end);

        const keyGen = new KeyGenerator("campaigns_"+this.state.updateCounter);

        let campaigns = [];
        for(let i = 0;i < this.state.campaigns.length; i++) {
            let campaign = this.state.campaigns[i];
            if (this.state.showActive) {
                if (campaign.isActive) {
                    campaigns.push(<CampaignPL key={keyGen.getKey()} campaignId={campaign.campaignId} gameId={this.props.urlParams.gameId} from={from} to={to}/>);
                }
            } else {
                campaigns.push(<CampaignPL key={keyGen.getKey()} campaignId={campaign.campaignId} gameId={this.props.urlParams.gameId} from={from} to={to}/>);
            }
        }

        return (
            <Fragment>
                {this.loadingBanner()}
                {this.errorBanner()}
                <br/>
                <Row>
                    <Col lg={ 1} md={ 2 } className="mb-3">
                        <Toggle id='locked' checked={this.state.showActive} onChange={this.onShowActiveChanged} />
                    </Col>
                    <Col>
                        <span className={"alert-headings"}>Only Active campaigns</span>
                    </Col>
                </Row>
                {campaigns}
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(CampaignPage,["time"], (_props)=> {return "Campaigns: "+AnalyticsContext.getGameName(_props.urlParams.gameId);}));