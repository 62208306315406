import React from "react";
import ErrorBannerUI from "../../../common/ErrorBannerUI";
import GraphCache from "./GraphCache";

const objectHash = require("object-hash");

function CachedGraph(_wrappedComponent, GraphComponent) {
    return class extends React.Component {
        constructor(_props) {
            super(_props);

            this.state = {
            };

            this.callWrapper = this.callWrapper.bind(this);
        }

        callWrapper(_name, _props) {
            if (_wrappedComponent && _wrappedComponent[_name] && typeof _wrappedComponent[_name] === "function") {
                return _wrappedComponent[_name](_props);
            }
        }

        componentDidMount() {
            if (!_wrappedComponent.getId || typeof _wrappedComponent.getId !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error", text: "Missing getId() in component!"}};
                });
            }
            if (!_wrappedComponent.getGraphProps || typeof _wrappedComponent.getGraphProps !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error", text: "Missing getGraphProps() in component!"}};
                });
            }
            if(!_wrappedComponent.getRequiredProps || typeof  _wrappedComponent.getRequiredProps !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error", text: "Missing getRequiredProps() in component!"}};
                });
            }
            if (!_wrappedComponent.getGraphData || typeof _wrappedComponent.getGraphData !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error", text: "Missing getGraphData() in component!"}};
                });
            }
            const graphProps = _wrappedComponent.getGraphProps(this.props);
            const requiredGraphProps = GraphComponent.CF_requiredProps();
            for (let i = 0; i < requiredGraphProps.length; i++) {
                if (graphProps[requiredGraphProps[i]] === undefined) {
                    return this.setState(() => {
                        return { error: {title: "Graph Error", text: "Missing required graph property: "+requiredGraphProps[i]}};
                    });
                }
            }

            const requiredWrappedProps = _wrappedComponent.getRequiredProps();
            for (let i = 0; i < requiredWrappedProps.length; i++) {
                if (this.props[requiredWrappedProps[i]] === undefined) {
                    return this.setState(() => {
                        return { error: {title: "Component Error", text: "Missing required component property: "+requiredWrappedProps[i]}};
                    });
                }
            }

            let hash = objectHash({id: _wrappedComponent.getId(), props: this.props});
            let cached = GraphCache.getData(hash);
            if (cached) {
                return this.setState((_state, _props) => {
                    return {data : cached};
                });
            }
            return _wrappedComponent.getGraphData(this.props, (_err, _graph) => {
                if(_err) {
                    console.log("CACHE",_err);
                    return this.setState((_state, _props) => {
                        return {
                            error: {title: "API Error", text: _err.code+": "+_err.message}
                        };
                    });
                } else {
                    GraphCache.storeData(hash, _graph);
                    return this.setState((_state, _props) => {
                        return {data : _graph};
                    });
                }
            });
        }

        render() {
            if (this.state.error) {
                return (<ErrorBannerUI error={this.state.error}/>);
            }

            let graphProps = this.callWrapper("getGraphProps", this.props);
            if (graphProps && graphProps.xAxis && graphProps.xAxis === "dataCategory") {
                graphProps.xAxis = [];
                if (this.state.data && this.state.data[0] && this.state.data[0].data) {
                    for (let i = 0; i < this.state.data[0].data.length; i++) {
                        graphProps.xAxis.push(this.state.data[0].data[i].name);
                    }
                }
            }

            return (<GraphComponent data={this.state.data} {...graphProps} />);
        }
    }

}

export default CachedGraph;