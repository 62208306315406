import withURLParams from "../common/SiteWithUrlParams";
import React, {Fragment} from "react";
import {Button, Col, Container, Input, Row, Table} from "reactstrap";
import {HeaderMain} from "../routes/components/HeaderMain";
import AnalyticsContext from "./AnalyticsContext";
import TextDropdown from "../routes/Dashboards/Overview/TextDropdown";
import LoadingBannerUI from "../common/LoadingBannerUI";
import ErrorBannerUI from "../common/ErrorBannerUI";

class DebugEventsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            selectedEnvironment: "",
            selectedEvent : "",
            userId : ""
        };

        this.onEnvironmentChanged = this.onEnvironmentChanged.bind(this);
        this.onEventChanged = this.onEventChanged.bind(this);
        this.onUserIdChanged = this.onUserIdChanged.bind(this);
        this.onSearchButton = this.onSearchButton.bind(this);
        this.loadingBanner = this.loadingBanner.bind(this);
        this.errorBanner = this.errorBanner.bind(this);
        this.eventResults = this.eventResults.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    onEnvironmentChanged(_event) {
        this.setState((_prevState, _props) => {
            return {selectedEnvironment : _event};
        });
    }

    onEventChanged(_event) {
        this.setState((_prevState, _props) => {
            return {selectedEvent : _event};
        });
    }

    onUserIdChanged(_event) {
        let userId = _event.target.value;
        this.setState((_prevState, _props) => {
            return {userId : userId};
        });
    }

    onSearchButton() {
        this.setState((_prevState, _props) => {
            return {isLoading : true};
        });
        let ctx = AnalyticsContext.getAsyncContext();

        let gameId = this.props.urlParams.gameId;
        if (this.state.selectedEnvironment !== "live") {
            gameId += "_test";
        }
        ctx.sendAsync("events",{type: "analytics_v3", noCache : true, subType: "rawUserData_v3", gameId : gameId, eventType : this.state.selectedEvent, userId : this.state.userId});
        ctx.waitForCompletion((_err, _response) => {
            if(_err) {
                this.setState(() => {
                    return {isLoading: false, eventData: null, error: {title : "Couldn't retrieve User Data", text : _err.code+": "+_err.message}};
                });
            } else {
                this.setState(() => {
                    let data = _response.events.result.result;
                    for (let i = 0; i < data.length; i++) {
                        if (this.state.selectedEvent === "adImpression") {
                            data[i].result = data[i].result === 0 ? "viewed" : "dismissed";
                        } else if (this.state.selectedEvent === "currency") {
                            data[i].type = data[i].type === 0 ? "given" : "spent";
                        }
                    }
                    return {
                        error : null,
                        isLoading : false,
                        eventData : data
                    };
                });
            }
        });
    }

    errorBanner() {
        if (this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>)
        }
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Retrieving Events..."/>)
        }
    }

    eventResults() {
        if (!this.state.isLoading && this.state.eventData) {
            if (this.state.eventData.length === 0) {
                return (
                    <Fragment>
                                No Events found.
                    </Fragment>
                )
            } else {
                let keys = {};
                for (let i = 0; i < this.state.eventData.length; i++) {
                    const entry = this.state.eventData[i];
                    for(let key in entry) {
                        keys[key] = true;
                    }
                }
                let keyList = [];
                for(let key in keys) {
                    keyList.push(key);
                }

                let tableHeader = [];
                for (let i = 0; i < keyList.length; i++) {
                    tableHeader.push(<th key={"header_"+i}>{keyList[i]}</th>);
                }
                let entries = [];
                for(let i = 0; i < this.state.eventData.length; i++) {
                    const entry = this.state.eventData[i];
                    let line = [];
                    for(let k = 0; k < keyList.length; k++) {
                        line.push(<td key={"line_"+i+"_"+k} className="text-inverse">{entry[keyList[k]]}</td>);
                    }
                    entries.push(<tr key={"line_"+i}>{line}</tr>);
                }
                return (
                    <Fragment>
                        <Table className="table">
                            <thead>
                                <tr>
                                    {tableHeader}
                                </tr>
                            </thead>
                            <tbody>
                            {entries}
                            </tbody>

                        </Table>
                    </Fragment>
                );
            }
        }
        return null;
    }

    render() {
        let environment = ["test","live"];
        let options = ["adImpression","event","currency"];
        return (
            <Fragment>
                <Container>
                    <HeaderMain className="mb-5 mt-4" title={"Debug Events: "+AnalyticsContext.getGameName(this.props.urlParams.gameId)} />
                    <Row>
                        <Col lg={ 2 } md={ 1}>
                            <TextDropdown options={environment} selectText="Select Environment" selected={this.state.selectedEnvironment} onChange={this.onEnvironmentChanged}/>
                        </Col>
                        <Col lg={ 2 } md={ 1 }>
                            <TextDropdown options={options} selectText="Select Event" selected={this.state.selectedEvent} onChange={this.onEventChanged}/>
                        </Col>
                        <Col lg={ 4 } md={ 2 }>
                            <Input type="userid" name="userid" id="userid" placeholder="Enter User-ID..." value={this.state.userId} onChange={this.onUserIdChanged} />
                        </Col>
                        <Col lg={ 2 } md={ 1 }>
                            <Button color="primary" onClick={this.onSearchButton}>Search</Button>
                        </Col>
                    </Row>
                    {this.loadingBanner()}
                    {this.errorBanner()}
                    <Row className="mt-3">
                    {this.eventResults()}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default withURLParams(DebugEventsPage);

