import React, {Fragment} from "react";

import AnalyticsContext from "./AnalyticsContext";
import {Col, Row} from "reactstrap";

import {formatDate} from "../common/TimeUtil";
import EstRevenue_daily from "./Components/Revenue_Daily_Line";
import withFilter from "./SiteWithFilter";
import {mergeFilters} from "./Filters/FilterLogic";
import EstRevenue_PieChart from "./Components/Revenue_Pie";
import withURLParams from "../common/SiteWithUrlParams";
import LTVPil from "./Components/LTV_Pil_Line";
import Purchaser_ColumnChart from "./Components/Purchaser_Bar";
import EstRevenueSplit_PieChart from "./Components/EstRevenueSplit_PieChart";

class RevenuePage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            updateCounter : 0
        };

    }

    render() {
        let gameId = this.props.urlParams.gameId;
        let comp = 0;
        let getKey = () => {
            const id = comp;
            comp +=1;
            return "comp_"+id+"_"+this.state.updateCounter;
        };

        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);



        const filter = mergeFilters(this.props.filter, {game_id : gameId});

        return (
            <Fragment>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <EstRevenue_daily key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mt-3">
                        <EstRevenue_PieChart key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} legend/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mt-3">
                        <EstRevenue_PieChart key={getKey()} startDate={startDate} endDate={endDate} group="country" filter={filter} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <LTVPil key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} average/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mt-3">
                        <Purchaser_ColumnChart key={getKey()} startDate={startDate} endDate={endDate} filter={filter} legend/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mt-3">
                        <EstRevenueSplit_PieChart key={getKey()} startDate={startDate} endDate={endDate} filter={filter} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(RevenuePage, ["filter","time","group"],(_props)=> {return "Revenue: "+AnalyticsContext.getGameName(_props.urlParams.gameId);}));