import React, {Fragment} from "react";

import AnalyticsContext from "./AnalyticsContext";
import {Col, Row} from "reactstrap";

import InstallsLine from "./Components/Installs_Line";
import {formatDate} from "../common/TimeUtil";
import RevenueDailyLine from "./Components/Revenue_Daily_Line";
import withFilter from "./SiteWithFilter";
import {mergeFilters} from "./Filters/FilterLogic";
import withURLParams from "../common/SiteWithUrlParams";
import DAULine from "./Components/DAU_Line";
import AdImpressionsDailyLine from "./Components/AdImpressions_Daily_Line";

class GameOverviewPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
        };

    }

    render() {
        let gameId = this.props.urlParams.gameId;
        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);
        const filter = mergeFilters(this.props.filter, {game_id : gameId});

        return (
            <Fragment>
                <Row>
                    <Col lg={ 6} md={12} className="mt-3">
                        <InstallsLine startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <RevenueDailyLine startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={12} className="mt-3">
                        <DAULine startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum/>
                    </Col>
                    <Col lg={ 6} md={12} className="mt-3">
                        <AdImpressionsDailyLine startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={12} className="mt-3">
                        <RevenueDailyLine startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum onlyAds/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <RevenueDailyLine startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum onlyIAP/>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(GameOverviewPage, ["filter","time","group"],(_props)=> {return "Overview: "+AnalyticsContext.getGameName(_props.urlParams.gameId);}));