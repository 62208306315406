import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import React from "react";

import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import StatisticUtil from "../StatisticUtil";
import GraphUtil from "./Graphs/GraphUtil";

class Revenue_Daily_Line {
    constructor() {
    }

    static getId() {
        return ("Revenue_Daily_Line");
    }

    static getGraphProps(_props) {
        let title = "Revenue"
        if (_props.onlyAds) {
            title += " (Ads)";
        }
        if (_props.onlyIAP) {
            title += " (IAP)";
        }
        return {
            title: title,
            xAxis : "time",
            yAxis : "euro",
            withSum : _props.withSum
        }
    }

    static getRequiredProps() {
        return ["startDate","endDate"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        mergedFilters.day = { from : _props.startDate, to: _props.endDate };
        const all = (_props.onlyAds === undefined && _props.onlyIAP === undefined);
        if (all || _props.onlyAds) {
            ctx.sendAsync("ad", {type:"analytics_v3", subType: "es_adImpression_v3", group: ["day",_props.group], filter: mergedFilters});
        }
        if (all || _props.onlyIAP) {
            ctx.sendAsync("iap", {type:"analytics_v3", subType: "es_premiumPurchase_v3", group: ["day",_props.group], filter: mergedFilters});
        }
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let revenueData = [];
                if (_data.iap) {
                    revenueData = AnalyticsUtil.join(revenueData, _data.iap.result.result);
                }
                if (_data.ad) {
                    revenueData = AnalyticsUtil.join(revenueData, _data.ad.result.result);
                }
                const rev = AnalyticsUtil.aggregateBy(revenueData, ["day", _props.group], "revenue", StatisticUtil.sum);
                let graphData = GraphUtil.generateTimeSeries(rev, "day","revenue", _props.group);
                return _cb(null, graphData);
            }
        });

    }

}


export default CachedGraph(Revenue_Daily_Line, LineGraph);