import * as React from "react";
import {
    tooltipFormat, XAxisFormatNone,
    XAxisFormatRetention,
    XAxisFormatTime,
    YAxisFormatEuro,
    YAxisFormatNumber,
    YAxisFormatPercent,
    YAxisFormatTime
} from "./GraphFormatting";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Theme from "./HighChartsTheme";
import "./loading.css";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

class BarGraph extends React.Component {
    static CF_requiredProps() {
        return ["title","xAxis","yAxis"];
    }

    static buildChartUpdate(_props, _newState) {
        if (_props.data) {
            if (_props.data.length > 1) {
                let hasLegend = true;
                if (_props.legend !== undefined) {
                    hasLegend = _props.legend;
                }
                if (hasLegend) {
                    _newState.chartOptions.legend = {
                        enabled: true,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    };
                } else {
                    _newState.chartOptions.legend = { enabled: false};
                }
            } else {
                _newState.chartOptions.legend = { enabled : false };
            }
            if (_props.lowPercentage) {
                let maxValue = 0;
                for(let i = 0; i < _props.data.length; i++) {
                    for(let j = 0; j < _props.data[i].data.length; j++) {
                        if (maxValue < _props.data[i].data[j].y) {
                            maxValue = _props.data[i].data[j].y;
                        }
                    }
                }
                let max = 100;
                if (maxValue < 50) {
                    max = 50;
                }
                if (maxValue < 25) {
                    max = 25;
                }
                if (maxValue < 10) {
                    max = 10;
                }
                if (maxValue < 5) {
                    max = 5;
                }
                if (_newState.chartOptions.yAxis) {
                    _newState.chartOptions.yAxis.max = max;
                }
            }
            _newState.chartOptions.series = _props.data;
            _newState.isLoading = false;
        }
    };

    static getDerivedStateFromProps(_props, _state) {
        let newState = JSON.parse(JSON.stringify(_state));
        // manually copy tooltip function
        if(newState.chartOptions && newState.chartOptions.tooltip) {
            newState.chartOptions.tooltip = _state.chartOptions.tooltip;
        }
        let hasChanges = false;
        if (_props.data) {
            // new data
            if (!_state.chartOptions || !_state.chartOptions.series || _state.chartOptions.series !== _props.data) {
                newState.isLoading = false;
                if (!newState.chartOptions) {
                    newState.chartOptions = {};
                }
                BarGraph.buildChartUpdate(_props, newState);
                hasChanges = true;
            }
        } else {
            // clear data
            if (_state.chartOptions && _state.chartOptions.series) {
                newState.chartOptions.series = _props.data;
                hasChanges = true;
            }
        }
        if (Array.isArray(_props.xAxis)) {
            if (!newState.chartOptions) {
                newState.chartOptions = {};
            }
            if (!newState.chartOptions.xAxis) {
                newState.chartOptions.xAxis = {};
            }
            newState.chartOptions.xAxis.categories = _props.xAxis;
            hasChanges = true;
        }
        if (hasChanges) {
            return newState;
        }
        return null;
    }

    constructor(_props) {
        super(_props);

        let isSmall = false;
        if (window.innerWidth !== undefined) {
            isSmall = window.innerWidth < 768;
        }
        let chartOptions = {
            title : {
                text : _props.title
            },
            chart : {
                type: "column",
                height : _props.small ? "105%" : "50%"
            },
            tooltip : tooltipFormat(_props.xAxis, _props.yAxis, _props.stacking === "percent", _props.sampleName),
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    stacking : _props.stacking
                }
            },
            legend : {
                enabled : false
            },
            credits : {
                enabled : false
            }
        };

        if (isSmall) {
            chartOptions.chart.height = "100%";
        }

        if (_props.xAxis === "time") {
            chartOptions.xAxis = XAxisFormatTime();
        } else if (_props.xAxis === "retention") {
            chartOptions.xAxis = XAxisFormatRetention();
        } else if (_props.xAxis === "category") {
            chartOptions.xAxis = {type : "category"};
        } else if (_props.xAxis === "dataCategory") {
            chartOptions.xAxis = {type : "category"};
        } else if (_props.xAxis === "none") {
            chartOptions.xAxis = XAxisFormatNone();
        } else if (Array.isArray(_props.xAxis)) {
            chartOptions.xAxis = {categories : _props.xAxis}
        }

        let yAxisType = _props.yAxis;
        if (_props.stacking === "percent") {
            yAxisType = "percent";
        }
        if(yAxisType === "time") {
            chartOptions.yAxis = YAxisFormatTime();
        } else if (yAxisType === "percent") {
            chartOptions.yAxis = YAxisFormatPercent();
            if (_props.lowPercentage) {
                chartOptions.yAxis.max = 10;
            }
        } else if (yAxisType === "number") {
            chartOptions.yAxis = YAxisFormatNumber();
        } else if (yAxisType === "euro") {
            chartOptions.yAxis = YAxisFormatEuro();
        }
        this.state = {
            isLoading : true,
            isSmall : false,
            chartOptions : chartOptions
        };
        BarGraph.buildChartUpdate(_props, this.state);
        this._chartRef = React.createRef();
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        if (this._chartRef.current) {
            if (this.state.isLoading) {
                this._chartRef.current.chart.showLoading();
            }
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let isSmall = false;
        if (window.innerWidth !== undefined) {
            isSmall = window.innerWidth < 768;
        }
        if (this.state.isSmall !== isSmall) {
            this.setState((_prevState, _props) => {
                let chartOptions = JSON.parse(JSON.stringify(_prevState.chartOptions));
                if (chartOptions.chart && chartOptions.chart.height) {
                    if (isSmall) {
                        chartOptions.chart.height = "75%";
                    } else {
                        chartOptions.chart.height = _props.small ? "105%" : "50%";
                    }
                }
                return {
                    isSmall : isSmall,
                    chartOptions : chartOptions
                };
            });
        }
    }


    render() {
        if (this._chartRef.current) {
            if (this.state.isLoading) {
                this._chartRef.current.chart.showLoading();
            } else {
                this._chartRef.current.chart.hideLoading();
            }
        }
        Highcharts.theme = Theme;
        Highcharts.setOptions(Highcharts.theme);
        return (<HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} ref={this._chartRef} />);
    }
}

export default BarGraph;