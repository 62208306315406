import AnalyticsContext from "../AnalyticsContext";

import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import GraphUtil from "./Graphs/GraphUtil";
import BarGraph from "./Graphs/BarGraph";

class EventFunnel_Bar {
    constructor() {
    }

    static getId() {
        return "EventFunnel_Bar";
    }

    static getGraphProps(_props) {
        return {
            title: _props.funnelName ? _props.funnelName : "Funnel",
            xAxis: "dataCategory",
            yAxis: "percent"
        };
    }

    static getRequiredProps() {
        return ["startDate","endDate","events","filter"];
    }

    static getGraphData(_props, _cb) {
        let ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        mergedFilters.name = [];
        for(let i = 0; i <_props.events.length; i++) {
            mergedFilters.name.push(_props.events[i]);
        }
        ctx.sendAsync("events", {type:"analytics_v3", subType: "es_event_v3", group: ["name"], filter: mergedFilters});
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                const eventsData = _data.events.result.result;
                var highestValue = 0;
                const eventLookup = {};
                for (let i = 0; i < eventsData.length; i++) {
                    if (eventsData[i].value >= highestValue) {
                        highestValue = eventsData[i].value;
                    }
                    eventLookup[eventsData[i].name] = eventsData[i].value;
                }
                let graphData = [{name:"default", data:[]}];
                for(let i = 0; i <_props.events.length; i++) {
                    const eventName = _props.events[i];
                    graphData[0].data.push({name : eventName, y: eventLookup[eventName] / highestValue * 100, sampleCount : eventLookup[eventName] });
                }
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(EventFunnel_Bar, BarGraph);