const path = require('path');

const root = path.join(__dirname);

const config = {
    //rootDir:                root,
    // Targets ========================================================
    //serveDir:               path.join(root, '.serve'),
    //distDir:                path.join(root, 'dist'),
    //clientManifestFile:     'manifest.webpack.json',
    //clientStatsFile:        'stats.webpack.json',

    // Source Directory ===============================================
    //srcDir:                 path.join(root, 'app'),
    //srcServerDir:           path.join(root, 'server'),

    // HTML Layout ====================================================
    //srcHtmlLayout:          path.join(root, 'app', 'index.html'),

    // Site Config ====================================================
    siteTitle:              'Meteor',
    siteDescription:        'Meteor Dashboard by ColdFire Games',
    //siteCannonicalUrl:      'http://localhost:4100',
    siteKeywords:           '',
    //scssIncludes:           [],
    useLiveEnvironment : true,
    isDebug : false,
    cachingEnabled : true,
    version : "v1.6.3",
    logConfig : { // types, 0: debug, 1: info, 2:warn, 3:err, 4:off
        "Graph-Cache" : 1,
        "Async-API" : 0,
        "WSS": 0,
        "Analytics-Cache" : 1
    },
    logDebug : (_type, ...args) => {
        doLog(_type, 0, ...args);
    },
    logInfo : (_type, ...args) => {
        doLog(_type, 1, ...args);
    },
    logWarn : (_type, ...args) => {
        doLog(_type, 2, ...args);
    },
    logErr : (_type, ...args) => {
        doLog(_type, 3, ...args);
    }
}

const severityLookup = ["DEBUG","INFO","WARNING","ERROR"];
function doLog(_type, _severity, ...args) {
    if (config.isDebug === true) {
        let isAllowed = true;
        if (config.logConfig[_type] !== undefined && _severity < config.logConfig[_type]) {
            isAllowed = false;
        }

        if (isAllowed) {
            console.log("["+_type+"] ["+severityLookup[_severity]+"]",...args);
        }
    }

}

module.exports = config;