import Config from "../config";

class LRUMemCache {
    constructor(_logName, _maxSize) {
        this._logName = _logName;
        this._cache = {};
        this._usedSize = 0;
        this._maxSize = _maxSize;
        this._cacheTimeout = 60*60*1000; // 1 hour
    }

    store(_hash, _data) {
        const dataText = JSON.stringify(_data);
        const dataLength = dataText.length;
        const entry = {
            ts: Date.now(),
            data : dataText
        };
        if (this._cache[_hash] !== undefined) {
            this._usedSize -= this._cache[_hash].data.length;
        }
        if (this._usedSize + dataLength > this._maxSize) {
            this.cleanup((this._usedSize + dataLength) - this._maxSize);
        }
        this._cache[_hash] = entry;
        this._usedSize += dataLength;

        const percentage = Math.floor((this._usedSize / this._maxSize)*10000)/100;
        Config.logDebug(this._logName, "Storing object "+_hash+" with size "+dataText.length+". Capacity is "+percentage+"%");
    }

    get(_hash) {
        const entry = this._cache[_hash];
        if (entry === undefined) {
            Config.logDebug(this._logName, "Cache MISS " + _hash);
        } else {
            const now = Date.now();
            if (now  > (entry.ts + this._cacheTimeout)) {
                Config.logDebug(this._logName, "Cache MISS (timeout) " + _hash);
            } else {
                Config.logDebug(this._logName, "Cache HIT "+_hash);
                this._cache[_hash].ts = Date.now();
                return JSON.parse(this._cache[_hash].data);
            }
        }
    }

    cleanup(_needed) {
        let lruList = [];
        for(let hash in this._cache) {
            const entry = this._cache[hash];
            lruList.push({hash: hash, ts: entry.ts, length: entry.data.length});
        }
        lruList.sort((_a, _b) => {return _a.ts-_b.ts});
        let deleteList = [];
        let freed = 0;
        for(let i = 0; i < lruList.length; i++) {
            const entry = lruList[i];
            freed += entry.length;
            deleteList.push(entry.hash);
            if (freed >= _needed) {
                break;
            }
        }
        for(let i = 0; i < deleteList; i++) {
            const hash = deleteList[i];
            delete this._cache[hash];
        }
        this._usedSize -= freed;
        Config.logDebug(this._logName, "Removed "+deleteList.length+" objects with size "+freed+".");
    }

}

export default LRUMemCache;