
function shortPieData(_data, _max) {
    _data.sort((a,b) => {return b.y-a.y;});
    if(_data.length <= _max) {
        return _data;
    }
    let newData = [];
    for(let i = 0; i < _max-1; i++) {
        newData.push(_data[i]);
    }
    let other = 0;
    for(let i = _max-1; i < _data.length; i++) {
        other += _data[i].y;
    }
    newData.push({name:"Other", y: other});
    return newData;
}

function addSum(_data) {
    for(let i = 0;i < _data.length; i++) {
        if (_data[i].name === "ALL") {
            return _data;
        }
    }
    let clone = JSON.parse(JSON.stringify(_data));
    let sums = {};
    for(let i = 0; i < clone.length; i++) {
        const serie = clone[i];
        if (serie.data) {
            for(let idx = 0; idx < serie.data.length; idx++) {
                const point = serie.data[idx];
                if(!sums[point.x]) {
                    sums[point.x] = 0;
                }
                sums[point.x] += point.y;
            }
        }
    }
    let sumSerie = {name:"ALL", data:[]};
    for(let key in sums) {
        const x = parseInt(key,10);
        sumSerie.data.push({x: x, y : sums[key]});
    }
    sumSerie.data.sort((a,b) => {return a.x-b.x;});
    clone.push(sumSerie);
    return clone;
}

function mergePilData(_data1, _data2, _propertyName, _groupName) {
    let users = {};
    const processUsers = (_list) => {
        for (let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            const group = (_groupName && entry[_groupName]) ? entry[_groupName] : "default";
            let value = 0;
            if (entry[_propertyName]) {
                if (typeof entry[_propertyName] === "number") {
                    value = entry[_propertyName];
                } else if (typeof  entry[_propertyName] === "string") {
                    value = parseFloat(entry[_propertyName]);
                }
                if (isNaN(value)) {
                    value = 0;
                }
            }
            if (!users[entry.user_id]) {
                users[entry.user_id] = {group: group, install_date: entry.install_date, days : {}};
            }
            if (!users[entry.user_id].days[entry.day]) {
                users[entry.user_id].days[entry.day] = 0;
            }
            users[entry.user_id].days[entry.day] += value;
        }
    }
    processUsers(_data1);
    processUsers(_data2);
    let result = []
    for(let userId in users) {
        const user = users[userId];
        for(let day in user.days) {
            let entry = {
                user_id : userId,
                install_date : user.install_date,
                day : day
            };
            if (_groupName !== null && _groupName !== undefined) {
                entry[_groupName] = user.group;
            }
            entry[_propertyName] = user.days[day];
            result.push(entry);
        }
    }
    return result;
}

function filterByPil(_list, _fn) {
    let result = [];
    const now = Date.now();
    for(let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        let installD = Date.parse(entry.install_date);
        let date = Date.parse(entry.day);
        if (date <= now) { // kill impossible data
            let pil = Math.round((date - installD) / (24 * 60 * 60 * 1000));
            if (_fn(pil)) {
                result.push(entry);
            }
        }
    }
    return result;
}

function join(_list1, _list2) {
    let result = [];
    for (let i = 0; i < _list1.length; i++) {
        result.push(_list1[i]);
    }
    for (let i = 0; i < _list2.length; i++) {
        result.push(_list2[i]);
    }
    return result;
}

function mergeCalcNewSeries(_list1, _prop1, _list2, _prop2, _fn) {
    const properties = {};
    for(let i = 0; i < _list1.length; i++) {
        if (_list1[i][_prop1]) {
            properties[_list1[i][_prop1]] = true;
        }
    }
    for(let i = 0; i < _list2.length; i++) {
        if (_list2[i][_prop2]) {
            properties[_list2[i][_prop2]] = true;
        }
    }
    const result = [];
    let findIn = (_list, _prop, _val) => {
        for(let i = 0; i < _list.length; i++) {
            if (_list[i][_prop] === _val) {
                return _list[i];
            }
        }
    }
    for (let prop in properties) {
        let entry = _fn(findIn(_list1,_prop1,prop), findIn(_list2,_prop2,prop));
        if (entry) {
            result.push(entry);
        }
    }
    return result;
}

function addPil(_list) {
    const now = Date.now();
    let result = [];
    for(let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        if (entry.install_date && entry.day) {
            let installD = Date.parse(entry.install_date);
            let date = Date.parse(entry.day);
            if (date <= now) { // kill impossible data
                let pil = Math.round((date - installD) / (24 * 60 * 60 * 1000));
                let maxPil = Math.floor((now - installD) / (24 * 60 * 60 * 1000));
                if (pil >= 0) {
                    entry.pil = pil;
                    entry.maxPil = maxPil;
                    result.push(entry);
                }
            }
        }
    }
    return result;
}

function filterBy(_list, _fn) {
    let result = [];
    for(let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        if (_fn(entry)) {
            result.push(entry);
        }
    }
    return result;
}

function sumBy(_list, _propertyName, _valueName) {
    let data = {};
    for(let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        const property = entry[_propertyName];
        if (!data[property]) {
            data[property] = 0;
        }
        let value = 0;
        if (entry[_valueName]) {
            if (typeof entry[_valueName] === "number") {
                value = entry[_valueName];
            } else if (typeof  entry[_valueName] === "string") {
                value = parseFloat(entry[_valueName]);
            }
            if (isNaN(value)) {
                value = 0;
            }
        }
        data[property] += value;
    }
    let resultList = [];
    for(let prop in data) {
        const entry = data[prop];
        let result = {};
        result[_propertyName] = prop;
        result[_valueName] = entry;
        resultList.push(result);
    }
    return resultList;
}

function removeNullArray(_list) {
    let result = [];
    for (let i = 0; i < _list.length; i++) {
        if (_list[i] !== undefined && _list[i] !== null) {
            result.push(_list[i]);
        }
    }
    return result;
}

function aggregateBy(_list, _groupingNames, _valueName, _fn) {
    if (!Array.isArray(_groupingNames)) {
        _groupingNames = [_groupingNames];
    }
    _groupingNames = removeNullArray(_groupingNames);
    let lookup = {};
    for(let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        let key = "";
        for(let j = 0; j < _groupingNames.length; j++) {
            key += "#"+entry[_groupingNames[j]];
        }
        if (!lookup[key]) {
            lookup[key] = JSON.parse(JSON.stringify(entry));
            lookup[key]._cf_aggreagte_list = [];
        }
        const val = parseFloat(entry[_valueName]);
        lookup[key]._cf_aggreagte_list.push(val);
    }
    let result = [];
    for(let key in lookup) {
        const entry = lookup[key];
        let agglist = entry._cf_aggreagte_list;
        delete entry._cf_aggreagte_list;
        entry[_valueName] = _fn(agglist);
        result.push(entry);
    }
    return result;
}

function sumArray(_list) {
    let sum = 0;
    for (let i = 0; i < _list.length; i++) {
        sum += _list[i];
    }
    return sum;
}

function scaleBy(_list, _property, _value, _fn) {
    for(let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        entry[_value] *= _fn(entry[_property]);
    }
}

function generateLookup(_list, _keyName, _valueName, _fn) {
    let lookup = {};
    for(let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        if (entry[_keyName] !== undefined && entry[_valueName] !== undefined) {
            if (_fn) {
                lookup[entry[_keyName]] = _fn(entry[_valueName]);
            } else {
                lookup[entry[_keyName]] = entry[_valueName];
            }
        }
    }
    return lookup;
}

function generateSummedPilGraph(_installs, _data, _propertyName, _groupName) {
    const now = Date.now();
    let retentionData = {};
    for (let i = 0; i < _data.length; i++) {
        let entry = _data[i];
        let group = (_groupName && entry[_groupName]) ? entry[_groupName] : "default";
        if (!retentionData[group]) {
            retentionData[group] = {};
        }
        let installD = Date.parse(entry.install_date);
        let date = Date.parse(entry.day);
        if (date <= now) { // kill impossible data
            let pil = Math.round((date - installD) / (24 * 60 * 60 * 1000));
            if (pil >= 0) {
                if (!retentionData[group][entry.install_date]) {
                    retentionData[group][entry.install_date] = { installs : 0, pil : []};
                }
                if (!retentionData[group][entry.install_date].pil[pil]) {
                    retentionData[group][entry.install_date].pil[pil] = 0;
                }
                let value = 0;
                if (entry[_propertyName]) {
                    if (typeof entry[_propertyName] === "number") {
                        value = entry[_propertyName];
                    } else if (typeof  entry[_propertyName] === "string") {
                        value = parseFloat(entry[_propertyName]);
                    }
                    if (isNaN(value)) {
                        value = 0;
                    }
                }
                retentionData[group][entry.install_date].pil[pil] += value;
            }
        }
    }
    for (let i = 0; i < _installs.length; i++) {
        let entry = _installs[i];
        let group = (_groupName && entry[_groupName]) ? entry[_groupName] : "default";
        if (!retentionData[group]) {
            retentionData[group] = {};
        }
        if (!retentionData[group][entry.install_date]) {
            retentionData[group][entry.install_date] = { installs : 0, pil : []};
        }
        retentionData[group][entry.install_date].installs = parseInt(entry.installs);
        retentionData[group][entry.install_date].summedPil = [];
        let highestPil = Math.round((now - Date.parse(entry.install_date)) / (24 * 60 * 60 * 1000));
        if (highestPil > 28) {
            highestPil = 28;
        }
        const cohort = retentionData[group][entry.install_date];
        for(let j = 0; j <= highestPil; j++) {
            cohort.summedPil[j] = cohort.pil[j] ? cohort.pil[j] : 0;
            if (j > 0) {
                cohort.summedPil[j] += cohort.summedPil[j-1];
            }
        }
    }

    let graphData = [];
    for (let groupName in retentionData) {
        let retData = {name: groupName, data: []};
        for (let pil = 0; pil <= 28; pil++) {
            let installs = 0;
            let revenue = 0;
            for (let cohort in retentionData[groupName]) {
                const cohortData = retentionData[groupName][cohort];
                if (cohortData.summedPil && cohortData.summedPil[pil] !== undefined) {
                    revenue += cohortData.summedPil[pil];
                    installs += cohortData.installs;
                }
            }
            if (installs > 10) {
                retData.data.push({x: pil, y: revenue / installs, sampleCount: installs});
            }
        }
        graphData.push(retData);
    }
    graphData.sort((_a, _b) => {
        return _a.name.localeCompare(_b.name)
    });
    return graphData;
}

export {
    shortPieData,
    addSum,
    generateSummedPilGraph,
    mergePilData,
    filterByPil,
    sumBy,
    addPil,
    filterBy,
    aggregateBy,
    generateLookup,
    scaleBy,
    sumArray,
    join,
    mergeCalcNewSeries
}