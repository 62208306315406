import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import React from "react";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import StatisticUtil from "../StatisticUtil";
import GraphUtil from "./Graphs/GraphUtil";

class PnL_Daily_Line {
    constructor() {
    }

    static getId() {
        return "PnL_Daily_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "P&L",
            xAxis: "time",
            yAxis: "euro"
        };
    }

    static getRequiredProps() {
        return ["startDate","endDate"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        mergedFilters.day = { from : _props.startDate, to: _props.endDate };
        let cpiFilter = JSON.parse(JSON.stringify(_props.filter));
        cpiFilter.install_date = { from : _props.startDate, to: _props.endDate };
        ctx.sendAsync("iap", {type:"analytics_v3", subType: "es_premiumPurchase_v3", group:["day"], filter: mergedFilters});
        ctx.sendAsync("ad", {type:"analytics_v3", subType: "es_adImpression_v3", group:["day"], filter: mergedFilters});
        ctx.sendAsync("cpi", {type:"analytics_v3", subType: "es_user_v3", group:["install_date"], filter: cpiFilter});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let revenueData = AnalyticsUtil.join(_data.iap.result.result, _data.ad.result.result);
                let revenue = AnalyticsUtil.aggregateBy(revenueData, "day","revenue", StatisticUtil.sum);
                let revenueReport = GraphUtil.generateNamedTimeseries("Revenue", revenue, "day","revenue");

                let cost = AnalyticsUtil.aggregateBy(_data.cpi.result.result, "install_date","cpi", StatisticUtil.sum);
                let costReport = GraphUtil.generateNamedTimeseries("Cost",cost, "install_date","cpi");

                let profit = AnalyticsUtil.mergeCalcNewSeries(revenue, "day", cost, "install_date", (_a, _b) => {
                    let day;
                    let revenue = 0;
                    let cost = 0;
                    if (_a) {
                        day = _a.day;
                        revenue = _a.revenue;
                    }
                    if (_b) {
                        day = _b.install_date;
                        cost = _b.cpi;
                    }
                    return {
                        day : day,
                        profit : revenue - cost
                    };
                });
                let profitReport = GraphUtil.generateNamedTimeseries("Profit", profit, "day", "profit");

                let graphData = GraphUtil.mergeReports(revenueReport, costReport);
                graphData = GraphUtil.mergeReports(graphData, profitReport);
                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(PnL_Daily_Line, LineGraph);