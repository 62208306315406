import React from "react";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container, DropdownItem, DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledButtonDropdown
} from "reactstrap";
import {HeaderMain} from "../routes/components/HeaderMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";

import MeteorContext from "../meteor/meteorContext";
import FirebuildContext from "./FirebuildContext";
import AnalyticsAdminUser from "../routes/Pages/AnalyticsAdmin/AnalyticsAdminUser";

class FirebuildAdmin extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : false,
            error : null,
            meteorUsers : [],
            firebuildUsers : [],
            permissions : []
        };

        this.loadingBanner = this.loadingBanner.bind(this);
        this.errorBanner = this.errorBanner.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSave = this.onSave.bind(this);

    }

    componentDidMount() {
        this.onRefresh();
    }

    onSelect(_sid) {
        this.setState({selectedUserSid : _sid});
    }

    onSave(_permissions) {
        this.setState({isLoading: true});
        let permissionList = [];
        for(let permission in _permissions) {
            permissionList.push({permission : permission, allow : _permissions[permission] === true});
        }
        return FirebuildContext.changePermissions(this.state.selectedUserSid, permissionList, (_err) => {
            if (_err) {
                this.setState({
                    error: {title: "Couldn't save user permission", text: _err.code + ": " + _err.message},
                    isLoading: false
                });
            } else {
                this.onRefresh();
            }
        });
    }

    onRefresh() {
        this.setState({isLoading : true});
        return MeteorContext.listUsers((_err, _meteorUsers) => {
            if (_err) {
                this.setState({
                    error: {title: "Couldn't retrieve Meteor Users", text: _err.code + ": " + _err.message},
                    isLoading: false
                });
            } else {
                return FirebuildContext.listUsers((_err, _firebuildUsers) => {
                    if (_err) {
                        this.setState({
                            error: {title: "Couldn't retrieve Analytics Users", text: _err.code + ": " + _err.message},
                            isLoading: false
                        });
                    } else {
                        return FirebuildContext.getMeta((_err, _meta) => {
                            if (_err) {
                                this.setState({
                                    error: {title: "Couldn't retrieve Analytics Meta", text: _err.code + ": " + _err.message},
                                    isLoading: false
                                });
                            } else {
                                this.setState({meteorUsers: _meteorUsers, firebuildUsers : _firebuildUsers, permissions : _meta.validPermissions, isLoading: false});
                            }
                        });
                    }
                });
            }
        });
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (
                <Alert color="info">
                    <FontAwesomeIcon icon={faSync} spin/>
                    <span>
                        <strong className="alert-heading"> Loading User Data</strong>Please wait...
                    </span>
                </Alert>
            );
        }
    }

    errorBanner() {
        if (this.state.error) {
            return (
                <Alert color="danger">
                    <i className="fa fa-times-circle mr-1 alert-icon" />
                    <span>
                        <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                    </span>
                </Alert>
            );
        }
    }


    render() {
        let userSelection = [];
        let selectedUserName = "Select User";
        let selectedUserPermissions;
        let userSelected = false;
        for(let i  = 0; i < this.state.meteorUsers.length;  i++) {
            const meteorUser = this.state.meteorUsers[i];
            userSelection.push(<DropdownItem key={meteorUser.sid} onClick={()=>{this.onSelect(meteorUser.sid)}}>{meteorUser.id}</DropdownItem>);
            if (meteorUser.sid === this.state.selectedUserSid) {
                selectedUserName = meteorUser.id;
                userSelected = true;
            }
        }
        for(let i = 0; i < this.state.firebuildUsers.length; i++) {
            const firebuildUser = this.state.firebuildUsers[i];
            if (firebuildUser.id === this.state.selectedUserSid) {
                selectedUserPermissions = firebuildUser.permissions ? firebuildUser.permissions : {};
            }
        }

        let userCard;
        if (!this.state.isLoading) {
            userCard = (
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle tag="h6">
                            Change User Permissions
                        </CardTitle>
                        <UncontrolledButtonDropdown >
                            <DropdownToggle caret color="secondary" outline>
                                {selectedUserName}
                            </DropdownToggle>
                            <DropdownMenu persist >
                                {userSelection}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        {(()=> {
                            if (userSelected) {
                                return(<AnalyticsAdminUser key={this.state.selectedUserSid} username={selectedUserName} disabled={this.state.isLoading} onSave={this.onSave}
                                                           permissions={selectedUserPermissions} validPermissions={this.state.permissions}/>);
                            }
                        })()}

                    </CardBody>
                </Card>
            );
        }

        return(
            <Container>
                <HeaderMain className="mb-5 mt-4" title="Firebuild Users" />
                <Row>
                    <Col lg={ 3 }>
                        <Button color="secondary" className="text-left" block onClick={this.onRefresh} disabled={this.state.isLoading}>
                            <FontAwesomeIcon icon={faSync} pull="left" />Refresh
                        </Button>
                    </Col>
                    <Col lg={ 6 }>
                        {this.loadingBanner()}
                        {this.errorBanner()}
                        {userCard}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FirebuildAdmin;