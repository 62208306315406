class StatisticUtil {
    static sum(_list) {
        let sum = 0;
        for (let i = 0; i < _list.length; i++) {
            if (!isNaN(_list[i])) {
                sum += _list[i];
            }
        }
        return sum;
    }
}

export default StatisticUtil;