import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faSync} from "@fortawesome/free-solid-svg-icons";
import {Alert} from "reactstrap";

function createTaskStateUI(_ref, _name) {
    let wrapper = {};

    wrapper.clearTasks = () => {
        _ref.setState((_prevState, _props) => {
            let tasks = {};
            if (_prevState[_name]) {
                tasks = JSON.parse(JSON.stringify(_prevState[_name]));
            }
            tasks.working = false;
            tasks.log = [];
            tasks.error = null;

            let updatedState = {};
            updatedState[_name] = tasks;
            return updatedState;
        });
    }
    wrapper.startTask = (_text) => {
        _ref.setState((_prevState, _props) => {
            let tasks = {};
            if (_prevState[_name]) {
                tasks = JSON.parse(JSON.stringify(_prevState[_name]));
            }
            tasks.working = true;
            if (!tasks.log) {
                tasks.log = [];
            }
            tasks.log.push(_text);
            tasks.error = null;
            let updatedState = {};
            updatedState[_name] = tasks;
            //console.log("updated: ", updatedState);
            return updatedState;
        });
    }

    wrapper.markTasksCompleted = () => {
        _ref.setState((_prevState, _props) => {
            let tasks = {};
            if (_prevState[_name]) {
                tasks = JSON.parse(JSON.stringify(_prevState[_name]));
            }
            tasks.working = false;
            let updatedState = {};
            updatedState[_name] = tasks;
            return updatedState;
        });
    }

    wrapper.hasActiveTask = () => {
        if (_ref.state[_name]) {
            return _ref.state[_name].working === true;
        }
        return false;
    }

    wrapper.drawTaskState = () => {
        let lines = [];
        if (_ref.state[_name]) {
            const tasks = _ref.state[_name];
            if (tasks.log) {
                const logs = tasks.log;
                for (let i = 0; i < logs.length; i++) {
                    let icon = (<FontAwesomeIcon icon={faCheckCircle} />);
                    if (tasks.working === true && i === logs.length-1) {
                        icon = (<FontAwesomeIcon icon={faSync} spin/>);
                    }
                    lines.push(
                        <Fragment key={"logs_"+lines.length}>
                            {icon}
                            <span>
                                <strong className="alert-heading">&nbsp;&nbsp;{logs[i]}</strong>
                            </span>
                            <br/>
                        </Fragment>
                    )
                }
            }
            if (tasks.working !== true) {
                lines.push(
                    <Fragment key={"logs_"+lines.length}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span>
                            <strong className="alert-heading">&nbsp;&nbsp;Done</strong>
                        </span>
                        <br/>
                    </Fragment>
                );
            }
        }
        if (lines.length === 0) {
            lines.push(
                <Fragment key={"logs_"+lines.length}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span>
                        <strong className="alert-heading">&nbsp;&nbsp;Ready</strong>
                    </span>
                </Fragment>
            )
        }
        return (
            <Alert color="info">
                {lines}
            </Alert>
        )
    }

    return wrapper;
}

export default createTaskStateUI;
