import AnalyticsContext from "../AnalyticsContext";

import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import GraphUtil from "./Graphs/GraphUtil";

class AdImpressions_Daily_Line {

    static getId() {
        return "AdImpressions_Daily_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "Ad Impressions",
            xAxis: "time",
            yAxis: "number",
            withSum : _props.withSum
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.day = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("impressions", {type: "analytics_v3", subType: "es_adImpression_v3", group: ["day",_props.group], filter: mergedFilters });
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                const graphData = GraphUtil.generateTimeSeries(_data.impressions.result.result, "day","impressions",_props.group);
                return _cb(null, graphData);
            }
        });
    }
}


export default CachedGraph(AdImpressions_Daily_Line, LineGraph);