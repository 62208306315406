import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import GraphUtil from "./Graphs/GraphUtil";

class Retention_Line{
    static getId() {
        return "Retention_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "Retention",
            xAxis: "retention",
            yAxis: "percent",
            small: _props.short ? true : false
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        let retentionLength = 28;
        if (_props.short) {
            retentionLength = 7;
        }
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("retention",{type: "analytics_v3", subType: "es_sessionDuration_v3", group: ["install_date","day",_props.group], filter : mergedFilters});
        ctx.sendAsync("installs",{type: "analytics_v3", subType: "installs_v3", group: _props.group, filter : mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                const retData = AnalyticsUtil.generatePilStats(_data.retention.result.result, _data.installs.result.result, _props.group, "users", retentionLength,false);
                const graphData = GraphUtil.generateRetentionSeries(retData, 1, retentionLength, true);
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(Retention_Line, LineGraph);