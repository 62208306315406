import DisposableCallback from "../common/DisposableCallback";

const uuidv4= require("uuid/v4");


class AnalyticsSubContext {
    constructor(_ctx) {
        this._ctx = _ctx;
        this._requests = [];
    }

    _removeHandle(_handle) {
        let idx = -1;
        for (let i = 0; i < this._requests.length; i++) {
            if (this._requests[i].handle === _handle) {
                idx = i;
                break;
            }
        }
        if (idx >= 0) {
            this._requests.splice(idx, 1);
        }
        this._ctx.getAsyncContext();
    }

    getAsyncContext() {
        return this._ctx.getAsyncContext(this);
    }

    getReportV2(_report, _group, _filter, _cb) {
        const disposableCallback = new DisposableCallback((_err, _result) => {
            this._removeHandle(handle);
            return _cb(_err, _result);
        });
        const handle = this._ctx.getReportV2(_report, _group, _filter, (_err, _result) => {
            disposableCallback.CallCallback(_err, _result);
        });
        this._requests.push({handle: handle, callback : disposableCallback});
    }

    sendAuthenticated(_params, _cb) {
        const disposableCallback = new DisposableCallback((_err, _result) => {
            this._removeHandle(handle);
            return _cb(_err, _result);
        });
        const handle = this._ctx.sendAuthenticated(_params, (_err, _result) => {
            disposableCallback.CallCallback(_err, _result);
        });
        this._requests.push({handle: handle, callback : disposableCallback});
    }

    getGameSettings(_id, _cb) {
        const handle = uuidv4();
        const disposableCallback = new DisposableCallback((_err, _result) => {
            this._removeHandle(handle);
            return _cb(_err, _result);
        });
        this._ctx.getGameSettings(_id, (_err, _result) => {
            disposableCallback.CallCallback(_err, _result);
        });
        this._requests.push({handle: handle, callback : disposableCallback});
    }

    clear() {
        for(let i = 0; i < this._requests.length; i++) {
            this._requests[i].callback.Dispose();
            this._ctx.cancelRequest(this._requests[i].handle);
        }
        this._requests = [];
    }
}

export default AnalyticsSubContext;