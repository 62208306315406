import React, {Fragment} from "react";
import withURLParams from "../common/SiteWithUrlParams";
import withFilter from "./SiteWithFilter";
import KeyGenerator from "../common/KeyGenerator";
import {Button, Col, Row} from "reactstrap";
import AnalyticsContext from "./AnalyticsContext";
import {formatDate} from "../common/TimeUtil";
import createTaskStateUI from "./TaskStateUI";
import Table from "reactstrap/es/Table";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import LineGraph from "./Components/Graphs/LineGraph";
import AdLatency_Daily_Line from "./Components/AdLatency_Daily_Line";

function parseToUnixTime(_day) {
    let tokens = _day.split("-");
    return Date.UTC(parseInt(tokens[0],10), parseInt(tokens[1],10)-1, parseInt(tokens[2],10));
}

function drawDayHeader(_dayList) {
    _dayList.sort();
    let months = [];
    let currentMonth = "";
    const monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    for(let i = 0; i < _dayList.length; i++) {
        let tokens = _dayList[i].split("-");
        let month = monthNames[parseInt(tokens[1],10)-1];
        if (month !== currentMonth) {
            currentMonth = month;
            months.push({month: currentMonth, days : []});
        }
        months[months.length-1].days.push(tokens[2]);
    }
    return (
        <thead>
            <tr>
                <th colSpan="2"></th>
                {(() => {
                    let list = [];
                    for(let i = 0; i < months.length; i++) {
                        list.push(<th colSpan={months[i].days.length} key={"months_"+i}>{months[i].month}</th>);
                    }
                    return list;
                })()}
            </tr>
            <tr>
                <th>Placement</th>
                <th>eCPM</th>
                {(() => {
                    let list = [];
                    for(let i = 0; i < months.length; i++) {
                        for(let j = 0; j < months[i].days.length; j++) {
                            list.push(<th key={"days_"+list.length}>{months[i].days[j]}</th>)
                        }
                    }
                    return list;
                })()}
            </tr>
        </thead>
    );
}

class MaxCountryImpressionsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this._tasksUI = createTaskStateUI(this, "taskData");
        this.state = {
            updateCounter : 1
        };

        this.retriveImpressions = this.retriveImpressions.bind(this);
        this.drawTable = this.drawTable.bind(this);
    }

    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
        this._tasksUI.clearTasks();
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    drawTable() {
        let tableData = [];
        if (this.state.data && this.state.dayList && this.state.dayList.length > 0) {
            for(let network in this.state.data) {
                tableData.push(
                    <h3 className="text-center mt-3" key={"networkList_"+tableData.length}>{network}</h3>
                );
                tableData.push(
                    <Table className="table"  key={"networkList_"+tableData.length}>
                        {drawDayHeader(this.state.dayList)}
                        <tbody>
                            {(() => {

                                let list = [];
                                for(let k = 0; k < this.state.data[network].length; k++) {
                                    if (!this.state.data[network][k].isKnown) {
                                        continue;
                                    }
                                    let instanceId = this.state.data[network][k].placementId;
                                    let ecpm = this.state.data[network][k].cpm;
                                    let fillRates = [];
                                    for(let i = 0; i < this.state.dayList.length; i++) {
                                        const day = this.state.dayList[i];
                                        if (this.state.data[network][k].days[day]) {
                                            let fillRate = Math.trunc(this.state.data[network][k].days[day].fill_rate*10000)/100;
                                            let impressions = this.state.data[network][k].days[day].impressions;
                                            let revenue = Math.trunc(this.state.data[network][k].days[day].estimated_revenue*100)/100;
                                            let color = "white";
                                            if (fillRate < 0.5) {
                                                color = "red";
                                            }
                                            if (fillRate > 1.0) {
                                                color = "green";
                                            }
                                            fillRates.push(

                                                    <td className={"text-"+color} id={CSS.escape("tooltip-"+network+"-"+day+"-"+list.length)} key={"fillrates_"+fillRates.length}>
                                                        <span>{fillRate}%</span>
                                                        <UncontrolledTooltip placement="top" target={CSS.escape("tooltip-"+network+"-"+day+"-"+list.length)}>
                                                            {"$"+revenue+" - "+impressions+" Imp"}
                                                        </UncontrolledTooltip>
                                                    </td>


                                            );
                                        } else {
                                            fillRates.push(<td key={"fillrates_"+fillRates.length}></td>);
                                        }
                                    }
                                    list.push(
                                        <tr className="text-white" key={"networkLine_"+list.length}>
                                            <td>{instanceId}</td>
                                            <td>${ecpm}</td>
                                            {fillRates}
                                        </tr>
                                    );
                                }
                                return list;
                            })()}
                        </tbody>
                    </Table>
                )
            }

            return tableData;

        }
        return null;
    }

    retriveImpressions() {
        this._tasksUI.startTask("Retrieving ecpm...");
        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);
        this._ctx.sendAuthenticated({type: "analytics_v3",subType: "maxAnalytics", gameId : this.props.urlParams.gameId, platform : this.props.urlParams.platform, start : startDate, end: endDate, columns : ["day","ecpm"], filters : {country: this.props.urlParams.country}}, (_err, _ecpmResponse) => {
            this._tasksUI.markTasksCompleted();
            if (_err) {
                return this.setState((_prevState, _props) => {
                    return {error : _err};
                });
            }
            this._tasksUI.startTask("Retrieving Impressions...");
            this._ctx.sendAuthenticated({type: "analytics_v3",subType: "maxAnalytics", gameId : this.props.urlParams.gameId, platform : this.props.urlParams.platform, start : startDate, end: endDate, columns : ["day","network","network_placement","fill_rate","impressions","estimated_revenue"], filters : {country: this.props.urlParams.country}}, (_err, _response) => {
                this._tasksUI.markTasksCompleted();

                if (_err) {
                    return this.setState((_prevState, _props) => {
                        return {error : _err};
                    });
                }
                else {

                    this._tasksUI.startTask("Retrieving AdUnit Information...");
                    this._ctx.sendAuthenticated({type: "analytics_v3", subType: "maxAdUnits", gameId :this.props.urlParams.gameId, platform : this.props.urlParams.platform }, (_err, _adUnitResponse) => {
                        this._tasksUI.markTasksCompleted();
                        if (_err) {
                            return this.setState((_prevState, _props) => {
                                return {error : _err};
                            });
                        }
                        let adUnitConfig = {};
                        if (_adUnitResponse.result && _adUnitResponse.result.ad_network_settings) {
                            for (let i = 0; i < _adUnitResponse.result.ad_network_settings.length; i++) {
                                const adNetworkConfig = _adUnitResponse.result.ad_network_settings[i];
                                let networkName = null;
                                for(let key in adNetworkConfig) {
                                    if (key !== null && key !== "") {
                                        networkName = key;
                                        break;
                                    }
                                }
                                for(let j = 0; j < adNetworkConfig[networkName].ad_network_ad_units.length; j++) {
                                    let floor = adNetworkConfig[networkName].ad_network_ad_units[j];
                                    if (!adUnitConfig[networkName]) {
                                        adUnitConfig[networkName] = {};
                                    }
                                    adUnitConfig[networkName][floor.ad_network_ad_unit_id] = floor;
                                }
                            }
                        }

                        let data = {};
                        let days = {};
                        for(let i = 0; i < _response.result.length; i++) {
                            const current = _response.result[i];
                            if(!data[current.network]) {
                                data[current.network] = [];
                            }
                            let placementEntry = null;
                            for (let j = 0; j < data[current.network].length; j++) {
                                if (data[current.network][j].placementId === current.network_placement) {
                                    placementEntry = data[current.network][j];
                                    break;
                                }
                            }
                            if (placementEntry === null) {
                                placementEntry = { placementId : current.network_placement, isKnown : false, cpm: 0, days : {}};
                                if (adUnitConfig[current.network] && adUnitConfig[current.network][current.network_placement]) {
                                    placementEntry.isKnown = true;
                                    placementEntry.cpm = parseFloat(adUnitConfig[current.network][current.network_placement].cpm);
                                    placementEntry.disabled = adUnitConfig[current.network][current.network_placement].disabled;
                                }
                                data[current.network].push(placementEntry);
                            }
                            placementEntry.days[current.day] = current;
                            days[current.day] = true;
                        }
                        let dayList = [];
                        for (let d in days) {
                            dayList.push(d);
                        }
                        for(let network in data) {
                            data[network].sort( (_a,_b) => {return _b.cpm-_a.cpm;});
                        }
                        let ecpmDataSeries = [];
                        for (let i = 0; i < _ecpmResponse.result.length; i++) {
                            ecpmDataSeries.push({y: parseFloat(_ecpmResponse.result[i].ecpm), x : parseToUnixTime(_ecpmResponse.result[i].day)});
                        }
                        ecpmDataSeries.sort((_a,_b) => {return _a.x-_b.x});
                        let ecpmData = [
                            {
                                name: "ecpm",
                                data : ecpmDataSeries
                            }
                        ];
                        this.setState((_prevState, _props) => {
                            return {dayList: dayList, data : data, ecpmData: ecpmData};
                        });
                    });
                }
        });
        });
    }
    render() {
        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);
        let retrieveButton = null;
        if (!this._tasksUI.hasActiveTask()) {
            retrieveButton = (<Button color="primary" onClick={this.retriveImpressions}>Retrieve Impressions</Button>);
        }
        let analyticsPlatform = "<none>";
        if (this.props.urlParams.platform === "ios") {
            analyticsPlatform = "IPhonePlayer";
        } else if (this.props.urlParams.platform === "android") {
            analyticsPlatform = "Android";
        }
        return (
            <Fragment>
                <br/>
                <br/>
                {this._tasksUI.drawTaskState()}
                {retrieveButton}
                <br/>
                <br/>
                <br/>
                {(()=> {
                    if (this.state.ecpmData) {
                        return (
                            <Fragment>
                                <Row>
                                    <Col lg={ 6} md={12} className="mt-3">
                                        <LineGraph title="eCPM" xAxis="time" yAxis="number" data={this.state.ecpmData} />
                                    </Col>
                                    <Col lg={ 6} md={ 12 } className="mt-3">
                                        <AdLatency_Daily_Line startDate={startDate} endDate={endDate} filter={{game_id : this.props.urlParams.gameId, platform : analyticsPlatform, country:this.props.urlParams.country }} average />
                                    </Col>
                                </Row>
                            </Fragment>
                        );
                    }
                    return null;
                })()}

                {this.drawTable()}
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(MaxCountryImpressionsPage,["time-2week"], (_props)=> {return "Max Impressions: "+_props.urlParams.gameId+" "+_props.urlParams.country+" "+_props.urlParams.platform;}));