import React from "react";
import {Alert, Button, Card, CardBody, CardHeader, CardText, Col, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faTrash, faSync} from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import {Link} from "react-router-dom";

import MeteorContext from "../meteorContext";
import DeleteUserModel from "./DeleteUserModal";
import ResetPasswordModal from "./ResetPasswordModal";

function clone(_data) {
    return JSON.parse(JSON.stringify(_data));
}

class MeteorAdminUser extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            deleteUserModelOpen : false,
            resetPasswordModalOpen: false,
            isSaving : false,
            locked : _props.user.locked,
            permissions : clone(_props.user.permissions),
            hasChanges : false,
            lockChanged : false,
            permissionChanged : false
        };

        this.onLockChange = this.onLockChange.bind(this);
        this.onPermissionChange = this.onPermissionChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCloseDelteModal = this.onCloseDelteModal.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
        this.onCloseResetPasswordModal = this.onCloseResetPasswordModal.bind(this);
    }

    onPermissionChange(event) {
        let oldPermission = this.state.permissions;
        oldPermission[event] = !oldPermission[event];
        this.setState({hasChanges: true, permissionChanged: true, permissions : oldPermission});
    }

    onLockChange(event) {
        let locked = !this.state.locked;
        this.setState({
            hasChanges : true,
            lockChanged : true,
            locked : locked
        });
    }

    onCancel(event) {
        this.setState({
            hasChanges : false,
            lockChanged : false,
            permissionChanged: false,
            locked : this.props.user.locked,
            permissions : clone(this.props.user.permissions)
        });
    }

    updateLockState(_hasChanges, _userId, _locked, _cb) {
        if (_hasChanges) {
            return MeteorContext.changeLockState(_userId, _locked, (_err) => {
                if (_err) {
                    return _cb(_err);
                }
                return _cb(null);
            });
        } else {
            return _cb(null);
        }
    }

    updatePermissions(_hasChanges, _userId, _permissions, _cb) {
        if (_hasChanges) {
            let permissionList = [];
            for(let key in _permissions) {
                permissionList.push({permission : key, allow : _permissions[key]});
            }
            return MeteorContext.changePermissions(_userId, permissionList, (_err) => {
                if(_err) {
                    return _cb(_err);
                }
                return _cb(null);
            });
        } else {
            return _cb(null);
        }
    }

    onSave(event) {
        this.setState({isSaving : true});
        return this.updateLockState(this.state.lockChanged, this.props.user.id, this.state.locked, (_err) => {
            if (_err) {
                this.setState({isSaving: false, error: { title : "Could not change lock", text : _err.code+": "+_err.message}});
            }
            return this.updatePermissions(this.state.permissionChanged, this.props.user.id, this.state.permissions, (_err) => {
                if (_err) {
                    this.setState({isSaving: false, error: { title : "Could not change lock", text : _err.code+": "+_err.message}});
                }
                this.setState({isSaving : false, hasChanges : false, lockChanged : false, permissionChanged: false});
            });
        });
    }

    onResetPassword(_event) {
        this.setState({resetPasswordModalOpen: true});
    }

    onCloseResetPasswordModal(_event) {
        this.setState({resetPasswordModalOpen: false});
    }

    onDelete(event) {
        this.setState({deleteUserModelOpen: true});
    }

    onCloseDelteModal(event) {
        this.setState({deleteUserModelOpen: false});
    }

    render() {
        let permissionsUI = [];
        const permissions = this.state.permissions;
        const validPermissions = MeteorContext.getValidPermissions();
        if (permissions) {
            for(let i = 0; i < validPermissions.length; i++) {
                const permission = validPermissions[i];
                permissionsUI.push(
                    <tr key={permission}>
                        <td className="text-inverse">
                            {permission}
                        </td>
                        <td className="text-right">
                            <Toggle id={permission} checked={permissions[permission]} onChange={()=> {this.onPermissionChange(permission);}} disabled={this.state.isSaving}/>
                        </td>
                    </tr>
                )
            }
        }
        let saveButton;
        if (this.state.hasChanges) {
            saveButton = (<Button color="primary" tag={ Link } onClick={this.onSave} disabled={this.state.isSaving}>
                {(() => {
                    if (this.state.isSaving) {
                        return (<FontAwesomeIcon icon={faSync} spin pull="left"/>);
                    }
                })()}
                { this.state.isSaving ? 'Saving ...' : "Save" }
            </Button>)
        }
        let cancelButton;
        if (this.state.hasChanges) {
            cancelButton = (<Button color="secondary" tag={ Link } onClick={this.onCancel} disabled={this.state.isSaving}>Cancel</Button>)
        }

        let error;
        if (this.state.error) {
            error = (
                <Alert color="danger">
                    <i className="fa fa-times-circle mr-1 alert-icon" />
                    <span>
                        <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                    </span>
                </Alert>
            );
        }

        return (
            <Card className="mb-3">
                <CardHeader tag="h6" className="bg-info text-white">
                    <Row>
                        <Col>
                            <FontAwesomeIcon icon={faUser} pull="left"/>{this.props.user.id}
                        </Col>
                        <Col >
                            <Button className="" size="sm" color="danger" onClick={this.onResetPassword}>Change Password</Button>
                            <ResetPasswordModal isOpen={this.state.resetPasswordModalOpen} userId={this.props.user.id} onClose={this.onCloseResetPasswordModal}/>
                            <Button className="float-right" size="sm" color="danger" onClick={this.onDelete} ><FontAwesomeIcon icon={faTrash} /></Button>
                            <DeleteUserModel isOpen={this.state.deleteUserModelOpen} userId={this.props.user.id} onClose={this.onCloseDelteModal}/>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {error}
                    <CardText>
                        <Table className="table">
                            <tbody>
                                <tr>
                                    <td className="text-inverse">
                                        Locked
                                    </td>
                                    <td className="text-right">
                                        <Toggle id='locked' checked={this.state.locked} onChange={() => {this.onLockChange();}} disabled={this.state.isSaving}/>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table className="table">
                            <thead>
                            <tr>
                                <th>
                                    Permissions
                                </th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>{permissionsUI}</tbody>
                        </Table>
                    </CardText>
                    {cancelButton} {saveButton}
                </CardBody>
            </Card>
        );
    }
}

export default MeteorAdminUser;