import React from "react";
import {Button, Card, CardBody, CardHeader, CardText, Col, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faTrash, faSync} from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import {Link} from "react-router-dom";

function clone(_data) {
    return JSON.parse(JSON.stringify(_data));
}

class AnalyticsAdminUser extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            permissions : clone(_props.permissions ? _props.permissions : {}),
            hasChanges : false
        };

        this.onPermissionChange = this.onPermissionChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    onPermissionChange(event) {
        let oldPermission = this.state.permissions;
        oldPermission[event] = !oldPermission[event];
        this.setState({hasChanges: true, permissions : oldPermission});
    }

    onCancel(event) {
        this.setState({
            permissions : clone(this.props.permissions ? this.props.permissions : {}),
            hasChanges: false
        });
    }

    onSave(event) {
        if (this.props.onSave && typeof this.props.onSave === "function") {
            this.props.onSave(this.state.permissions);
        }
    }

    render() {
        let permissionsUI = [];
        const permissions = this.state.permissions;
        if (this.props.validPermissions) {
            for(let i = 0; i < this.props.validPermissions.length; i++) {
                const permission = this.props.validPermissions[i];
                permissionsUI.push(
                    <tr key={permission}>
                        <td className="text-inverse">
                            {permission}
                        </td>
                        <td className="text-right">
                            <Toggle id={permission} checked={permissions[permission]} onChange={()=> {this.onPermissionChange(permission);}} disabled={this.props.disabled}/>
                        </td>
                    </tr>
                )
            }
        }
        let saveButton;
        if (this.state.hasChanges) {
            saveButton = (<Button color="primary" onClick={this.onSave} disabled={this.props.disabled}>
                {(() => {
                    if (this.props.disabled) {
                        return (<FontAwesomeIcon icon={faSync} spin pull="left"/>);
                    }
                })()}
                { this.props.disabled ? 'Saving ...' : "Save" }
            </Button>)
        }
        let cancelButton;
        if (this.state.hasChanges) {
            cancelButton = (<Button color="secondary" onClick={this.onCancel} disabled={this.props.disabled}>Cancel</Button>)
        }

        return (
            <Card className="mb-3 mt-3">
                <CardHeader tag="h6" className="bg-info text-white">
                    <Row>
                        <Col>
                            <FontAwesomeIcon icon={faUser} pull="left"/>{this.props.username}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <CardText>
                        <Table className="table">
                            <thead>
                            <tr>
                                <th>
                                    Permissions
                                </th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody> {permissionsUI} </tbody>
                        </Table>
                    </CardText>
                    {cancelButton} {saveButton}
                </CardBody>
            </Card>
        );
    }
}

export default AnalyticsAdminUser;