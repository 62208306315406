
function YAxisFormatPercent() {
    return {
        min: 0, max:100,
            allowDecimals: false,
            labels : {
            format: "{value:.2f}%"
        },
        title: {
            enabled : false
        }
    };
}

function YAxisFormatNumber() {
    return {
        title: {
            enabled : false
        },
        min: 0,
            allowDecimals: false,
    };
}

function YAxisFormatTime() {
    return {
        title: {
            enabled : false
        },
        min: 0,
            allowDecimals: false,
            labels : {
            formatter: formatAxisTimeFn
        }
    };
}

function YAxisFormatEuro() {
    return {
        title : {
            enabled: false
        },
        min: 0,
        allowDecimals: true,
        labels : {
            format: "{value:.2f}€"
        }
    };
}

function tooltipFormat(_xAxis, _yAxis, _percent, _sampleName) {
    return {
        split: true,
        crosshairs: true,
        outside: true,
        formatter : formatTooltipGenerator(_xAxis, _yAxis, _percent, _sampleName)
    };
}

function tooltipPieFormat(_yAxis) {
    return {
        formatter : formatTooltipPieGenerator(_yAxis)
    }
}


function XAxisFormatTime() {
    return {
        type: "datetime"
    }
}

function XAxisFormatRetention() {
    return {
        allowDecimals: false,
            labels : {
            format: "D{value}"
        }
    };
}

function XAxisFormatNone() {
    return {
        visible : false,
        labels : {
            format: ""
        }
    };
}

function formatTooltipPieGenerator(_yAxis) {
    function fn() {
        let text = this.point.name+": <b>"+(Math.round(this.point.percentage*100)/100)+"%</b> - ";
        if (_yAxis === "percent") {
            text += (Math.round(this.point.y*100)/100)+"%";
        } else if (_yAxis === "euro") {
            text += (Math.round(this.point.y*100)/100)+"€";
        } else if (_yAxis === "time") {
            text += formatTime(this.point.y);
        } else {
            text += (Math.round(this.point.y*100)/100);
            if (_yAxis) {
                text += " "+_yAxis;
            }
        }
        if (this.point.sampleCount) {
            text += " ("+this.point.sampleCount+" Users)";
        } else if (this.point.samples) {
            text += " ("+this.point.samples+" Users)";
        }
        return text;
    }
    return fn;
}

function formatTooltipGenerator(_xAxis, _yAxis, _percent, _sampleName) {
    function fn() {
        let result = [];
        if (_xAxis === "retention") {
            result.push("D"+this.x);
        } else if (_xAxis === "time") {
            const d = new Date(this.x);
            result.push(format2Digit(d.getUTCDate())+"."+format2Digit(d.getUTCMonth()+1)+"."+d.getUTCFullYear());
        }
        else {
            result.push(this.x);
        }
        for(let i = 0; i < this.points.length; i++) {
            const p = this.points[i];
            let text = "";
            if (this.points.length > 1) {
                text += p.series.name+": ";
            }
            if (_yAxis === "percent") {
                text += "<b>"+(Math.round(p.point.y*100)/100)+"%</b>";
            } else if (_yAxis === "euro") {
                text += "<b>"+(Math.round(p.point.y*100)/100)+"€</b>";
            } else if (_yAxis === "time") {
                text += "<b>"+formatTime(p.point.y)+"</b>";
            } else {
                text += "<b>"+(Math.round(p.point.y*100)/100)+"</b>";
            }
            if (p.point.sampleCount) {
                text += " ("+p.point.sampleCount+" "+(_sampleName ? _sampleName : "Users")+")";
            } else if (p.point.samples) {
                text += " ("+p.point.samples+" "+(_sampleName ? _sampleName : "Users")+")";
            }
            if (_percent) {
                text += " ("+(Math.round(p.point.percentage*100)/100)+"%)";
            }
            if (p.point.variance) {
                text += " σ=";
                if (_yAxis === "percent") {
                    text += p.point.variance+"%";
                } else if (_yAxis === "euro") {
                    text += (Math.round(p.point.variance*100)/100)+"€";
                } else if (_yAxis === "time") {
                    text += formatTime(p.point.variance);
                } else {
                    text += (Math.round(p.point.variance*100)/100);
                }
            }
            result.push(text);
        }
        return result;
    }
    return fn;
}
function format2Digit(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return _num;
}

function formatAxisTimeFn() {
    return formatTime(this.value);
}

function formatTime(_seconds) {
    _seconds = Math.trunc(_seconds);
    let hours   = Math.floor(_seconds / 3600);
    let minutes = Math.floor((_seconds - (hours * 3600)) / 60);
    let seconds = _seconds - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (hours > 0) {
        return hours+'h '+minutes+'m '+seconds+'s';
    } else {
        return minutes+'m '+seconds+'s';
    }
}

export {
    XAxisFormatTime,
    XAxisFormatRetention,
    XAxisFormatNone,
    YAxisFormatNumber,
    YAxisFormatPercent,
    YAxisFormatTime,
    YAxisFormatEuro,
    tooltipFormat,
    tooltipPieFormat
}