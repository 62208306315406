import React, {Fragment} from "react";

import withFilter from "./SiteWithFilter";
import withURLParams from "../common/SiteWithUrlParams";
import {Row} from "reactstrap";
import Privacy_Comp from "./Components/Privacy_Comp";
import {mergeFilters} from "./Filters/FilterLogic";
class PrivacyPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
        };

    }

    render() {
        let androidFilter = mergeFilters(this.props.filter, {platform: "Android", game_id : this.props.urlParams.gameId});
        let iosFilter = mergeFilters(this.props.filter, {platform: "IPhonePlayer", game_id : this.props.urlParams.gameId});
        return (
            <Fragment>
                <Row>
                    <h3 className="text-center mt-3">Android</h3>
                </Row>
                <Privacy_Comp filter={androidFilter} dateSelection={this.props.dateSelection}/>
                <Row>
                    <h3 className="text-center mt-3">iOS</h3>
                </Row>
                <Privacy_Comp filter={iosFilter} dateSelection={this.props.dateSelection}/>
            </Fragment>
        )
    }
}

export default withURLParams(withFilter(PrivacyPage, ["filter","time"],(_props)=> {return "Privacy";}));