import AnalyticsContext from "../AnalyticsContext";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import GraphUtil from "./Graphs/GraphUtil";
import StatisticUtil from "../StatisticUtil";
import LineGraph from "./Graphs/LineGraph";
import {mergeFilters} from "../Filters/FilterLogic";

class CampaignPLDetailedROAS_Line {
    constructor() {
    }

    static getId() {
        return "CampaignPLDetailedROAS_Line";
    }

    static getGraphProps(_props) {
        let title = "ROAS";
        return {
            title : title,
            xAxis : "time",
            yAxis : "percent",
            lowPercentage: true
        };
    }

    static getRequiredProps() {
        return ["from","to","campaignId","pils"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        const filter = mergeFilters({ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}, _props.filter);
        ctx.sendAsync("iap",{type: "analytics_v3", subType: "es_premiumPurchase_v3", group: ["install_date","day"], filter : filter});
        ctx.sendAsync("ad",{type: "analytics_v3", subType: "es_adImpression_v3", group: ["install_date","day"], filter : filter});
        ctx.sendAsync("cpi",{type: "analytics_v3", subType: "es_user_v3", group: ["install_date"], filter : filter});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                return _cb(_err);
            } else {
                let now = Date.now();
                let cost = AnalyticsUtil.aggregateBy(_data.cpi.result.result, "install_date", "cpi", StatisticUtil.sum);
                let costLookup = AnalyticsUtil.generateLookup(cost, "install_date","cpi", (_a) => parseFloat(_a));
                let installLookup = AnalyticsUtil.generateLookup(cost, "install_date","installs", (_a) => parseFloat(_a));
                let graphData;

                const revenue = AnalyticsUtil.join(_data.iap.result.result, _data.ad.result.result);
                const revenuePil = AnalyticsUtil.addPil(revenue);
                for (let i = 0; i < _props.pils.length; i++) {
                    const pil = _props.pils[i];
                    let dRevData = AnalyticsUtil.filterBy(revenuePil, (_entry) => {return _entry.pil <= pil});
                    let dRev = AnalyticsUtil.aggregateBy(dRevData, "install_date", "revenue", StatisticUtil.sum);
                    AnalyticsUtil.scaleBy(dRev, "install_date","revenue", (_installDate) => 100/costLookup[_installDate]);
                    for(let i = 0; i < dRev.length; i++) {
                        dRev[i].samples = installLookup[dRev[i].install_date];
                    }
                    for(let i = dRev.length -1 ; i >= 0; i--) {
                        const  installDate = new Date(dRev[i].install_date);
                        if (installDate.getTime() + (pil * 24*60*60*1000) > now) {
                            dRev.splice(i,1);
                        }
                    }

                    let dRevReport = GraphUtil.generateNamedTimeseries("D"+pil, dRev, "install_date","revenue");
                    graphData = GraphUtil.mergeReports(graphData, dRevReport);
                }

                let rev = AnalyticsUtil.aggregateBy(revenue, "install_date", "revenue", StatisticUtil.sum);
                AnalyticsUtil.scaleBy(rev, "install_date","revenue", (_installDate) => 100/costLookup[_installDate]);
                for(let i = 0; i < rev.length; i++) {
                    rev[i].samples = installLookup[rev[i].install_date];
                }
                let revReport = GraphUtil.generateNamedTimeseries("LTV", rev, "install_date","revenue");
                graphData = GraphUtil.mergeReports(graphData, revReport);

                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(CampaignPLDetailedROAS_Line, LineGraph);