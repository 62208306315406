import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeteor } from '@fortawesome/free-solid-svg-icons'

import { UncontrolledTooltip } from "reactstrap";
import { Tools } from './../../../components';

const SidebarTopB = () => (
    <React.Fragment>
        { /* START Sidebar TOP: B */ }
            { /* START DESKTOP View */ }
            <Tools.DefaultOnly>
                <div className="d-flex">
                    <Link to="/" className="align-self-center" id="tooltipBackToHome">
                        <FontAwesomeIcon icon={faMeteor} size="2x" />
                    </Link>
                    <UncontrolledTooltip placement="right" target="tooltipBackToHome">
                        Back to Home
                    </UncontrolledTooltip>
                            <div className="h5 fw-900 sidebar-logo mb-1 mt-1 ml-3 text-left">Meteor
                            </div>
                </div>
            </Tools.DefaultOnly>
            { /* END DESKTOP View */ }
            { /* START SLIM Only View */ }
            <Tools.SlimOnly>
                <div className="d-flex justify-content-center">
                    <Link to="/">
                        <FontAwesomeIcon icon={faMeteor} size="2x" id="tooltipBackToHomeSlim"/>
                    </Link>
                    <UncontrolledTooltip placement="right" target="tooltipBackToHomeSlim">
                        Back to Home
                    </UncontrolledTooltip>
                </div>
            </Tools.SlimOnly>
            { /* END SLIM Only View  */ }
        { /* END Sidebar TOP: B */ }
    </React.Fragment>
)

export { SidebarTopB };
