import BarGraph from "./Graphs/BarGraph";
import AnalyticsContext from "../AnalyticsContext";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import StatisticUtil from "../StatisticUtil";
import GraphUtil from "./Graphs/GraphUtil";

class CampaignPL_Cohort_Bar {
    static getId() {
        return "CampaignPL_Cohort_Bar";
    }

    static getGraphProps(_props) {
        return {
            title : "Spend & Revenue",
            xAxis : "time",
            yAxis : "euro"
        };
    }

    static getRequiredProps() {
        return ["from","to","campaignId"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("iap",{type: "analytics_v3", subType: "es_premiumPurchase_v3", group: ["install_date"], filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.sendAsync("ad",{type: "analytics_v3", subType: "es_adImpression_v3", group:["install_date"], filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.sendAsync("cpi",{type: "analytics_v3", subType: "es_user_v3", group:["install_date"], filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                return _cb(_err);
            } else {
                const revenueData = AnalyticsUtil.join(_data.iap.result.result ,_data.ad.result.result);
                const revenue = AnalyticsUtil.aggregateBy(revenueData, "install_date", "revenue", StatisticUtil.sum);
                const revenueReport = GraphUtil.generateNamedTimeseries("Revenue", revenue, "install_date", "revenue");
                let cost = AnalyticsUtil.aggregateBy(_data.cpi.result.result, "install_date", "cpi", StatisticUtil.sum);
                let costReport = GraphUtil.generateNamedTimeseries("Cost",cost,"install_date","cpi");
                let graphData = GraphUtil.mergeReports(revenueReport, costReport);
                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(CampaignPL_Cohort_Bar, BarGraph);