import React, {Fragment} from "react";
import {Button} from "reactstrap";

import Table from "reactstrap/es/Table";

import FilterUI from "./FilterUI";
import {buildFilter} from "./FilterLogic";


const uuidv4 = require("uuid/v4");

class FilterSelection extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            filters : _props.filters ? _props.filter : []
        };

        this.onAddFilter = this.onAddFilter.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onDeleteFilter = this.onDeleteFilter.bind(this);
        this.notifyFilterUpdate = this.notifyFilterUpdate.bind(this);
    }

    notifyFilterUpdate(_filters) {
        if (this.props.onChange && typeof this.props.onChange === "function") {
            const filter = buildFilter(_filters);
            this.props.onChange(filter);
        }
    }

    onAddFilter() {
        this.setState((_prevState, _props) => {
            let filterCopy = JSON.parse(JSON.stringify(_prevState.filters));
            filterCopy.push({id : uuidv4()});
            return {filters: filterCopy}
        });
    }

    onFilterChanged(_filter) {
        this.setState((_prevState, _props) => {
            let filterCopy = JSON.parse(JSON.stringify(_prevState.filters));
            for (let i = 0; i < filterCopy.length; i++) {
                if (filterCopy[i].id === _filter.id) {
                    filterCopy[i] = _filter;
                    break;
                }
            }
            this.notifyFilterUpdate(filterCopy);
            return {filters : filterCopy};
        });
    }

    onDeleteFilter(_filter) {
        this.setState((_prevState, _props) => {
            let idx = -1;
            for (let i = 0; i < _prevState.filters.length; i++) {
                if (_prevState.filters[i].id === _filter.id) {
                    idx = i;
                    break;
                }
            }
            if (idx >= 0) {
                let filterCopy = JSON.parse(JSON.stringify(_prevState.filters));
                filterCopy.splice(idx,1);
                this.notifyFilterUpdate(filterCopy);
                return {filters : filterCopy};
            }
            return null;
        });
    }

    render() {
        let renderedFilters  = [];
        for (let i = 0; i < this.state.filters.length; i++) {
            let filter = this.state.filters[i];
            renderedFilters.push(<FilterUI key={filter.id} filter={filter} onChanged={this.onFilterChanged} onDelete={this.onDeleteFilter}/>);
        }
        
        return (
            <Fragment>
                <Table className="table">
                    <thead>
                    <tr>
                    <th>
                        Filters ({this.state.filters.length})
                    </th><th/><th/>
                    </tr>
                    </thead>
                    <tbody>
                    {renderedFilters}
                    </tbody>
                </Table>
                <Button color="primary" onClick={this.onAddFilter}>Add Filter</Button>
            </Fragment>
        )
    }
}

export default FilterSelection;