import AnalyticsContext from "../AnalyticsContext";
import PieGraph from "./Graphs/PieGraph";
import CachedGraph from "./Graphs/CachedGraph";

class AdPlacements_Pie {
    static getId() {
        return "AdPlacements_Pie";
    }

    static getGraphProps(_props) {
        return {
            title: "Ad Placements",
            yAxis: "Impressions",
            small: true,
            legend: true
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.day = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("adStats", {type : "analytics_v3", subType: "es_adImpression_v3", group: ["placement"], filter: mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let placements = {};
                for (let i = 0; i < _data.adStats.result.result.length; i++) {
                    const entry = _data.adStats.result.result[i];
                    if (!placements[entry.placement]) {
                        placements[entry.placement] = {viewed : 0, dismissed : 0};
                    }
                    placements[entry.placement].viewed += parseInt(entry.impressions,10);
                    placements[entry.placement].dismissed += parseInt(entry.discards,10);
                }
                let allAds = [];
                for (let adName in placements) {
                    const entry = placements[adName];
                    if (entry.viewed > 0) {
                        allAds.push({name: adName, y: entry.viewed});
                    }
                }
                allAds.sort((a,b) => a.name.localeCompare(b.name));
                const graphData =  [{data: allAds}];
                return _cb(null, graphData);
            }

        });
    }

}

export default CachedGraph(AdPlacements_Pie, PieGraph);