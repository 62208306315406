import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import AnalyticsContext from "../AnalyticsContext";
import CampaignPL_Cohort_Bar from "./CampaignPL_Cohort_Bar";
import CampaignPL_Bar from "./CampaignPL_Bar";
import LoadingBannerUI from "../../common/LoadingBannerUI";
import ErrorBannerUI from "../../common/ErrorBannerUI";

class AllCampaignPL extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : true,
            name : _props.campaignId
        };

    }

    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
        this._ctx.sendAuthenticated({subType: "allCampaignPL", from: this.props.from, to: this.props.to}, (_err, _resposne) => {
            if(_err) {
                this.setState({error: { title : "Couldn't retrieve Campaign Data", text : _err.code+": "+_err.message}, isLoading: false});
            } else {
                this.setState({isLoading: false, name : _resposne.result.campaignName, data: _resposne.result.data});
            }
        });
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    render() {
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Loading Campaign Data..."/>);
        }
        if(this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>);
        }
        return(
            <Fragment>
                <Row>
                    <h3 className="text-center mt-3">{this.state.name}</h3>
                </Row>
                {(()=> {
                    if (this.state.data != null) {
                        return (
                            <Fragment>
                                <Row>
                                    <Col lg={ 6} md={ 12 } className="mb-3">
                                        <CampaignPL_Cohort_Bar data={this.state.data}/>
                                    </Col>
                                    <Col lg={ 3} md={ 6 } className="mb-3">
                                        <CampaignPL_Bar data={this.state.data}/>
                                    </Col>
                                </Row>
                            </Fragment>
                        );
                    }
                    return null;
                })()}

            </Fragment>
        );
    }
}

export default AllCampaignPL;