import React from 'react';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import { setupPage } from './../../../components/Layout/setupPage';

import { HeaderMain } from "../../components/HeaderMain";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const options = {
    chart: {
        type : 'pie'
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    title: {
        text: 'My chart'
    },
    series: [{
        data: [1, 2, 3]
    }]
}

const OverviewDashboard = () => {
    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Overview"
                        className="mb-4 mb-lg-5"
                    />
                    <p>
                        lorem ipsum
                    </p>
                </Col>
                <Col lg={ 3 } md={ 6 }>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
                </Col>
            </Row>
        </Container>
    )};

export default setupPage({
    pageTitle: 'Overview Dashboard'
})(OverviewDashboard);