import React from 'react';
import {Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import _ from 'lodash';
import {Link} from "react-router-dom";

import MeteorContext from "../meteorContext";

class ResetPasswordModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password1 : "",
            password2 : "",
            isChangingPassword : false,
            error : null
        };

        this.onPassword1Changed = this.onPassword1Changed.bind(this);
        this.onPassword2Changed = this.onPassword2Changed.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    onCancel(event) {
        this.closeModal();
    }

    onPassword1Changed(event) {
        this.setState({password1 : event.target.value});
    }

    onPassword2Changed(event) {
        this.setState({password2 : event.target.value});
    }

    onSave(event) {
        if (this.state.password1 !== this.state.password2) {
            this.setState({error : {
                    title : "Passwords do not match!",
                    text : ""
                }});
        } else if (this.state.password1.length < 5) {
            this.setState({error : {
                    title : "Passwords too short.",
                    text : "The password has to be at least 5 characters."
                }});

        } else {
            this.setState({isChangingPassword: true});
            return MeteorContext.setPassword(this.props.userId, this.state.password1, (_err) => {
                if(_err) {
                    this.setState({
                        isChangingPassword : false,
                        error : {
                            title : _err.code+": "+_err.message,
                            text : ""
                        }
                    });
                } else {
                    this.closeModal()
                }

            });
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    closeModal() {
        this.setState({ password1: "", password2: "", isChangingPassword: false, error: null});
        if (this.props.onClose && typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }



    render() {
        const modalProps = _.omit(this.props, ['target','userId']);

        return (
            <Modal { ...modalProps } isOpen={ this.props.isOpen } toggle={ this.onCancel } >
                <ModalHeader tag="h6">
                                <span className="text-info">
                                    Reset Password
                                </span>
                    <span className="small ml-1 text-muted">
                                    Please enter the new password
                                </span>
                </ModalHeader>
                <ModalBody>
                    {
                        (() => {
                            if (this.state.error) {
                                return (
                                    <Alert color="danger">
                                        <i className="fa fa-times-circle mr-1 alert-icon"></i>
                                        <span>
                                            <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                                        </span>
                                    </Alert>
                                );
                            }
                        })()
                    }
                    <Form className="mb-3">
                        <FormGroup>
                            <Label for="password1">
                                Password:
                            </Label>
                            <Input type="password" name="password1" id="password1" placeholder="Enter password..." value={this.state.password1} onChange={this.onPassword1Changed} disabled={this.state.isChangingPassword} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password2">
                                Retype Password:
                            </Label>
                            <Input type="password" name="password2" id="password2" placeholder="Retype password..." value={this.state.password2} onChange={this.onPassword2Changed} disabled={this.state.isChangingPassword} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" tag = { Link } disabled={this.state.isChangingPassword} onClick={this.onCancel}>
                        Cancel
                    </Button>
                    <Button color="info" tag={ Link }  disabled={this.state.isChangingPassword} onClick={this.onSave}>
                        {(() => {
                            if (this.state.isChangingPassword) {
                                return (<i className="fa fa-fw fa-refresh" style={{animation: "fa-spin 1.5s linear infinite"}} />);
                            }
                        })()}
                        { this.state.isChangingPassword ? 'Saving ...' : "Save" }
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ResetPasswordModal;