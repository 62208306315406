import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button, Input} from "reactstrap";
import {
    getDefaultText,
    getFilterTypes,
    getPredefinedValues,
    hasPredefinedValues,
    isSupportedFilter
} from "./FilterLogic";
import TextDropdown from "../../routes/Dashboards/Overview/TextDropdown";


class FilterUI extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            hasChanges : false,
            backup : JSON.parse(JSON.stringify(_props.filter)),
            options : getFilterTypes(),
            type : _props.filter.type,
            value : _props.filter.value
        };

        this.onFilterTypeChanged = this.onFilterTypeChanged.bind(this);
        this.onFilterValueChanged = this.onFilterValueChanged.bind(this);
        this.onApply = this.onApply.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.renderValue = this.renderValue.bind(this);
    }

    onFilterTypeChanged(_type) {
        this.setState({hasChanges: true, type: _type});
    }

    onFilterValueChanged(_value) {
        if (typeof _value === "string") {
            this.setState({hasChanges: true, value : _value});
        } else if (_value && _value.target && _value.target.value) {
            this.setState({hasChanges: true, value : _value.target.value});
        }
    }

    onApply() {
        this.setState({
            hasChanges: false
        });
        if (this.props.onChanged && typeof this.props.onChanged === "function") {
            let filterCopy = JSON.parse(JSON.stringify(this.props.filter));
            filterCopy.type = this.state.type;
            filterCopy.value = this.state.value;
            this.props.onChanged(filterCopy);
        }
    }

    onCancel() {
        if (!this.state.type) {
            this.onDelete();
        } else {
            this.setState({
                hasChanges: false,
                type : this.state.backup.type,
                value : this.state.backup.value
            });
        }
    }

    onDelete() {
        if (this.props.onDelete && typeof this.props.onDelete === "function") {
            this.props.onDelete(this.props.filter);
        }
    }

    renderValue() {
        if (!isSupportedFilter(this.state.type)) {
            return null;
        }
        if (hasPredefinedValues(this.state.type)) {
            return (
                <TextDropdown options={getPredefinedValues(this.state.type)} selected={this.state.value} onChange={this.onFilterValueChanged} />
            );
        } else {
            let placeholder = getDefaultText(this.state.type);
            return (
                <Input type="country" name="country" id="country" placeholder={placeholder} value={this.state.value} onChange={this.onFilterValueChanged} />
            );
        }
    }

    render() {
        const hasChanges = this.state.hasChanges || !this.state.type || this.state.type == "";
        return (
            <Fragment>
                <tr>
                    <td className="text-inverse">
                        <TextDropdown options={this.state.options} selected={this.state.type} onChange={this.onFilterTypeChanged}/>
                    </td>
                    <td className="text-inverse">
                        {this.renderValue()}
                    </td>
                    <td className="text-right">
                        {(() => {
                            if (hasChanges) {
                                return (
                                    <Fragment>
                                        <Button color="success" size="sm" className="mr-1" onClick={this.onApply} ><FontAwesomeIcon icon={faCheck} /></Button>
                                        <Button color="danger" size="sm" onClick={this.onCancel} ><FontAwesomeIcon icon={faTimes} /></Button>
                                    </Fragment>
                                );
                            } else {
                                return (<Button color="danger" size="sm" onClick={this.onDelete} ><FontAwesomeIcon icon={faTrash} /></Button>);
                            }
                        })()}


                    </td>
                </tr>
            </Fragment>
        )
    }
}

export default FilterUI;