import React, {Fragment} from "react";

import withFilter from "./SiteWithFilter";
import withURLParams from "../common/SiteWithUrlParams";
import {Col, Row} from "reactstrap";
import {mergeFilters} from "./Filters/FilterLogic";
import GraphCache from "./Components/Graphs/GraphCache";
import AnalyticsContext from "./AnalyticsContext";
import LoadingBannerUI from "../common/LoadingBannerUI";
import ErrorBannerUI from "../common/ErrorBannerUI";
import Toggle from "react-toggle";
import FPSDailyLine from "./Components/FPS_Daily_Line";
import DAU_Line from "./Components/DAU_Line";
import Installs_Line from "./Components/Installs_Line";
import AdImpressions_Daily_Line from "./Components/AdImpressions_Daily_Line";
import Revenue_Daily_Line from "./Components/Revenue_Daily_Line";
class DevicesPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading: true,
            toggles: {}
        };

        this.loadingBanner = this.loadingBanner.bind(this);
        this.errorBanner = this.errorBanner.bind(this);
        this.toggleDetailState = this.toggleDetailState.bind(this);
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Loading Device data..."/>);
        }
        return null;
    }


    errorBanner() {
        if(this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>);
        }
        else return null;
    }

    toggleDetailState(_name) {
        this.setState((_state, _props) => {
            let toggles = {};
            if (_state.toggles) {
                toggles = JSON.parse(JSON.stringify(_state.toggles));
            }
            if (toggles[_name] === true) {
                toggles[_name] = false;
            } else {
                toggles[_name] = true;
            }
            return {toggles: toggles};
        });
    }

    componentDidMount() {
        const hash = GraphCache.getHash("Devices_Page", this.props);
        const cached = GraphCache.getData(hash);
        if (cached) {
            return this.setState((_state, _props)=> {
                return {
                    isLoading: false,
                    deviceData: cached
                };
            });
        }
        let filter = {
            game_id : this.props.urlParams.gameId,
            day: {from: this.props.dateSelection.start, to: this.props.dateSelection.end},
            device_model : {notNull : true}
        };
        if (this.props.urlParams.platform === "ios") {
            filter.platform = "IPhonePlayer";
        }
        else if (this.props.urlParams.platform === "android") {
            filter.platform = "Android";
        }
        const mergedFilter = mergeFilters(this.props.filter, filter);
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("devices",{type: "analytics_v3", subType: "es_sessionDuration_v3", filter:mergedFilter, group: ['device_model'], uniqueUsers: true});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                this.setState({error: { title : "Couldn't retrieve Device Data", text : _err.code+": "+_err.message}, isLoading: false});
            } else {
                _data.devices.result.result.sort((_a,_b) => {
                    return parseInt(_b.unique_users,10) - parseInt(_a.unique_users, 10);
                })
                GraphCache.storeData(hash, _data.devices.result.result);
                return this.setState((_state, _props) => {
                    return {
                        isLoading: false,
                        deviceData : _data.devices.result.result
                    }
                });
            }
        });
    }

    render() {

        let devices = [];
        if (this.state.deviceData) {
            let sum = 0;
            for (let i = 0; i < this.state.deviceData.length; i++) {
                sum += parseInt(this.state.deviceData[i].unique_users, 10);
            }
            for (let i = 0; i < this.state.deviceData.length; i++) {
                const current = this.state.deviceData[i];
                const showDetails = this.state.toggles[current.device_model] === true;
                let details = null;
                if (showDetails) {
                    let filter = {
                        game_id : this.props.urlParams.gameId,
                        device_model : current.device_model
                    };
                    if (this.props.urlParams.platform === "ios") {
                        filter.platform = "IPhonePlayer";
                    }
                    else if (this.props.urlParams.platform === "android") {
                        filter.platform = "Android";
                    }
                    const mergedFilter = mergeFilters(this.props.filter, filter);
                    details = (
                        <Fragment key={"devdetail-"+i}>
                        <Row>
                            <Col lg={ 4} md={ 8 } className="mb-3">
                                <Installs_Line startDate={this.props.dateSelection.start} endDate={this.props.dateSelection.end} filter={mergedFilter} />
                            </Col>
                            <Col lg={ 4} md={ 8 } className="mb-3">
                                <DAU_Line startDate={this.props.dateSelection.start} endDate={this.props.dateSelection.end} filter={mergedFilter} />
                            </Col>
                            <Col lg={ 4} md={ 8 } className="mb-3">
                                <FPSDailyLine startDate={this.props.dateSelection.start} endDate={this.props.dateSelection.end} filter={mergedFilter} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={ 4} md={ 8 } className="mb-3">
                                <AdImpressions_Daily_Line startDate={this.props.dateSelection.start} endDate={this.props.dateSelection.end} filter={mergedFilter} />
                            </Col>
                            <Col lg={ 4} md={ 8 } className="mb-3">
                                <Revenue_Daily_Line onlyAds startDate={this.props.dateSelection.start} endDate={this.props.dateSelection.end} filter={mergedFilter} />
                            </Col>
                            <Col lg={ 4} md={ 8 } className="mb-3">
                                <Revenue_Daily_Line onlyIAP startDate={this.props.dateSelection.start} endDate={this.props.dateSelection.end} filter={mergedFilter} />
                            </Col>
                        </Row>
                        </Fragment>
                    )
                }
                let users = parseInt(current.unique_users, 10);
                let percentage = (users/sum)*100;
                percentage = Math.round(percentage*100)/100;
                devices.push(
                    <Fragment key={"dev-"+i}>
                        <Row>
                            <Col lg={ 1} md={ 2 } className="mb-3">
                                <span className={"alert-headings text-white"}>Details</span>
                            </Col>
                            <Col lg={ 1} md={ 2 } className="mb-3">
                                <Toggle id='locked' checked={showDetails} onChange={()=> {this.toggleDetailState(current.device_model)}} />
                            </Col>
                            <Col g={ 1} md={ 2 } className="mb-3">
                                <span className={"alert-headings text-white"}>{percentage+"% - "+users+" Users"}</span>
                            </Col>
                            <Col className="mb-3">
                                <span className={"alert-headings text-white"}>{current.device_model}</span>
                            </Col>
                        </Row>
                        {details}
                    </Fragment>
                );
            }
        }

        return (
            <Fragment>
                {this.loadingBanner()}
                {this.errorBanner()}
                <br/>
                {devices}
            </Fragment>
        )
    }
}

export default withURLParams(withFilter(DevicesPage, ["filter","time"],(_props)=> {return "Devices";}));