import CachedGraph from "./Graphs/CachedGraph";
import BarGraph from "./Graphs/BarGraph";

class Events_Pil_Bar {

    static getId() {
        return "Events_Pil_Bar";
    }

    static getGraphProps(_props) {
        return {
            title : "Events",
            xAxis : "retention",
            yAxis : "number"
        };
    }

}

export default CachedGraph(Events_Pil_Bar, BarGraph);