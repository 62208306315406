import AnalyticsContext from "../AnalyticsContext";
import PieGraph from "./Graphs/PieGraph";
import CachedGraph from "./Graphs/CachedGraph";

class Revenue_Pie {
    static getId() {
        return "Revenue_Pie";
    }

    static getGraphProps(_props) {
        let title = _props.title ? _props.title : "Revenue";
        if (_props.onlyAds) {
            title += " (Ads)";
        }
        if (_props.onlyIAP) {
            title += " (IAP);"
        }
        return {
            title : title,
            yAxis: "euro",
            small: true,
            legend: _props.legend
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        const all = (_props.onlyAds === undefined && _props.onlyIAP === undefined);
        if (all || _props.onlyAds) {
            ctx.sendAsync("ad", {type: "analytics_v3", subType: "es_premiumPurchase_v3", group: [_props.group], filter: mergedFilters });
        }
        if (all || _props.onlyIAP) {
            ctx.sendAsync("iap", {type: "analytics_v3", subType: "es_adImpression_v3", group: [_props.group], filter: mergedFilters });
        }
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let groups = {};
                if (_data.ad) {
                    for(let i = 0; i < _data.ad.result.result.length; i++) {
                        const entry = _data.ad.result.result[i];
                        let groupName = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                        if (!groups[groupName]) {
                            groups[groupName] = 0;
                        }
                        groups[groupName] += entry.revenue;
                    }
                }
                if (_data.iap) {
                    for(let i = 0; i < _data.iap.result.result.length; i++) {
                        const entry = _data.iap.result.result[i];
                        let groupName = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                        if (!groups[groupName]) {
                            groups[groupName] = 0;
                        }
                        groups[groupName] += entry.revenue;
                    }
                }
                let graphData = [{name: "default", data : []}];
                for(let groupName in groups) {
                    const entry = groups[groupName];
                    graphData[0].data.push({name : groupName, y : entry});
                }
                return _cb(null, graphData);
            }
        })
    }

}

export default CachedGraph(Revenue_Pie, PieGraph);