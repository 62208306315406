import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    NavItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from 'reactstrap';

import {ChangePasswordModal} from "../../meteor/components/ChangePasswordModal";

import MeteorContext from "../../meteor/meteorContext";

class NavbarUser extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            passwordModalIsOpen : false
        };

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onCloseChangePasswordModal = this.onCloseChangePasswordModal.bind(this);
    }

    onChangePassword() {
        this.setState({passwordModalIsOpen : true});
    }

    onCloseChangePasswordModal() {
        this.setState({passwordModalIsOpen: false});
    }

    render() {
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                    <i className="fa fa-power-off"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem tag={ Link } to="" onClick={this.onChangePassword}>Change Password</DropdownItem>
                    <ChangePasswordModal className="modal-outline-info" isOpen={this.state.passwordModalIsOpen} onClose={ this.onCloseChangePasswordModal} />
                    <DropdownItem tag={ Link } to="/pages/Login" onClick={() => {MeteorContext.logout();}}>Logout</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}


export { NavbarUser };
