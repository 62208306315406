import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import GraphUtil from "./Graphs/GraphUtil";

class DAU_Line {
    static getId() {
        return "DAU_Line";
    }

    static getGraphProps(_props) {
        return {
            title : "DAU",
            xAxis : "time",
            yAxis : "number",
            withSum : _props.withSum
        };
    }

    static getRequiredProps() {
        return ["startDate","endDate"];
    }

    static getGraphData(_props, _cb) {
        let ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        mergedFilters.day = { from : _props.startDate, to: _props.endDate };
        ctx.sendAsync("sessionDuration", {type:"analytics_v3", subType: "es_sessionDuration_v3", group: ["day",_props.group], filter: mergedFilters});
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let report = GraphUtil.generateTimeSeries(_data.sessionDuration.result.result, "day","users", _props.group);
                return _cb(null, report);
            }
        });
    }
}

export default CachedGraph(DAU_Line, LineGraph);