import React, {Fragment} from "react";
import withURLParams from "../common/SiteWithUrlParams";
import withFilter from "./SiteWithFilter";
import KeyGenerator from "../common/KeyGenerator";
import TextDropdown from "../routes/Dashboards/Overview/TextDropdown";
import {Button} from "reactstrap";
import AnalyticsContext from "./AnalyticsContext";
import {formatDate} from "../common/TimeUtil";
import createTaskStateUI from "./TaskStateUI";
import Table from "reactstrap/es/Table";

class MaxImpressionsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this._tasksUI = createTaskStateUI(this, "taskData");
        this.state = {
            updateCounter : 1
        };

        this.retriveImpressions = this.retriveImpressions.bind(this);
        this.drawTable = this.drawTable.bind(this);
    }

    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
        this._tasksUI.clearTasks();
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    drawTable() {
        let tableData = [];
        if (this.state.data && this.state.data.length > 0) {
            for(let i = 0; i < this.state.data.length; i++) {
                const current = this.state.data[i];
                const ecpm = Math.trunc(current.ecpm*1000)/1000;
                tableData.push(
                    <tr className="text-white" key={"imp_"+i}>
                        <td><a href={'/analytics/'+this.props.urlParams.gameId+'/max/'+this.props.urlParams.platform+'/impressions/'+current.country}>{current.country}</a> </td>
                        <td>{current.impressions}</td>
                        <td>${ecpm}</td>
                    </tr>
                );
            }
            return (
                <Table className="table">
                    <thead>
                        <tr>
                            <th>Country</th>
                            <th>Impressions</th>
                            <th>eCPM</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tableData}
                    </tbody>
                </Table>
            );
        }
        return null;
    }

    retriveImpressions() {
        this._tasksUI.startTask("Retrieving Impressions...");
        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);
        this._ctx.sendAuthenticated({type: "analytics_v3",subType: "maxAnalytics", gameId : this.props.urlParams.gameId, platform : this.props.urlParams.platform, start : startDate, end: endDate, columns : ["country","impressions","ecpm"], filters : {}}, (_err, _response) => {
            this._tasksUI.markTasksCompleted();
            if (_err) {
                this.setState((_prevState, _props) => {
                    return {error : _err};
                });
            } else {
                let data = _response.result;
                for(let i = 0; i < data.length; i++) {
                    data.impressions = parseInt(data.impressions, 10);
                    if (isNaN(data.impressions)) {
                        data.impressions = 0;
                    }
                }
                data.sort((_a,_b) => _b.impressions - _a.impressions);
                this.setState((_prevState, _props) => {
                    return {data : data};
                });
            }
        });
    }

    render() {
        const keyGen = new KeyGenerator("campaigns_"+this.state.updateCounter);
        let retrieveButton = null;
        if (!this._tasksUI.hasActiveTask()) {
            retrieveButton = (<Button color="primary" onClick={this.retriveImpressions}>Retrieve Impressions</Button>);
        }
        return (
            <Fragment>
                <br/>
                <br/>
                {this._tasksUI.drawTaskState()}
                {retrieveButton}
                <br/>
                <br/>
                <br/>
                {this.drawTable()}
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(MaxImpressionsPage,["time-1week"], (_props)=> {return "Max Impressions: "+_props.urlParams.gameId+" "+_props.urlParams.platform;}));