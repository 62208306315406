import * as React from "react";
import {
    tooltipFormat,
    XAxisFormatRetention,
    XAxisFormatTime,
    YAxisFormatEuro,
    YAxisFormatNumber,
    YAxisFormatPercent,
    YAxisFormatTime
} from "./GraphFormatting";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Theme from "./HighChartsTheme";
import "./loading.css";
import {addSum} from "../DataUtil";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

class LineGraph extends React.Component {
    static CF_requiredProps() {
        return ["title","xAxis","yAxis"];
    }

    static buildChartUpdate(_props, _newState) {
        if (_props.data) {
            if (_props.data.length > 1) {
                _newState.chartOptions.legend = {
                    enabled: true,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                };

            } else {
                _newState.chartOptions.legend = { enabled : false };
            }
            if (_props.lowPercentage) {
                let maxValue = 0;
                for(let i = 0; i < _props.data.length; i++) {
                    for(let j = 0; j < _props.data[i].data.length; j++) {
                        if (maxValue < _props.data[i].data[j].y) {
                            maxValue = _props.data[i].data[j].y;
                        }
                    }
                }
                let max = 150;
                if (maxValue < 100) {
                    max = 100;
                }
                if (maxValue < 50) {
                    max = 50;
                }
                if (maxValue < 25) {
                    max = 25;
                }
                if (maxValue < 10) {
                    max = 10;
                }
                if (maxValue < 5) {
                    max = 5;
                }
                if (_newState.chartOptions.yAxis) {
                    _newState.chartOptions.yAxis.max = max;
                }
            }
            let series = _props.data;
            if (_props.withSum) {
                series = addSum(_props.data);
            }
            _newState.chartOptions.series = series;
            _newState.isLoading = false;
        }
    };
    static getDerivedStateFromProps(_props, _state) {
        if (!_state.chartOptions) {
            _state.chartOptions = {};
        }
        let chartOptions = JSON.parse(JSON.stringify(_state.chartOptions));
        if (_props.data) {
            // new data
            if (!chartOptions || !chartOptions.series || chartOptions.series !== _props.data) {
                let newState = { isLoading: false, chartOptions : chartOptions };
                LineGraph.buildChartUpdate(_props, newState);
                return newState;
            }
        } else {
            // clear data

            if (chartOptions && chartOptions.series) {
                chartOptions.series = _props.data;
                return { chartOptions: chartOptions };
            }
        }
        return null;
    }

    constructor(_props) {
        super(_props);

        let isSmall = false;
        if (window.innerWidth !== undefined) {
            isSmall = window.innerWidth < 768;
        }


        let chartOptions = {
            title : {
                text : _props.title
            },
            chart : {
                height : _props.small ? "105%" : "50%"
            },
            tooltip : tooltipFormat(_props.xAxis, _props.yAxis, undefined, _props.sampleName),
            legend : {
                enabled : false
            },
            credits : {
                enabled : false
            }
        };

        if (isSmall) {
            chartOptions.chart.height = "100%";
        }

        if (_props.xAxis === "time") {
            chartOptions.xAxis = XAxisFormatTime();
        } else if (_props.xAxis === "retention") {
            chartOptions.xAxis = XAxisFormatRetention();
        }

        if(_props.yAxis === "time") {
            chartOptions.yAxis = YAxisFormatTime();
        } else if (_props.yAxis === "percent") {
            chartOptions.yAxis = YAxisFormatPercent();
        } else if (_props.yAxis === "number") {
            chartOptions.yAxis = YAxisFormatNumber();
        } else if (_props.yAxis === "euro") {
            chartOptions.yAxis = YAxisFormatEuro();
        }


        this.state = {
            isLoading : true,
            isSmall : isSmall,
            chartOptions : chartOptions
        };
        LineGraph.buildChartUpdate(_props, this.state);
        this._chartRef = React.createRef();

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        if (this._chartRef.current) {
            if (this.state.isLoading) {
                this._chartRef.current.chart.showLoading();
            }
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let isSmall = false;
        if (window.innerWidth !== undefined) {
            isSmall = window.innerWidth < 768;
        }
        if (this.state.isSmall !== isSmall) {
            this.setState((_prevState, _props) => {
                let chartOptions = JSON.parse(JSON.stringify(_prevState.chartOptions));
                if (chartOptions.chart && chartOptions.chart.height) {
                    if (isSmall) {
                        chartOptions.chart.height = "75%";
                    } else {
                        chartOptions.chart.height = _props.small ? "105%" : "50%";
                    }
                }
                return {
                    isSmall : isSmall,
                    chartOptions : chartOptions
                };
            });
        }
    }

    render() {
        if (this._chartRef.current) {
            if (this.state.isLoading) {
                this._chartRef.current.chart.showLoading();
            } else {
                this._chartRef.current.chart.hideLoading();
            }
        }
        Highcharts.theme = Theme;
        Highcharts.setOptions(Highcharts.theme);

        return (<HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} ref={this._chartRef}/>);
    }
}

export default LineGraph;