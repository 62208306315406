import AnalyticsContext from "../AnalyticsContext";
import {mergeFilters} from "../Filters/FilterLogic";
import CachedGraph from "./Graphs/CachedGraph";
import PieGraph from "./Graphs/PieGraph";

class RevenueSplit_Pie {
    static getId() {
        return "RevenueSplit_Pie";
    }

    static getGraphProps(_props) {
        return {
            title: "Revenue",
            yAxis: "Euro",
            small: true,
            legend: true
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if(_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("ads", {type: "analytics_v3", subType: "es_adImpression_v3", group: _props.group, filter: mergedFilters});
        ctx.sendAsync("iap", {type: "analytics_v3", subType: "es_premiumPurchase_v3", group: _props.group, filter: mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let graphData = [{data: [
                        {name: "Ads", y: _data.ads.result.result[0].revenue},
                        {name: "IAP", y: _data.iap.result.result[0].revenue}
                    ]}];
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(RevenueSplit_Pie, PieGraph);