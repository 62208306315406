import AnalyticsContext from "../AnalyticsContext";

import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import GraphUtil from "./Graphs/GraphUtil";

class FPS_Daily_Line {
    constructor() {
    }

    static getId() {
        return "FPS_Daily_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "FPS",
            xAxis: "time",
            yAxis: "number"
        };
    }

    static getRequiredProps() {
        return ["startDate","endDate","filter"];
    }

    static getGraphData(_props, _cb) {
        let ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        mergedFilters.day = { from : _props.startDate, to: _props.endDate};
        ctx.sendAsync("fps", {type:"analytics_v3", subType: "es_fps_v3", group: ["day",_props.group], filter: mergedFilters});
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let report = GraphUtil.generateTimeSeries(_data.fps.result.result, "day","fps", _props.group);
                return _cb(null, report);
            }
        });
    }

}

export default CachedGraph(FPS_Daily_Line, LineGraph);