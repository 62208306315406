import AnalyticsContext from "../AnalyticsContext";
import BarGraph from "./Graphs/BarGraph";
import CachedGraph from "./Graphs/CachedGraph";

class Rating_Bar {
    static getId() {
        return "Rating_Bar";
    }

    static getGraphProps(_props) {
        return {
            title: "Rating",
            xAxis: "dataCategory",
            yAxis: "number",
            small: true
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if(_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("rating", {type: "analytics_v3", subType : "es_rating_v3", group: ["rating",_props.group], filter: mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let groups = {};
                for (let i = 0; i < _data.rating.result.result.length; i++) {
                    const entry = _data.rating.result.result[i];
                    let groupName = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                    if(!groups[groupName]) {
                        groups[groupName] = { sum : 0, users : 0};
                    }
                    groups[groupName].sum += entry.rating * parseInt(entry.samples, 10);
                    groups[groupName].users += parseInt(entry.samples, 10);
                }

                let graphData = [{name: "default", data : []}];
                for (let groupName in groups) {
                    const entry = groups[groupName];
                    graphData[0].data.push({name: groupName, y: entry.sum / entry.users, sampleCount : entry.users});
                }
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(Rating_Bar, BarGraph);