import { w3cwebsocket as W3CWebSocket } from "websocket";
import IdGenerator from "./IdGenerator";
import CallbackStore from "./CallbackStore";
import ChunkAggregator from "./ChunkAggregator";
import {getUnixTime} from "./TimeUtil";
import * as Config from "../config";

const uuidv4 = require("uuid/v4");

const MAX_REQUESTS = 10;


class WSConnection {
    constructor(_url) {
        this._url = _url;
        this._idGenerator = new IdGenerator();
        this._callbacks = new CallbackStore();
        this._chunks = new ChunkAggregator();
        this._messageQueue = [];
        this._ws = null;
        this._isConnecting = false;
        this._timerHandle = null;
        this._lastActivity = getUnixTime();
        this._inFlightData = [];
    }

    send(_data, _cb) {
        const uuid = uuidv4();
        const result = this._trySend(uuid, _data, _cb);
        if (!result) {
            this._messageQueue.push({data: _data, callback: _cb, uuid : uuid});
        }
        return uuid;
    }

    cancelRequest(_uuid) {
        // check queued requests
        let idx = -1;
        for(let i = 0; i < this._messageQueue.length; i++) {
            if (this._messageQueue[i].uuid === _uuid) {
                idx = i;
                break;
            }
        }
        if (idx >= 0) {
            this._messageQueue.splice(idx,1);

        }
        // check active request
        let flightIdx = -1;
        for(let i = 0; i < this._inFlightData.length; i++) {
            if (this._inFlightData[i].uuid === _uuid) {
                flightIdx = i;
                break;
            }
        }
        if (flightIdx >= 0) {
            const inFlightData = this._inFlightData[flightIdx];
            this._callbacks.getCallback(inFlightData.datagramId);
            this._inFlightData.splice(flightIdx,1);
        }
        Config.logWarn("WSS","Cancelling request: "+_uuid);
    }

    _reconnect() {
        if(this._isConnecting === false) {
            this._isConnecting = true;
            Config.logInfo("WSS","Connecting to "+this._url);
            const ws = new W3CWebSocket(this._url);
            ws.onmessage = this._onMessage.bind(this);
            ws.onerror = this._onError.bind(this);
            ws.onclose = this._onClose.bind(this);
            ws.onopen = this._onOpen.bind(this);
            this._ws = ws;
        }
    }

    _trySend(_uuid, _data, _cb) {
        if (this._ws == null && this._isConnecting === false) {
            this._reconnect();
            return false;
        } else if (this._ws != null && this._isConnecting === false && this._callbacks.getCount() <= MAX_REQUESTS) {
            this._lastActivity = getUnixTime();
            const datagramId = this._idGenerator.nextId();
            _data.datagramId = datagramId;
            this._callbacks.storeCallback(datagramId, _cb);
            this._inFlightData.push({uuid : _uuid, datagramId : _data.datagramId, ts: Date.now()});
            Config.logDebug("WSS","Sending datagram "+datagramId+" with: ",_data);
            this._ws.send(JSON.stringify(_data));
            return true;
        }
        return false;
    }

    _trySendNext() {
        if (this._messageQueue.length > 0) {
            let first = this._messageQueue[0];
            const result = this._trySend(first.uuid, first.data, first.callback);
            if (result) {
                this._messageQueue.splice(0,1);
            }
        }
    }

    _checkConnection() {
        const now = getUnixTime();
        const diffToLastActivity = now - this._lastActivity;
        const callbackCount = this._callbacks.getCount();
        if (callbackCount > 0 && diffToLastActivity > (6*60)) {
            this.close();
        }
        if (callbackCount === 0 && diffToLastActivity > 1*60) {
            this.close();
        }
        let timedOut = this._callbacks.getTimedOut(now - 60);
        for (let i = 0; i < timedOut.length; i++) {
            Config.logWarn("WSS","No Response for datagramId: "+timedOut[i].key+". Sending timeout response.");
            timedOut[i].callback({type:"error", code:-1, errorText: "timeout for request."});
        }
    }

    close() {
        if(this._ws != null) {
            this._ws.close();
            this._ws = null;
        }
        if(this._timerHandle !== null) {
            clearInterval(this._timerHandle);
            this._timerHandle = null;
        }
    }

    _onOpen() {
        this._lastActivity = getUnixTime();
        this._isConnecting = false;
        if (this._timerHandle !== null) {
            clearInterval(this._timerHandle);
        }
        this._timerHandle = setInterval(this._checkConnection.bind(this),5000);
        this._trySendNext();
    }

    _onMessage(_msg) {
        if (Config.isDebug) {
            //console.log("Receiving: " + (new Date()));
            //console.log(_msg.data);
        }
        this._lastActivity = getUnixTime();
        const data = _msg.data;
        let json = null;
        try {
            json = JSON.parse(data);
        } catch(ex) {
            Config.logErr("WSS","Received unparsable JSON: ",data);
        }
        if (json !== null) {
            if (json.type === "chunkedData") {
                this._chunks.addChunk(json.id, json.idx, json.chunk);
                if (this._chunks.getChunkCount(json.id) === json.length) {
                    let data = this._chunks.getChunkData(json.id);
                    json = null;
                    try {
                        json = JSON.parse(data);
                    } catch(ex) {}
                    if(json === null) {
                        Config.logErr("WSS", "Received unparsable chunk:", data);
                    }
                }
            }
            if (json && typeof json.datagramId === "number") {
                if (json.type && json.type === "keepalive") {
                    this._callbacks.extendTimeout(json.datagramId);
                } else {
                    let inflight = null;
                    let inflightIdx = -1;
                    for(let i = 0; i < this._inFlightData.length; i++) {
                        if (this._inFlightData[i].datagramId === json.datagramId) {
                            inflight = this._inFlightData[i];
                            inflightIdx = i;
                            break;
                        }
                    }
                    let duration = Math.round((Date.now() - inflight.ts)/1000);
                    Config.logDebug("WSS","Received datagramId: "+json.datagramId+" "+duration+" sec.");
                    const cb = this._callbacks.getCallback(json.datagramId);
                    if (cb) {
                        cb(json);
                    } else {
                        Config.logWarn("WSS", "Received message without callback: ", data)
                    }
                }
            } else if (json && json.type !== "chunkedData") {
                Config.logErr("WSS","Received json without datagramId: ",json);
            }

        }
        this._trySendNext();
    }

    _onError(_err) {
        Config.logErr("WSS","Error on Websocket ("+this._url+"):", _err);
        this._ws = null;
        this._reconnect();
    }

    _onClose() {
        Config.logInfo("WSS","Connection closed. ("+this._url+")");
        this._ws = null;
    }

}

export default WSConnection;