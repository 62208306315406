import React, {Fragment} from "react";

import AnalyticsContext from "./AnalyticsContext";
import {Col, Row} from "reactstrap";

import Installs_Line from "./Components/Installs_Line";
import {formatDate} from "../common/TimeUtil";
import Installs_PieChart from "./Components/Installs_Pie";
import UserRetention from "./Components/Retention_Line";
import DAU_LineChart from "./Components/DAU_LineChart";
import SessionDurationPil from "./Components/SessionDuration_Pil_Line";
import SessionCountPil from "./Components/SessionCount_Pil_Line";
import withFilter from "./SiteWithFilter";
import {mergeFilters} from "./Filters/FilterLogic";
import withURLParams from "../common/SiteWithUrlParams";

class UsersPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            updateCounter : 0
        };

    }

    render() {
        let gameId = this.props.urlParams.gameId;
        let comp = 0;
        let getKey = () => {
            const id = comp;
            comp +=1;
            return "comp_"+id+"_"+this.state.updateCounter;
        };

        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);



        const filter = mergeFilters(this.props.filter, {game_id : gameId});

        return (
            <Fragment>
                <Row>
                    <Col lg={ 6} md={12} className="mt-3">
                        <Installs_Line key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum/>
                    </Col>
                    <Col lg={ 3} md={6} className="mt-3">
                        <Installs_PieChart key={getKey()} title="Installs organic" startDate={startDate} endDate={endDate} group="country" filter={mergeFilters(filter, {ua_type:"organic"})}/>
                    </Col>
                    <Col lg={ 3} md={6} className="mt-3">
                        <Installs_PieChart key={getKey()} title="Installs UA" startDate={startDate} endDate={endDate} group="country" filter={mergeFilters(filter, {ua_type:"non-organic"})}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <DAU_LineChart key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} withSum/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <UserRetention key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <SessionDurationPil key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} average />
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <SessionCountPil key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter} average />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(UsersPage, ["filter","time","group"],(_props)=> {return "User: "+AnalyticsContext.getGameName(_props.urlParams.gameId);}));