import AnalyticsContext from "../AnalyticsContext";

import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import StatisticUtil from "../StatisticUtil";
import GraphUtil from "./Graphs/GraphUtil";


class AdImpressions_Cohort_Line {

    static getId() {
        return "AdImpressions_Cohort_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "Ad Impressions (Cohort)",
            xAxis: "time",
            yAxis: "number",
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("impressions", {type: "analytics_v3", subType: "es_adImpression_v3", group: ["install_date","day",_props.group], filter: mergedFilters });
        ctx.sendAsync("installs", {type: "analytics_v3", subType: "es_user_v3", group: ["install_date",_props.group], filter: mergedFilters });
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                const installLookup = AnalyticsUtil.generateLookup(_data.installs.result.result, "install_date","installs", (_a) => parseInt(_a, 10));
                const pilImpressions = AnalyticsUtil.addPil(_data.impressions.result.result);
                let graphData = null;
                let pils = [0,3,7];
                for (let pilidx = 0; pilidx < pils.length; pilidx++) {
                    const pil = pils[pilidx];
                    const d0Impressions = AnalyticsUtil.filterBy(pilImpressions, (_e) => _e.pil <= pil && _e.maxPil >= pil);
                    const d0Data = AnalyticsUtil.aggregateBy(d0Impressions, "install_date", "impressions", StatisticUtil.sum);
                    AnalyticsUtil.scaleBy(d0Data, "install_date", "impressions", (_a) => (1/installLookup[_a]));
                    for (let i = 0; i < d0Data.length; i++) {
                        d0Data[i].samples = installLookup[d0Data[i].install_date];
                    }
                    const graph = GraphUtil.generateNamedTimeseries("D"+pil,d0Data, "install_date", "impressions");
                    graphData = GraphUtil.mergeReports(graphData, graph);
                }
                return _cb(null, graphData);
            }
        });
    }
}


export default CachedGraph(AdImpressions_Cohort_Line, LineGraph);