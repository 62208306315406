import React from 'react';
import {Alert, Button, CardText, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import _ from 'lodash';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock, faCodeBranch, faHashtag, faSync, faUser} from "@fortawesome/free-solid-svg-icons";

import FirebuildContext from "./FirebuildContext";
import {faGitlab} from "@fortawesome/free-brands-svg-icons";

class EditBuildModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLocked : false,
            error : null
        };

        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onCommentChanged = this.onCommentChanged.bind(this);
    }

    static getDerivedStateFromProps(_props, _state) {
        if (_props.isOpen && _props.build && _props.build.buildId !== _state.selectedBuildId) {
            return {
                selectedBuildId : _props.build.buildId,
                comment : _props.build.comment
            }
        }
        if (!_props.isOpen && _state.selectedBuildId !== "invalid") {
            return {selectedBuildId: "invalid"};
        }
        return null;
    }

    onCancel(event) {
        this.closeModal(false);
    }

    onUpdate(event) {
        this.setState({isLocked: true});
        return FirebuildContext.sendAuthenticated({type: "builds",subType: "update", buildId: this.props.build.buildId, comment: this.state.comment}, (_err) => {
            if(_err) {
                this.setState({
                    isLocked : false,
                    error : {
                        title : _err.code+": "+_err.message,
                        text : ""
                    }
                });
            } else {
                this.closeModal(true)
            }

        });
    }

    onCommentChanged(event) {
        let newComment = event.target.value;
        this.setState(() => {
            return {comment : newComment};
        });
    }

    closeModal(_val) {
        this.setState(() => {return {isLocked: false, error: null, selectedBuildId: "invalid", comment: null}});
        if (this.props.onClose && typeof this.props.onClose === 'function') {
            this.props.onClose(_val);
        }
    }

    render() {
        const modalProps = _.omit(this.props, ['target']);
        if(!this.props.build) {
            return null;
        }

        return (
            <Modal { ...modalProps } isOpen={ this.props.isOpen } toggle={ this.onCancel } >
                <ModalHeader tag="h6">
                    <span className="text-info">
                        Update Build Comment
                    </span>
                </ModalHeader>
                <ModalBody>
                    <span className="text-white ml-1">
                        <Input type="text" name="comment" id="comment" value={this.state.comment} onChange={this.onCommentChanged} disabled={this.state.isLocked} />
                    </span>
                    {
                        (() => {
                            if (this.state.error) {
                                return (
                                    <Alert color="danger">
                                        <i className="fa fa-times-circle mr-1 alert-icon"></i>
                                        <span>
                                            <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                                        </span>
                                    </Alert>
                                );
                            }
                        })()
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" tag = { Link } disabled={this.state.isLocked} onClick={this.onCancel}>
                        Cancel
                    </Button>
                    <Button color="info" tag={ Link }  disabled={this.state.isLocked} onClick={this.onUpdate}>
                        {(() => {
                            if (this.state.isLocked) {
                                return (<FontAwesomeIcon icon={faSync} spin pull="left"/>);
                            }
                        })()}
                        { this.state.isLocked ? 'Updating ...' : "Update" }
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default EditBuildModal;