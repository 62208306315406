import * as React from "react";
import {
    tooltipPieFormat,
} from "./GraphFormatting";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Theme from "./HighChartsTheme";
import "./loading.css";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

class PieGraph extends React.Component {
    static CF_requiredProps() {
        return ["title","yAxis"];
    }

    static buildChartUpdate(_props, _newState) {
        if (_props.data) {
            _newState.chartOptions.legend = { enabled : _props.legend ? true : false };
            _newState.chartOptions.series = _props.data;
            _newState.isLoading = false;
        }
    };
    static getDerivedStateFromProps(_props, _state) {
        if (_props.data) {
            // new data
            if (!_state.chartOptions || !_state.chartOptions.series || _state.chartOptions.series !== _props.data) {
                let newState = { isLoading: false, chartOptions : {} };
                PieGraph.buildChartUpdate(_props, newState);
                return newState;
            }
        } else {
            // clear data
            if (_state.chartOptions && _state.chartOptions.series) {
                return { chartOptions: {series : _props.data} };
            }
        }
        return null;
    }

    constructor(_props) {
        super(_props);

        let chartOptions = {
            title : {
                text : _props.title
            },
            chart : {
                type : "pie",
                height : _props.small ? "105%" : "50%"
            },
            plotOptions : {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend : _props.legend ? true : false,
                    dataLabels: {
                        enabled: _props.legend ? false : true,
                        format: '{point.name}',
                        distance: 1
                    },
                }
            },
            tooltip : tooltipPieFormat(_props.yAxis),
            legend : {
                enabled : _props.legend ? true : false,
            },
            credits : {
                enabled : false
            }
        };

        this.state = {
            isLoading : true,
            chartOptions : chartOptions
        };
        PieGraph.buildChartUpdate(_props, this.state);
        this._chartRef = React.createRef();
    }

    componentDidMount() {
        if (this._chartRef.current) {
            if (this.state.isLoading) {
                this._chartRef.current.chart.showLoading();
            }
        }
    }

    render() {
        if (this._chartRef.current) {
            if (this.state.isLoading) {
                this._chartRef.current.chart.showLoading();
            } else {
                this._chartRef.current.chart.hideLoading();
            }
        }
        Highcharts.theme = Theme;
        Highcharts.setOptions(Highcharts.theme);

        return (<HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} ref={this._chartRef}/>);
    }
}

export default PieGraph;