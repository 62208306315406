import React, {Fragment} from "react";
import withURLParams from "../common/SiteWithUrlParams";
import KeyGenerator from "../common/KeyGenerator";
import TextDropdown from "../routes/Dashboards/Overview/TextDropdown";
import {Alert, Button, Col, Container, Row} from "reactstrap";
import FirebuildContext from "./FirebuildContext";
import {formatDate} from "../common/TimeUtil";

import Table from "reactstrap/es/Table";
import withFilter from "../analytics/SiteWithFilter";
import {HeaderMain} from "../routes/components/HeaderMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import FirebuildBuildEntry from "./FirebuildBuildEntry";
import DeleteBuildModal from "./DeleteBuildModal";
import EditBuildModal from "./EditBuildModal";
import {faApple} from "@fortawesome/free-brands-svg-icons";



class FirebuildBuildsPage extends React.Component {
    constructor(_props) {
        super(_props);


        this.state = {
            isLoading: true,
            updateCounter : 1
        };

        this.onDeleteBuild = this.onDeleteBuild.bind(this);
        this.onEditBuild = this.onEditBuild.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.loadingBanner = this.loadingBanner.bind(this);
        this.errorBanner = this.errorBanner.bind(this);
        this.renderBuilds = this.renderBuilds.bind(this);
        this.loadBuilds = this.loadBuilds.bind(this);
    }

    componentDidMount() {
        this.loadBuilds();
    }

    componentWillUnmount() {

    }

    loadBuilds() {
        this.setState((_prevState, _opts) => {
            return {isLoading: true};
        });
        FirebuildContext.sendAuthenticated({type : "builds", subType: "list", projectId : this.props.urlParams.projectId, buildType: this.props.urlParams.buildType}, (_err, _response) => {
            if (_err) {
                this.setState((_prevState, _props) => {
                    return {
                        error: {title: "Couldn't retrieve builds", text: _err.code + ": " + _err.message},
                        isLoading: false
                    };
                });
            } else {
                this.setState((_prevState, _opts) => {
                    _response.builds.sort((_a,_b) => {return _b.timestamp - _a.timestamp;});
                    return {isLoading: false, builds: _response.builds};
                });
            }
        });
    }

    onDeleteBuild(_buildId) {
        this.setState((_prevState, _props) => {
            return {
                selectedBuildId : _buildId,
                modalType : "delete"
            }
        });
    }

    onEditBuild(_buildId) {
        this.setState((_prevState, _props) => {
            return {
                selectedBuildId : _buildId,
                modalType : "edit"
            }
        });
    }

    onModalClose(_shouldUpdate) {
        this.setState((_prevState, _props) => {
            return {
                selectedBuildId : "invalid",
                modalType : "invalid"
            }
        });
        if (_shouldUpdate) {
            this.setState((_prevState, _props) => {
                return {
                    builds: null
                };
            });
            this.loadBuilds();
        }
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (
                <Alert color="info">
                    <FontAwesomeIcon icon={faSync} spin/>
                    <span>
                        <strong className="alert-heading"> Loading Builds</strong>&nbsp;Please wait...
                    </span>
                </Alert>
            );
        }
    }

    errorBanner() {
        if (this.state.error) {
            return (
                <Alert color="danger">
                    <i className="fa fa-times-circle mr-1 alert-icon" />
                    <span>
                        <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                    </span>
                </Alert>
            );
        }
    }

    renderBuilds() {
        if (this.state.builds) {
            let list = [];
            for(let i = 0; i < this.state.builds.length; i++) {
                list.push(<FirebuildBuildEntry key={"build_" + i} project={FirebuildContext.getProjectConfig(this.state.builds[i].projectId)} build={this.state.builds[i]}
                    canEdit={this.state.builds[i].canEdit} canDelete={this.state.builds[i].canDelete}
                    onDelete={this.onDeleteBuild} onEdit={this.onEditBuild}
                    withLink
                />);
            }
            return (<Fragment>
                {list}
            </Fragment>);
        }
        return null;
    }

    render() {
        let projectId = this.props.urlParams.projectId;
        let projectConfig = FirebuildContext.getProjectConfig(projectId);
        let name = projectId;
        if (projectConfig && projectConfig.displayName) {
            name = projectConfig.displayName;
        }
        let buildType = this.props.urlParams.buildType;
        let selectedBuild;
        if (this.state.selectedBuildId && this.state.builds) {
            for(let i = 0; i < this.state.builds.length; i++) {
                if(this.state.builds[i].buildId === this.state.selectedBuildId) {
                    selectedBuild = this.state.builds[i];
                }
            }
        }
        return (
            <Container>
                <HeaderMain className="mb-5 mt-4" title={name+" ("+buildType+")"} />
                <DeleteBuildModal isOpen={this.state.modalType === "delete"} build={selectedBuild} onClose={this.onModalClose}/>
                <EditBuildModal isOpen={this.state.modalType === "edit"} build={selectedBuild} onClose={this.onModalClose}/>
                {this.loadingBanner()}
                {this.errorBanner()}
                {this.renderBuilds()}
            </Container>
        );
    }
}

export default withURLParams(FirebuildBuildsPage);