import {Alert} from "reactstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";

function LoadingBannerUI(_props) {
    return (
        <Alert color="info">
            <FontAwesomeIcon icon={faSync} spin/>
            <span>
                <strong className="alert-heading"> {_props.text}</strong>Please wait...
            </span>
        </Alert>
    );
}

export default LoadingBannerUI;