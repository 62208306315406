import React from 'react';

import { SidebarMenu } from './../../components';
import Hierarchy from "../../common/Hierarchy";
import EventBus from "../../common/EventBus";


function generateNavItem(_prefix, _node) {
    const childs = _node.getChilds();
    let renderedChilds = [];
    const path = _node.getLink();
    for(let i = 0; i < childs.length; i++) {
        renderedChilds.push(generateNavItem(path, childs[i]));
    }
    if(_node.getLink()) {
        if (_node.getChilds().length === 0) {
            return (<SidebarMenu.Item key={_node.getName()} title={_node.getName()} icon={_node.getIcon()} to={path} exact />);
        } else {
            return (<SidebarMenu.Item key={_node.getName()} title={_node.getName()} icon={_node.getIcon()} to={path} exact>
                    {renderedChilds}
                </SidebarMenu.Item>
            );
        }
    } else {
        if (_node.getChilds().length === 0) {
            return (<SidebarMenu.Item key={_node.getName()} title={_node.getName()} icon={_node.getIcon()} />);
        } else {
            return (<SidebarMenu.Item key={_node.getName()} title={_node.getName()} icon={_node.getIcon()}>
                    {renderedChilds}
                </SidebarMenu.Item>
            );
        }
    }
}

class SidebarMiddleNav extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = { counter : 1};

        this.onNavigationChanged = this.onNavigationChanged.bind(this);

    }

    onNavigationChanged(event) {
        const oldVal = this.state.counter;
        this.setState({ counter: oldVal +1});
    }

    componentDidMount() {
        EventBus.registerHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }

    componentWillUnmount() {
        EventBus.unregisterHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }

    render() {
        const childs = Hierarchy.getChilds();
        let list = [];
        for( let i = 0; i < childs.length; i++) {
            list.push(generateNavItem("",childs[i]));
        }
        return (
            <SidebarMenu>
                {list}
            </SidebarMenu>
        );
    }
}

export { SidebarMiddleNav };