import React from 'react';
import * as Config from "../../config";

const FooterText = (props) => (
	<React.Fragment>
		<span>Meteor {Config.version+(Config.isDebug ? " (devel)" : "")}</span><br/>
		<span>&copy; 2019 ColdFire Games</span>
	</React.Fragment>
);

export { FooterText };
