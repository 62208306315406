import React from "react";
import {
    Container
} from "reactstrap";
import {HeaderMain} from "../components/HeaderMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMeteor} from "@fortawesome/free-solid-svg-icons";
import * as Config from "../../config";

class DefaultPage extends React.Component {
    constructor(_props) {
        super(_props);
    }


    render() {
        return(
            <Container>
                <FontAwesomeIcon icon={faMeteor} size="6x"/><span>{Config.version +(Config.isDebug ? " (devel)": "")}</span>
            </Container>
        );
    }
}

export default DefaultPage;