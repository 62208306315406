import React, {Fragment} from "react";
import AnalyticsContext from "./AnalyticsContext";
import AB_Users from "./Components/AB_Users";
import AB_Ads from "./Components/AB_Ads";
import withURLParams from "../common/SiteWithUrlParams";
import AB_Ratings from "./Components/AB_Ratings";
import withFilter from "./SiteWithFilter";
import AB_Revenue from "./Components/AB_Revenue";
import LoadingBannerUI from "../common/LoadingBannerUI";
class ABTestPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : false
        };

        this.loadingBanner = this.loadingBanner.bind(this);
        this.renderABTests = this.renderABTests.bind(this);
        this.renderGraphs = this.renderGraphs.bind(this);
        this.renderABTests = this.renderABTests.bind(this);
    }


    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
        this.setState({isLoading : true});
        this._ctx.getGameSettings(this.props.urlParams.gameId, (_err, _games) => {
            this.setState((_prevState, _props)=>{return {isLoading: false, abTests : _games.abTests};});
            if (this.props.extraFilters && typeof this.props.extraFilters === "function") {
                let abTests = [];
                for(let i = 0; i < _games.abTests.length; i++) {
                    const abTest = _games.abTests[i];
                    abTests.push(abTest.name);
                }
                this.props.extraFilters({abTest : abTests});
            }
        });
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (<LoadingBannerUI text={"Loading User Data"} />);
        }
        return null;
    }

    renderGraphs() {
        let params = null;
        if (this.props.abTest && this.state.abTests) {
            for(let i = 0; i< this.state.abTests.length; i++) {
                if (this.state.abTests[i].name === this.props.abTest) {
                    params = this.state.abTests[i];
                    break;
                }
            }
        }
        if (params === null) {
            return null;
        } else {

            let filter = {};
            if (this.props.filter) {
                filter = JSON.parse(JSON.stringify(this.props.filter));
            }
            filter.game_id = this.props.urlParams.gameId;
            filter.ab_group = params.abFilter;
            return (
                <Fragment>
                    <AB_Users filter={filter}/>
                    <AB_Revenue filter={filter}/>
                    <AB_Ads filter={filter}/>
                    <AB_Ratings filter={filter}/>
                </Fragment>
            )
        }
    }

    renderABTests() {

        if(!this.state.isLoading) {
            return (
                <Fragment>
                    {this.renderGraphs()}
                </Fragment>
            )
        }
        else return null;
    }

    render() {
        return (
            <Fragment>
                {this.loadingBanner()}
                {this.renderABTests()}
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(ABTestPage,["filter"], (_props)=> {return "AB-Tests "+AnalyticsContext.getGameName(_props.urlParams.gameId);}));