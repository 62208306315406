
function removeNullArray(_list) {
    let result = [];
    for (let i = 0; i < _list.length; i++) {
        if (_list[i] !== undefined && _list[i] !== null) {
            result.push(_list[i]);
        }
    }
    return result;
}

function generateHashLookup(_list, _keys) {
    let wrapper = {};

    let lookup = {};
    for (let i = 0; i < _list.length; i++) {
        const entry = _list[i];
        let key = "";
        for(let j = 0; j < _keys.length; j++) {
            key += "#"+entry[_keys[j]];
        }
        lookup[key] = entry;
    }

    wrapper.get = (_keys) => {
        let key = "";
        for(let i = 0; i < _keys.length; i++) {
            key += "#"+_keys[i];
        }
        return lookup[key];
    }
    return wrapper;
}

class AnalyticsUtil {
    static join(_list1, _list2) {
        let result = [];
        for (let i = 0; i < _list1.length; i++) {
            result.push(_list1[i]);
        }
        for (let i = 0; i < _list2.length; i++) {
            result.push(_list2[i]);
        }
        return result;
    }

    static generateLookup(_list, _keyName, _valueName, _fn) {
        let lookup = {};
        for(let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            if (entry[_keyName] !== undefined && entry[_valueName] !== undefined) {
                if (typeof _fn === "function") {
                    lookup[entry[_keyName]] = _fn(entry[_valueName]);
                } else {
                    lookup[entry[_keyName]] = entry[_valueName];
                }
            }
        }
        return lookup;
    }

    static getDistinct(_list, _propertyName) {
        let result = {};
        for(let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            const val = entry[_propertyName];
            if (val !== undefined && val !== null) {
                result[val] = typeof val;
            }
        }
        let list = [];
        for(let key in result) {
            if (result[key] === "number") {
                list.push(parseFloat(key));
            } else {
                list.push(key);
            }
        }
        list.sort();
        return list;
    }

    static filterBy(_list, _fn) {
        let result = [];
        for(let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            if (_fn(entry)) {
                result.push(entry);
            }
        }
        return result;
    }

    static scaleBy(_list, _property, _value, _fn) {
        for(let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            entry[_value] *= _fn(entry[_property]);
        }
    }

    static aggregateAll(_list, _valueName, _fn) {
        let result = [];
        for(let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            let val = entry[_valueName];
            if (typeof val === "string") {
                val = parseFloat(val);
            }
            result.push(val);
        }
        return _fn(result);
    }

    static aggregateBy(_list, _groupingNames, _valueName, _fn) {
        if (!Array.isArray(_groupingNames)) {
            _groupingNames = [_groupingNames];
        }
        _groupingNames = removeNullArray(_groupingNames);
        let lookup = {};
        for(let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            let key = "";
            for(let j = 0; j < _groupingNames.length; j++) {
                key += "#"+entry[_groupingNames[j]];
            }
            if (!lookup[key]) {
                lookup[key] = JSON.parse(JSON.stringify(entry));
                lookup[key]._cf_aggreagte_list = [];
            }
            let val = entry[_valueName];
            if (typeof val === "string") {
                val = parseFloat(val);
            }
            lookup[key]._cf_aggreagte_list.push(val);
        }
        let result = [];
        for(let key in lookup) {
            const entry = lookup[key];
            let agglist = entry._cf_aggreagte_list;
            delete entry._cf_aggreagte_list;
            entry[_valueName] = _fn(agglist);
            result.push(entry);
        }
        return result;
    }

    static mergeCalcNewSeries(_list1, _prop1, _list2, _prop2, _fn) {
        const properties = {};
        for(let i = 0; i < _list1.length; i++) {
            if (_list1[i][_prop1]) {
                properties[_list1[i][_prop1]] = true;
            }
        }
        for(let i = 0; i < _list2.length; i++) {
            if (_list2[i][_prop2]) {
                properties[_list2[i][_prop2]] = true;
            }
        }
        const result = [];
        let findIn = (_list, _prop, _val) => {
            for(let i = 0; i < _list.length; i++) {
                if (_list[i][_prop] === _val) {
                    return _list[i];
                }
            }
        }
        for (let prop in properties) {
            let entry = _fn(findIn(_list1,_prop1,prop), findIn(_list2,_prop2,prop));
            if (entry) {
                result.push(entry);
            }
        }
        return result;
    }

    static addPil(_list) {
        const now = new Date(Date.now());
        const today = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getDate());
        let result = [];
        for(let i = 0; i < _list.length; i++) {
            const entry = _list[i];
            if (entry.install_date && entry.day) {
                let installD = Date.parse(entry.install_date);
                let date = Date.parse(entry.day);
                if (date < today) { // kill impossible data
                    let pil = Math.round((date - installD) / (24 * 60 * 60 * 1000));
                    let maxPil = Math.floor((today - installD) / (24 * 60 * 60 * 1000));
                    if (pil >= 0) {
                        entry.pil = pil;
                        entry.maxPil = maxPil-1;
                        if (entry.pil <= entry.maxPil) {
                            result.push(entry);
                        }
                    }
                }
            }
        }
        return result;
    }

    static generatePilStats(_data, _installs, _groupProperty, _valueProperty, _maxPil, _withSum) {
        const installsLookup = generateHashLookup(_installs, ["install_date",_groupProperty]);
        const pilData = AnalyticsUtil.addPil(_data);
        let retentionData = {};
        for(let i = 0; i < pilData.length; i++) {
            const entry = pilData[i];
            let group = (_groupProperty && entry[_groupProperty]) ? entry[_groupProperty] : "undefined";
            if (!retentionData[group]) {
                retentionData[group] = {};
            }
            if(!retentionData[group][entry.install_date]) {
                let installsNumber = 0;
                const lookup = installsLookup.get([entry.install_date, group]);
                if (lookup && lookup.installs) {
                    installsNumber = lookup.installs;
                }
                if (typeof installsNumber === "string") {
                    installsNumber = parseFloat(installsNumber);
                }
                retentionData[group][entry.install_date] = {
                    installs : installsNumber,
                    maxPil : entry.maxPil,
                    pil: []
                };
            }
            let value = 0;
            if (entry[_valueProperty]) {
                if (typeof entry[_valueProperty] === 'number') {
                    value = entry[_valueProperty];
                } else if (typeof entry[_valueProperty] === "string") {
                    value = parseFloat(entry[_valueProperty]);
                }
            }
            retentionData[group][entry.install_date].pil[entry.pil] = value;
        }
        // normalize for install entries
        const now = Date.now();
        for(let group in retentionData) {
            let groupData = retentionData[group];
            for(let i = 0; i < _installs.length; i++) {
                const installDate = _installs[i].install_date;
                let installD = Date.parse(installDate);
                let maxPil = Math.floor((now - installD) / (24 * 60 * 60 * 1000));
                if (maxPil > 0) {
                    if (!groupData[installDate]) {
                        let installsNumber = 0;
                        const lookup = installsLookup.get([installDate, group]);
                        if (lookup && lookup.installs) {
                            installsNumber = lookup.installs;
                        }
                        if (typeof installsNumber === "string") {
                            installsNumber = parseFloat(installsNumber);
                        }
                        groupData[installDate] = {
                            installs : installsNumber,
                            maxPil : maxPil,
                            pil: []
                        }
                    }
                }
            }
        }
        for(let group in retentionData) {
            for(let cohort in retentionData[group]) {
                const series = retentionData[group][cohort];
                if(!series.summedPil) {
                    series.summedPil = [];
                }
                for(let i = 0; i <= series.maxPil; i++) {
                    if (!series.pil[i]) {
                        series.summedPil[i] = 0;
                    } else {
                        series.summedPil[i] = series.pil[i];
                    }
                    if (_withSum && i > 0) {
                        series.summedPil[i] += series.summedPil[i-1];
                    }
                }
            }
        }
        let aggreagted = {};
        for(let group in retentionData) {
            if(!aggreagted[group]) {
                aggreagted[group] = [];
            }
            for (let i = 0; i <= _maxPil; i++) {
                let installs = 0;
                let value = 0;
                for(let cohort in retentionData[group]) {
                    if (retentionData[group][cohort].maxPil >= i) {
                        installs += retentionData[group][cohort].installs;
                        value += retentionData[group][cohort].summedPil[i];
                    }
                }
                if (installs >= 10) {
                    aggreagted[group][i] = {samples: installs, value : value/installs};
                }
            }
        }
        return aggreagted;
    }
}

export default AnalyticsUtil;