import AnalyticsContext from "../AnalyticsContext";
import PieGraph from "./Graphs/PieGraph";
import CachedGraph from "./Graphs/CachedGraph";

class Installs_Pie {
    static getId() {
        return "Installs_Pie";
    }

    static getGraphProps(_props) {
        return {
            title: _props.title ? _props.title : "Installs",
            yAxis: "installs",
            small: true,
            legend: _props.legend
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("installs", {type: "analytics_v3", subType: "es_user_v3", group: [_props.group], filter: mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let graphData = [{data:[]}];
                for (let i = 0; i < _data.installs.result.result.length; i++) {
                    const entry = _data.installs.result.result[i];
                    let group = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                    graphData[0].data.push({name: group, y: parseInt(entry.installs,10)});
                }
                graphData[0].data.sort((a,b) => {return a.name.localeCompare(b.name)});
                if (_props.maxCount && typeof _props.maxCount === "number") {
                    graphData[0].data.sort((a,b) => {return b.y-a.y});
                    let others = 0;
                    while(graphData[0].data.length > _props.maxCount) {
                        let last = graphData[0].data.pop();
                        others += last.y;
                    }
                    if(others > 0) {
                        graphData[0].data.push({name: "other", y: others});
                    }
                }
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(Installs_Pie, PieGraph);