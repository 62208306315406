import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import AnalyticsContext from "../AnalyticsContext";
import CampaignPLCohortBar from "./CampaignPL_Cohort_Bar";
import CampaignPLBar from "./CampaignPL_Bar";
import LoadingBannerUI from "../../common/LoadingBannerUI";
import ErrorBannerUI from "../../common/ErrorBannerUI";
import GraphCache from "./Graphs/GraphCache";
import RetentionLine from "./Retention_Line";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import CampaignPLDetailed_Bar from "./CampaignPLDetailed_Bar";

class CampaignPL extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : true,
            name : _props.campaignId
        };

    }

    componentDidMount() {
        const hash = GraphCache.getHash("CampaignPL_Widget", this.props);
        const cached = GraphCache.getData(hash);
        if (cached) {
            return this.setState((_state, _props)=> {
                return {
                    isLoading: false,
                    name: cached
                };
            });
        }
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("campaignName",{type: "analytics_v3", subType: "campaignName_v3", campaignId : this.props.campaignId});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                this.setState({error: { title : "Couldn't retrieve Campaign Data", text : _err.code+": "+_err.message}, isLoading: false});
            } else {
                GraphCache.storeData(hash, _data.campaignName.result.result);
                return this.setState((_state, _props) => {
                    return {
                        isLoading: false,
                        name : _data.campaignName.result.result
                    }
                });
            }
        });
    }

    componentWillUnmount() {

    }

    render() {
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Loading Campaign Data..."/>);
        }
        if(this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>);
        }
        return(
        <Fragment>
            <Row>
                <h3 className="text-center mt-3">{this.state.name}
                    &nbsp;&nbsp;<Link color="primary" tag={Link} to={"/analytics/"+this.props.gameId+"/campaigns/"+this.props.campaignId+"?start="+this.props.from+"&end="+this.props.to+"&name="+encodeURIComponent(this.state.name)}>
                        <FontAwesomeIcon color="primary" icon={faInfoCircle} />
                    </Link>
                    {/*&nbsp;&nbsp;<Link color="primary" tag={Link} to={"/analytics/"+this.props.gameId+"/campaignsExport/"+this.props.campaignId+"?start="+this.props.from+"&end="+this.props.to}>
                        <FontAwesomeIcon color="primary" icon={faTable} />
                    </Link>*/}
                </h3>
            </Row>
            {(()=> {
                return (
                    <Fragment>
                        <Row>
                            <Col lg={ 6} md={ 12 } className="mb-3">
                                <CampaignPLCohortBar campaignId={this.props.campaignId} from={this.props.from} to={this.props.to}/>
                            </Col>
                            <Col lg={ 3} md={ 6 } className="mb-3">
                                <CampaignPLDetailed_Bar campaignId={this.props.campaignId} pils={[0,1,3,7,14,28]}  from={this.props.from} to={this.props.to}/>
                            </Col>
                            <Col lg={ 3} md={ 6 } className="mb-3">
                                <RetentionLine short startDate={this.props.from} endDate={this.props.to} filter={{ua_campaign : this.props.campaignId}}/>
                            </Col>
                        </Row>
                    </Fragment>
                );
            })()}

        </Fragment>
        );
    }
}

export default CampaignPL;