import React, {Fragment} from "react";

import AnalyticsContext from "./AnalyticsContext";
import {Col, Row} from "reactstrap";

import {formatDate} from "../common/TimeUtil";
import withFilter from "./SiteWithFilter";
import {mergeFilters} from "./Filters/FilterLogic";
import withURLParams from "../common/SiteWithUrlParams";
import PnLDailyLine from "./Components/PnL_Daily_Line";
import Toggle from "react-toggle";
import PnLCohortLine from "./Components/PnL_Cohort_Line";

class PnLPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            updateCounter : 0,
            showDaily : true
        };

        this.onToggle = this.onToggle.bind(this);
    }

    onToggle() {
        this.setState((_state, _props) => {
            return {
                updateCounter : _state.updateCounter +1,
                showDaily : !_state.showDaily
            };
        })
    }

    render() {
        let gameId = this.props.urlParams.gameId;
        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);

        const filter = mergeFilters(this.props.filter, {game_id : gameId});

        const name = this.state.showDaily ? "Daily" : "Cohort";

        const PnLGraph = this.state.showDaily ? PnLDailyLine : PnLCohortLine;

        return (
            <Fragment>
                <br/>
                <Row>
                    <Col lg={ 1} md={ 2 } className="mb-3">
                        <Toggle id='locked' checked={this.state.showDaily} onChange={this.onToggle} />
                    </Col>
                    <Col>
                        <span className={"alert-headings"}>{name}</span>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 12} md={ 12 } className="mt-3">
                        <PnLGraph startDate={startDate} endDate={endDate} filter={filter} />
                    </Col>

                </Row>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(PnLPage, ["filter","time"],(_props)=> {return "P&L Daily: "+AnalyticsContext.getGameName(_props.urlParams.gameId);}));