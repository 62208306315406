import React from "react";
import PropTypes from 'prop-types';
import AnalyticsContext from "../AnalyticsContext";
import {shortPieData} from "./DataUtil";
import ErrorBannerUI from "../../common/ErrorBannerUI";
import PieGraph from "./Graphs/PieGraph";

class ErrorMetric_PieChart extends React.Component {
    constructor(_props) {
        super(_props);

        this.state  = {};
    }

    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
        let mergedFilters = JSON.parse(JSON.stringify(this.props.filter));
        mergedFilters.day = { from : this.props.startDate, to: this.props.endDate };
        this._ctx.getReportV2("errorMetric", this.props.group, mergedFilters, (_err, _report) => {
            if (_err) {
                this.setState({error: {title : "errorMetric", text : _err.code+": "+_err.message}});
            } else {
                if (_report.length > 0) {
                    _report[0].data = shortPieData(_report[0].data, 10);
                }
                this.setState({ data : _report });
            }
        });
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    render() {
        if(this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>);
        }

        return (<PieGraph title="Error" yAxis="number" data={this.state.data} small legend />);
    };
}

ErrorMetric_PieChart.propTypes = {
    startDate : PropTypes.string.isRequired,
    endDate : PropTypes.string.isRequired,
};

export default ErrorMetric_PieChart;