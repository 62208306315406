import BarGraph from "./Graphs/BarGraph";
import AnalyticsContext from "../AnalyticsContext";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import StatisticUtil from "../StatisticUtil";
import GraphUtil from "./Graphs/GraphUtil";

class CampaignPLDetailed_Bar {
    static getId() {
        return "CampaignPLDetailed_Bar";
    }

    static getGraphProps(_props) {
        return {
            title: "Spend & Revenue",
            xAxis: "dataCategory",
            yAxis: "euro",
            small: true,
            legend : false
        };
    }

    static getRequiredProps() {
        return ["from","to","campaignId"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("iap",{type: "analytics_v3", subType: "es_adImpression_v3", group: ["install_date","day"],filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.sendAsync("ad",{type: "analytics_v3", subType: "es_premiumPurchase_v3", group: ["install_date","day"], filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.sendAsync("cpi",{type: "analytics_v3", subType: "es_user_v3", group: ["install_date"], filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                return _cb(_err);
            } else {
                let installLookup = AnalyticsUtil.generateLookup(_data.cpi.result.result, "install_date","installs", (_a) => parseFloat(_a));

                for(let i = 0; i < _data.cpi.result.result.length; i++) {
                    _data.cpi.result.result[i].samples = installLookup[_data.cpi.result.result[i].install_date];
                }
                let cost = AnalyticsUtil.aggregateAll(_data.cpi.result.result,"cpi", StatisticUtil.sum);
                let graphData = [{name:"default", data:[]}];
                const revenue = AnalyticsUtil.join(_data.iap.result.result, _data.ad.result.result);
                const revenuePil = AnalyticsUtil.addPil(revenue);
                for (let i = 0; i < _props.pils.length; i++) {
                    const pil = _props.pils[i];
                    let dRevData = AnalyticsUtil.filterBy(revenuePil, (_entry) => {return _entry.pil <= pil});
                    let dRev = AnalyticsUtil.aggregateAll(dRevData, "revenue", StatisticUtil.sum);
                    graphData[0].data.push({name:"D"+pil, y: dRev});
                }

                let rev = AnalyticsUtil.aggregateAll(revenue, "revenue", StatisticUtil.sum);
                graphData[0].data.push({name: "Revenue", y: rev});
                graphData[0].data.push({name: "Cost",y: cost});
                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(CampaignPLDetailed_Bar, BarGraph);