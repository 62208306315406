import React, {Fragment} from "react";

import AnalyticsContext from "./AnalyticsContext";
import {Col, Row} from "reactstrap";

import {formatDate} from "../common/TimeUtil";
import withFilter from "./SiteWithFilter";
import {mergeFilters} from "./Filters/FilterLogic";
import ErrorMetric_daily from "./Components/ErrorMetric_daily";
import ErrorMetric_PieChart from "./Components/ErrorMetric_PieChart";
import withURLParams from "../common/SiteWithUrlParams";
import AdLatency_daily from "./Components/AdLatency_Daily_Line";

class ErrorStatsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            updateCounter : 0
        };

    }

    render() {
        let gameId = this.props.urlParams.gameId;
        let comp = 0;
        let getKey = () => {
            const id = comp;
            comp +=1;
            return "comp_"+id+"_"+this.state.updateCounter;
        };

        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);



        const filter = mergeFilters(this.props.filter, {game_id : gameId});

        return (
            <Fragment>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <ErrorMetric_daily key={getKey()} startDate={startDate} endDate={endDate} filter={filter} />
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mt-3">
                        <AdLatency_daily key={getKey()} startDate={startDate} endDate={endDate} group={this.props.group} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 3} md={ 6 } className="mt-3">
                        <ErrorMetric_PieChart key={getKey()} startDate={startDate} endDate={endDate} group="name" filter={filter} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(ErrorStatsPage, ["filter","time","group"],(_props)=> {return "Errors: "+AnalyticsContext.getGameName(_props.urlParams.gameId);}));