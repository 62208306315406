import BarGraph from "./Graphs/BarGraph";
import AnalyticsContext from "../AnalyticsContext";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";

class PurchaseCount_Pil_Bar {
    static getId() {
        return "PurchaseTimes_Pil_Bar";
    }

    static getGraphProps(_props) {
        return {
            title : "Purchase Count",
            xAxis : "retention",
            yAxis : "number"
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if(_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("iap",{type: "analytics_v3", subType: "es_premiumPurchase_v3", group: ["install_date","day"], filter : mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                return _cb(_err);
            } else {
                const revenuePil = AnalyticsUtil.addPil(_data.iap.result.result);
                let graphData = [{data: []}];
                for (let pil = 0; pil <= 28; pil++) {
                    let purchases = 0;
                    for (let i = 0; i < revenuePil.length; i++) {
                        const entry = revenuePil[i];
                        if (entry.pil === pil) {
                            purchases += parseInt(entry.purchase_count);
                        }
                    }
                    graphData[0].data[pil] = {name: "D"+pil, y : purchases};
                }
                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(PurchaseCount_Pil_Bar, BarGraph);