import EventBus from "../common/EventBus";
import MeteorContext from "../meteor/meteorContext";
import jwt from "jsonwebtoken";
import Hierarchy from "../common/Hierarchy";
import Routes from "../common/Routes";
import { faGitlab} from '@fortawesome/free-brands-svg-icons'

import WSConnection from "../common/WSConnection";
import * as Config from "../config";
import FirebuildAdmin from "./FirebuildAdmin";
import FirebuildBuildsPage from "./FirebuildBuildsPage";
import FirebuildBuildPage from "./FirebuildBuildPage";


class FirebuildContext {
    constructor() {
        let url = "wss://v6.firebuild.coldfire.io/live";
        if (Config.isDebug) {
            url = "wss://v6.firebuild.coldfire.io/test";
        }
        this._connection = new WSConnection(url);
        this._projects = [];

        EventBus.registerHandler("METEOR_AUTHENTICATION_CHANGED", this.onMeteorAuthenticationChanged.bind(this));

        this.onMeteorAuthenticationChanged();
    }

    getPlistLink(_buildId) {
        return "itms-services://?action=download-manifest&url=https://plist.v6.firebuild.coldfire.io/" + (Config.isDebug ? "test" : "live") + "/"+_buildId+".plist";
    }

    getExistingPlistLink(_s3Bucket, _plistFile) {
        return "itms-services://?action=download-manifest&url="+_s3Bucket+_plistFile;
    }


    waitForAuth(_cb) {
        if (this.isAuthenticated()) {
            return _cb();
        } else {
            this._authCb = _cb;
        }
    }

    isAuthenticated() {
        if (this._token) {
            const decoded = jwt.decode(this._token);
            const now = Math.floor(new Date().getTime()/1000);
            if (decoded && decoded.exp && decoded.exp > now) {
                return true;
            }
        }
        return false
    };

    getPermissions() {
        if (this._token) {
            try {
                const decoded = jwt.decode(this._token);
                if (decoded && decoded.permissions) {
                    return decoded.permissions;
                }
            } catch(ex) {}
        }
        return {};
    }

    isAdmin() {
        const permissions = this.getPermissions();
        return permissions && permissions.admin === true;
    };

    hasPermission(_permission) {
        const permissions = this.getPermissions();
        return permissions && permissions[_permission] === true;
    }

    listUsers(_cb) {
        return this._connection.send({type : "auth", subType : "list", authToken : this._token}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, _response.users);
        });
    };

    changePermissions(_id, _permissions, _cb) {
        return this._connection.send({type : "auth", subType: "changePermission", authToken: this._token, sid: _id, permissions : _permissions}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb();
        });
    };

    getMeta(_cb) {
        return this._connection.send({type : "auth", subType : "getMeta", authToken : this._token}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, {validPermissions : _response.permissions});
        });
    };

    cancelRequest(_uuid) {
        this._connection.cancelRequest(_uuid);
    }

    sendAuthenticated(_params, _cb) {
        let params = JSON.parse(JSON.stringify(_params));
        params.authToken = this._token;
        return this._connection.send(params, (_response) => {
            if (Config.isDebug) {
                console.log("ServerResponse: " + JSON.stringify(_response));
            }
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, _response);
        });
    }

    getProjectConfig(_projectId) {
        for(let i = 0; i < this._projects.length; i++) {
            if(this._projects[i].projectId === _projectId) {
                return this._projects[i];
            }
        }
        return null;
    }

    onMeteorAuthenticationChanged() {
        let node = Hierarchy.getNode("Builds");
        if (!node) {
            node = Hierarchy.addNode("Builds",faGitlab);
        }
        node.clearChilds();
        node.setSyncMode(true);
        if(MeteorContext.isAuthenticated()) {
            const meteorToken = MeteorContext.getToken();
            this._connection.send({type: "auth", subType : "loginMeteor", authToken: meteorToken}, (_response) => {
                if (_response.type === "auth" && _response.subType === "authToken" && _response.token) {
                    this._token = _response.token;
                }
                Routes.addRoute("/firebuild/admin", FirebuildAdmin);
                Routes.addRoute("/firebuild/build/:buildId", FirebuildBuildPage);
                Routes.addRoute("/firebuild/:projectId/:buildType", FirebuildBuildsPage);

                return this._connection.send({type: "projects", subType : "list", authToken: this._token}, (_response) => {
                    if (_response.type === 'projects' && _response.subType === 'listResult' && _response.projects) {
                        this._projects = _response.projects;
                    }
                    this._projects.sort((_a,_b) => {return _a.sortOrder-_b.sortOrder;});
                    for(let i = 0; i < this._projects.length; i++) {
                        const project = this._projects[i];
                        if (project.hidden !== true) {
                            const gameNode = node.addNode(project.displayName,null,null);
                            gameNode.addNode("devel", null, "/firebuild/"+project.projectId+"/devel");
                            gameNode.addNode("stable", null, "/firebuild/"+project.projectId+"/stable");
                            gameNode.addNode("release", null, "/firebuild/"+project.projectId+"/release");
                        }
                    }
                    if (this.isAdmin()) {
                        node.addNode("Admin",null, "/firebuild/admin");
                    }
                    node.setSyncMode(false);
                    Hierarchy.redrawUI();
                });
            });
        }
    }


}

const instance = new FirebuildContext();
export default instance;