import React, {Fragment} from "react";
import {Button, Card, CardBody, CardHeader, CardText, Col, Row} from "reactstrap";
import ResetPasswordModal from "../meteor/MeteorAdmin/ResetPasswordModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faCalendar, faClock, faCodeBranch, faUser, faDownload, faPencilAlt, faHashtag, faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { faApple,faAndroid, faGitlab, faAppStoreIos, faGooglePlay} from '@fortawesome/free-brands-svg-icons'
import DeleteUserModel from "../meteor/MeteorAdmin/DeleteUserModal";
import {Link} from "react-router-dom";
import FirebuildContext from "./FirebuildContext";

function format(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return _num;
}

function formatDate(_date) {
    return format(_date.getDate())+"."+format(_date.getMonth()+1)+"."+_date.getFullYear();
}

function formatTime(_date) {
    return format(_date.getHours())+":"+format(_date.getMinutes())+":"+format(_date.getSeconds());
}

class FirebuildBuildEntry extends React.Component {
    constructor(_props) {
        super(_props);

        this.onDeleteButton = this.onDeleteButton.bind(this);
        this.onEditButton = this.onEditButton.bind(this);
        this.renderDownloadButtons = this.renderDownloadButtons.bind(this);
    }

    onDeleteButton() {
        if(this.props.onDelete && typeof this.props.onDelete === "function") {
            this.props.onDelete(this.props.build.buildId);
        }
    }

    onEditButton() {
        if(this.props.onEdit && typeof this.props.onEdit === "function") {
            this.props.onEdit(this.props.build.buildId);
        }
    }

    renderDownloadButtons(_entry) {
        let iosDownloadButton = (<Button color="dark"><FontAwesomeIcon icon={faApple} /></Button>);
        if (_entry.iosPlist) {
            iosDownloadButton = (<a href={FirebuildContext.getExistingPlistLink(this.props.project.s3Bucket, _entry.iosPlist)}>
                <Button color="primary"><FontAwesomeIcon icon={faApple} /></Button>
            </a>);
        }
        else if (_entry.iosLink) {
            iosDownloadButton = (<a href={FirebuildContext.getPlistLink(_entry.buildId)}>
                <Button color="primary"><FontAwesomeIcon icon={faApple} /></Button>
            </a>);
        }
        let apkDownloadButton = (<Button color="dark"><FontAwesomeIcon icon={faAndroid} /></Button>);
        if (_entry.androidLink) {
            apkDownloadButton = (<a href={this.props.project.s3Bucket+ _entry.androidLink}>
                <Button color="primary"><FontAwesomeIcon icon={faAndroid} /></Button>
            </a>);
        }
        let xarchiveDownloadButton;
        if (_entry.iosXarchive) {
            xarchiveDownloadButton = (<a href={this.props.project.s3Bucket+ _entry.iosXarchive}>
                <Button color="primary"><FontAwesomeIcon icon={faAppStoreIos} />&nbsp;<FontAwesomeIcon icon={faDownload} /></Button>
            </a>);
        }
        let bundleDownloadButton;
        if (_entry.androidBundle) {
            bundleDownloadButton = (<a href={this.props.project.s3Bucket+ _entry.androidBundle}>
                <Button color="primary"><FontAwesomeIcon icon={faGooglePlay} />&nbsp;<FontAwesomeIcon icon={faDownload} /></Button>
            </a>);
        }
        return (
            <Fragment>
                &nbsp;&nbsp;&nbsp;
                {iosDownloadButton}
                &nbsp;&nbsp;&nbsp;
                {apkDownloadButton}
                &nbsp;&nbsp;&nbsp;
                {xarchiveDownloadButton}
                &nbsp;&nbsp;&nbsp;
                {bundleDownloadButton}
            </Fragment>
        );
    }

    render() {
        let timestamp = new Date(this.props.build.timestamp*1000);
        let externalLinkButton;
        if (this.props.withLink) {
            externalLinkButton = (<a href={"/firebuild/build/"+this.props.build.buildId}>
                <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            </a>);
        }
        let deleteButton;
        if (this.props.canDelete === true) {
            deleteButton = (<Button className="float-right" size="sm" color="danger" onClick={this.onDeleteButton}><FontAwesomeIcon icon={faTrash} /></Button>);
        }
        let editButton;
        if (this.props.canEdit === true) {
            editButton = (<Button color="primary" className="float-right" style={{"marginRight":"20px"}} onClick={this.onEditButton}><FontAwesomeIcon icon={faPencilAlt} /></Button>);
        }

        return (
            <Card className="mb-3 mt-3">
                <CardHeader tag="h6" className="bg-info text-white">
                    <Row>
                        <Col>
                            {"v"+this.props.build.version+" ("+this.props.build.buildType+")"}&nbsp;&nbsp;{externalLinkButton}
                        </Col>
                        {deleteButton}
                    </Row>
                </CardHeader>
                <CardBody className="bg-secondary">
                    <CardText className="text-light" style={{margin: "2px 0 2px 0"}}>
                        &nbsp;
                        <FontAwesomeIcon icon={faCalendar} /> &nbsp; {formatDate(timestamp)} &nbsp; &nbsp;
                        <FontAwesomeIcon icon={faClock} /> &nbsp; {formatTime(timestamp)}
                    </CardText>
                    <hr className="bg-dark" style={{height: "1px", border:"0", "margin":"0 0 0 0" }}/>
                    <CardText className="text-light" style={{margin: "2px 0 2px 0"}}>
                        &nbsp;
                        <FontAwesomeIcon icon={faCodeBranch}/> &nbsp; {this.props.build.branchName ? this.props.build.branchName : "N/A"} &nbsp; &nbsp;
                        <FontAwesomeIcon icon={faGitlab}/> &nbsp; {this.props.build.buildNumber ? this.props.build.buildNumber : "N/A"}
                    </CardText>
                    <hr className="bg-dark" style={{height: "1px", border:"0", "margin":"0 0 0 0"}}/>
                    <CardText className="text-light" style={{margin: "2px 0 2px 0"}}>
                        &nbsp;
                        <FontAwesomeIcon icon={faUser}/> &nbsp; {this.props.build.owner} &nbsp; &nbsp;
                        <FontAwesomeIcon icon={faHashtag}/> &nbsp; {this.props.build.scmHash} &nbsp; &nbsp;
                    </CardText>
                    <hr className="bg-dark" style={{height: "1px", border:"0", "margin":"0 0 10px 0"}}/>
                    <Row>
                        <Col className="text-light">
                            {this.props.build.comment}
                        </Col>
                        {editButton}
                    </Row>
                    <hr className="bg-dark" style={{height: "1px", border:"0", "margin":"10px 0 10px 0"}}/>
                    <Row>
                        {this.renderDownloadButtons(this.props.build)}
                    </Row>
                    {(()=>{
                        let result = [];
                        if (this.props.build.thirdParty) {
                            for (let i = 0; i < this.props.build.thirdParty.length; i++) {
                                const entry = this.props.build.thirdParty[i];
                                result.push(
                                    <Fragment>
                                    <hr className="bg-dark" style={{height: "1px", border:"0", "margin":"10px 0 10px 0"}}/>
                                    <Row>
                                        <CardText className="text-light" style={{margin: "2px 0 2px 0"}}>
                                            &nbsp;{entry.id}
                                        </CardText>
                                        {this.renderDownloadButtons(entry.data)}
                                    </Row>
                                    </Fragment>
                                )
                            }
                        }
                        return result;
                    })()}
                </CardBody>
            </Card>
        )
    }

}

export default FirebuildBuildEntry;