import BarGraph from "./Graphs/BarGraph";
import AnalyticsContext from "../AnalyticsContext";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import GraphUtil from "./Graphs/GraphUtil";
import StatisticUtil from "../StatisticUtil";
import {mergeFilters} from "../Filters/FilterLogic";

class CampaignPLDetailed_Cohort_Bar {
    constructor() {
    }

    static getId() {
        return "CampaignPLDetailed_Daily_Bar";
    }

    static getGraphProps(_props) {
        let title = "Spend & Revenue";
        if (_props.byUser) {
            title += " (User)";
        }
        return {
            title : title,
            xAxis : "time",
            yAxis : "euro"
        };
    }

    static getRequiredProps() {
        return ["from","to","campaignId","pils"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        const filter = mergeFilters({ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}, _props.filter);
        ctx.sendAsync("iap",{type: "analytics_v3", subType: "es_premiumPurchase_v3", group: ["install_date","day"], filter : filter});
        ctx.sendAsync("ad",{type: "analytics_v3", subType: "es_adImpression_v3", group: ["install_date","day"], filter : filter});
        ctx.sendAsync("cpi",{type: "analytics_v3", subType: "es_user_v3", group: ["install_date"], filter : filter});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                return _cb(_err);
            } else {
                let installLookup = AnalyticsUtil.generateLookup(_data.cpi.result.result, "install_date","installs", (_a) => parseFloat(_a));

                if(_props.byUser) {
                    AnalyticsUtil.scaleBy(_data.cpi.result.result, "install_date","cpi", (_installDate) => 1/installLookup[_installDate]);
                }
                for(let i = 0; i < _data.cpi.result.result.length; i++) {
                    _data.cpi.result.result[i].samples = installLookup[_data.cpi.result.result[i].install_date];
                }
                let graphData = GraphUtil.generateNamedTimeseries("Cost",_data.cpi.result.result, "install_date","cpi");

                const revenue = AnalyticsUtil.join(_data.iap.result.result, _data.ad.result.result);
                const revenuePil = AnalyticsUtil.addPil(revenue);
                for (let i = 0; i < _props.pils.length; i++) {
                    const pil = _props.pils[i];
                    let dRevData = AnalyticsUtil.filterBy(revenuePil, (_entry) => {return _entry.pil <= pil});
                    let dRev = AnalyticsUtil.aggregateBy(dRevData, "install_date", "revenue", StatisticUtil.sum);
                    if(_props.byUser) {
                        AnalyticsUtil.scaleBy(dRev, "install_date","revenue", (_installDate) => 1/installLookup[_installDate]);
                    }
                    for(let i = 0; i < dRev.length; i++) {
                        dRev[i].samples = installLookup[dRev[i].install_date];
                    }
                    let dRevReport = GraphUtil.generateNamedTimeseries("D"+pil, dRev, "install_date","revenue");
                    graphData = GraphUtil.mergeReports(graphData, dRevReport);
                }

                let rev = AnalyticsUtil.aggregateBy(revenue, "install_date", "revenue", StatisticUtil.sum);
                if(_props.byUser) {
                    AnalyticsUtil.scaleBy(rev, "install_date","revenue", (_installDate) => 1/installLookup[_installDate]);
                }
                for(let i = 0; i < rev.length; i++) {
                    rev[i].samples = installLookup[rev[i].install_date];
                }
                let revReport = GraphUtil.generateNamedTimeseries("LTV", rev, "install_date","revenue");
                graphData = GraphUtil.mergeReports(graphData, revReport);

                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(CampaignPLDetailed_Cohort_Bar, BarGraph);