import AnalyticsContext from "../AnalyticsContext";
import BarGraph from "./Graphs/BarGraph";
import CachedGraph from "./Graphs/CachedGraph";

class AdViewers_Bar {
    static getId() {
        return "AdViewers_Bar";
    }

    static getGraphProps(_props) {
        return {
            title: "Ad Viewer",
            xAxis: "dataCategory",
            yAxis: "percent",
            small: true
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        ctx.sendAsync("viewers", {type: "analytics_v3", subType: "es_adImpression_v3", group: [_props.group], filter:mergedFilters });
        ctx.sendAsync("installs", {type: "analytics_v3", subType: "es_user_v3", group: [_props.group], filter:mergedFilters });
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let groups = {};
                for(let i = 0; i < _data.viewers.result.result.length; i++) {
                    const entry = _data.viewers.result.result[i];
                    let groupName = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                    if (!groups[groupName]) {
                        groups[groupName] = {user: 0, viewer:0};
                    }
                    groups[groupName].viewer += parseInt(entry.users,10);
                }
                for(let i = 0; i < _data.installs.result.result.length; i++) {
                    const entry = _data.installs.result.result[i];
                    let groupName = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                    if (!groups[groupName]) {
                        groups[groupName] = {user: 0, viewer: 0};
                    }
                    groups[groupName].user += parseInt(entry.installs,10);
                }
                let graphData = [{name: "Ad Viewers", data : []}];
                for(let groupName in groups) {
                    const entry = groups[groupName];
                    graphData[0].data.push({name : groupName, y : Math.round(10000*entry.viewer / entry.user)/100});
                }
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(AdViewers_Bar, BarGraph);