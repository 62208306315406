import React, {Fragment} from "react";
import AnalyticsContext from "../AnalyticsContext";
import {Col, Row} from "reactstrap";
import ErrorBannerUI from "../../common/ErrorBannerUI";
import LoadingBannerUI from "../../common/LoadingBannerUI";
import RatingPie from "./Rating_Pie";
import RatingBar from "./Rating_Bar";
import GraphCache from "./Graphs/GraphCache";

class AB_Ratings extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : true
        }

    }

    componentDidMount() {
        const hash = GraphCache.getHash("AB_Ratings", this.props);
        const cached = GraphCache.getData(hash);
        if (cached) {
            return this.setState((_state, _props) => {
                return {isLoading: false, abGroups : cached};
            });
        }
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("abGroups", {type : "analytics_v3", subType: "abGroups_v3", filter : this.props.filter});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return this.setState((_state, _props) => {
                    return {
                        isLoading: false,
                        abGroups: null,
                        error: {title : "Couldn't retrieve AB-Groups", text : _err.code+": "+_err.message}
                    };
                });
            }
            let groups = [];
            for(let i = 0; i < _data.abGroups.result.result.length; i++) {
                groups.push(_data.abGroups.result.result[i].ab_group);
            }
            groups.sort((_a,_b) => {return _a.localeCompare(_b)});
            GraphCache.storeData(hash, groups);
            return this.setState((_state, _props) => {
                return {
                    error: null,
                    isLoading: false,
                    abGroups: groups
                };
            });
        });
    }

    componentWillUnmount() {
    }

    render() {

        if (this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>)
        }
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Retrieving AB-Groups..."/>)
        }

        let rows = [];
        if (this.state.abGroups) {
            rows.push(
                <Col key={"rating_all"} lg={ 3} md={6} className="mb-3">
                    <RatingBar group="ab_group" filter={this.props.filter}/>
                </Col>
            );
            for (let i = 0; i < this.state.abGroups.length; i++) {
                const abGroup = this.state.abGroups[i];
                let abFilter = JSON.parse(JSON.stringify(this.props.filter));
                abFilter.ab_group = abGroup;
                rows.push(
                    <Col key={"rating_"+i} lg={ 3} md={6} className="mb-3">
                        <RatingPie title={abGroup} filter={abFilter}/>
                    </Col>
                );
            }
        }

        return (
            <Fragment>
                <Row>
                    <h5 className="text-center mt-3">Ratings</h5>
                </Row>
                <Row>
                    {rows}
                </Row>
            </Fragment>
        );
    }
}

export default AB_Ratings;