import React from 'react';
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import _ from 'lodash';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";

import MeteorContext from "../meteorContext";

class DeleteUserModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username : "",
            password : "",
            isLocked : false,
            error : null
        };

        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onCancel(event) {
        this.closeModal();
    }

    onDelete(event) {
        this.setState({isLocked: true});
        return MeteorContext.deleteUser(this.props.userId, (_err) => {
            if(_err) {
                this.setState({
                    isLocked : false,
                    error : {
                        title : _err.code+": "+_err.message,
                        text : ""
                    }
                });
            } else {
                this.closeModal()
            }

        });
    }

    closeModal() {
        this.setState({isLocked: false, error: null});
        if (this.props.onClose && typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }



    render() {
        const modalProps = _.omit(this.props, ['target']);

        return (
            <Modal { ...modalProps } isOpen={ this.props.isOpen } toggle={ this.onCancel } >
                <ModalHeader tag="h6">
                    <span className="text-info">
                        Delete User
                    </span>
                </ModalHeader>
                <ModalBody>
                    <span className="text-white ml-1">
                        Do you really want to delete User: {this.props.userId}
                    </span>
                    {
                        (() => {
                            if (this.state.error) {
                                return (
                                    <Alert color="danger">
                                        <i className="fa fa-times-circle mr-1 alert-icon"></i>
                                        <span>
                                            <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                                        </span>
                                    </Alert>
                                );
                            }
                        })()
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" tag = { Link } disabled={this.state.isLocked} onClick={this.onCancel}>
                        Cancel
                    </Button>
                    <Button color="info" tag={ Link }  disabled={this.state.isLocked} onClick={this.onDelete}>
                        {(() => {
                            if (this.state.isLocked) {
                                return (<FontAwesomeIcon icon={faSync} spin pull="left"/>);
                            }
                        })()}
                        { this.state.isLocked ? 'Deleting ...' : "Delete" }
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteUserModal;