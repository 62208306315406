
class DisposableCallback {
    constructor(_callback) {
        this._enabled = true;
        this._callback = _callback;
    }

    Dispose() {
        this._enabled = false;
        this._callback = null;
    }

    CallCallback(...params) {
        if (this._enabled === true) {
            this._enabled = false;
            const cb = this._callback;
            this._callback = null;
            if (cb) {
                cb(...params);
            }
        }
    }
}

export default DisposableCallback;