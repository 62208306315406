import AnalyticsContext from "../AnalyticsContext";
import PieGraph from "./Graphs/PieGraph";
import CachedGraph from "./Graphs/CachedGraph";
import {mergeFilters} from "../Filters/FilterLogic";

class Privacy_Pie {
    static getId() {
        return "Privacy_Pie";
    }

    static getGraphProps(_props) {
        return {
            title: _props.title,
            yAxis: "users",
            small: true,
            legend: true
        };
    }

    static getRequiredProps() {
        return ["filter","dateSelection"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        const mergedFilter = mergeFilters(_props.filter, {limit_ad_tracking: {notNull: true}, day: { from : _props.dateSelection.start, to: _props.dateSelection.end}});
        ctx.sendAsync("users", {type: "analytics_v3", subType: "es_sessionDuration_v3", group: ["limit_ad_tracking"], filter: mergedFilter, uniqueUsers: true});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let graphData = [{data:[]}];
                for (let i = 0; i < _data.users.result.result.length; i++) {
                    const entry = _data.users.result.result[i];
                    let group = entry.limit_ad_tracking === true ? "IDFA disabled" : "IDFA enabled";
                    graphData[0].data.push({name: group, y: parseInt(entry.unique_users,10)});
                }
                graphData[0].data.sort((a,b) => {return a.name.localeCompare(b.name)});
                if (_props.maxCount && typeof _props.maxCount === "number") {
                    graphData[0].data.sort((a,b) => {return b.y-a.y});
                    let others = 0;
                    while(graphData[0].data.length > _props.maxCount) {
                        let last = graphData[0].data.pop();
                        others += last.y;
                    }
                    if(others > 0) {
                        graphData[0].data.push({name: "other", y: others});
                    }
                }
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(Privacy_Pie, PieGraph);