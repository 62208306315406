function renameSeries(_report, _oldName, _newName) {
    for (let i = 0; i < _report.length; i++) {
        const otherSeries = _report[i];
        if(otherSeries.name === _oldName) {
            otherSeries.name = _newName;
        }
    }
}

function extractSeries(_report, _seriesName) {
    for(let i = 0; i < _report.length; i++) {
        if(_report[i].name === _seriesName) {
            return _report[i];
        }
    }
}

function mergeSeries(_series1, _series2) {
    for(let i = 0; i < _series2.data.length; i++) {
        let entry = _series2.data[i];
        let found = false;
        for (let j = 0; j < _series1.data.length; j++) {
            if (_series1.data[j].x === entry.x) {
                _series1.data[j].y += entry.y;
                found = true;
                break;
            }
        }
        if (!found) {
            _series1.data.push(entry);
        }
    }
    _series1.data.sort((a,b) => {return a.x-b.x});
}

class GraphUtil {

    static generateNamedTimeseries(_name, _data, _x, _y, _groupName) {
        let report = this.generateTimeSeries(_data, _x, _y, _groupName);
        renameSeries(report, "default", _name);
        return report;
    }

    static generateRetentionSeries(_data, _minPil, _maxPil, _isPercent) {
        let graphData = [];
        for(let group in _data) {
            let seriesData = {name: group, data : []};
            for(let i = 0; i <_data[group].length; i++) {
                if (_minPil <= i && i <= _maxPil) {
                    let value = _data[group][i].value;
                    if (_isPercent) {
                        value *= 100;
                    }
                    seriesData.data.push({x: i, y: value, samples: _data[group][i].samples});
                }
            }
            graphData.push(seriesData);
        }
        graphData.sort((_a, _b) => {return _a.name.localeCompare(_b.name)})
        return graphData;
    }

    static generateTimeSeries(_data, _x, _y, _groupName) {
        let data = {};
        for(let i = 0; i < _data.length; i++) {
            const entry = _data[i];
            let groupName = _groupName ? entry[_groupName] : "default";
            if (!groupName) {
                groupName = "unknown";
            }
            if (!data[groupName]) {
                data[groupName] = {name : groupName, data : []};
            }
            let y = parseFloat(entry[_y]);
            data[groupName].data.push({x : Date.parse(entry[_x]), y : y ? y : 0, samples : (entry.samples ? parseInt(entry.samples) : undefined )});
        }
        let report = [];
        for(let groupName in data) {
            let current = data[groupName];
            current.data.sort((a,b) => {return a.x-b.x});
            report.push(current);
        }
        return report;
    }

    static mergeReports(_report1, _report2) {
        if (_report1 === null || _report1 === undefined) {
            return _report2;
        }
        let copy = JSON.parse(JSON.stringify(_report1));
        for (let i = 0; i < _report2.length; i++) {
            const otherSeries = _report2[i];
            let series = extractSeries(copy, otherSeries.name);
            if (series ) {
                mergeSeries(series, otherSeries);
            } else {
                copy.push(JSON.parse(JSON.stringify(otherSeries)));
            }
        }
        copy.sort((_a, _b) => {return _a.name.localeCompare(_b.name)})
        return copy;
    }
}

export default GraphUtil;