import AnalyticsContext from "../AnalyticsContext";
import BarGraph from "./Graphs/BarGraph";
import {mergeFilters} from "../Filters/FilterLogic";
import CachedGraph from "./Graphs/CachedGraph";

class Purchaser_Bar {
    static getId() {
        return "Purchaser_Bar";
    }

    static getGraphProps(_props) {
        return {
            title: "Purchaser",
            xAxis: "dataCategory",
            yAxis: "percent",
            small: true,
            lowPercentage: true,
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if(_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("payer", {type: "analytics_v3", subType: "es_user_v3", group: [_props.group], filter: mergeFilters(mergedFilters, {is_payer: true}) });
        ctx.sendAsync("nonPayer", {type: "analytics_v3", subType: "es_user_v3", group: [_props.group], filter: mergeFilters(mergedFilters, {is_payer: false}) });
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let groups = {};
                for(let i = 0; i < _data.payer.result.result.length; i++) {
                    const entry = _data.payer.result.result[i];
                    let groupName = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                    if (!groups[groupName]) {
                        groups[groupName] = {payer: 0, nonPayer: 0};
                    }
                    groups[groupName].payer += parseInt(entry.installs,10);
                }
                for(let i = 0; i < _data.nonPayer.result.result.length; i++) {
                    const entry = _data.nonPayer.result.result[i];
                    let groupName = (_props.group && entry[_props.group]) ? entry[_props.group] : "default";
                    if (!groups[groupName]) {
                        groups[groupName] = {payer: 0, nonPayer: 0};
                    }
                    groups[groupName].nonPayer += parseInt(entry.installs,10);
                }
                let graphData = [{name: "Purchaser", data: []}];
                for(let groupName in groups) {
                    const entry = groups[groupName];
                    graphData[0].data.push({name : groupName, y : Math.round(10000*entry.payer / (entry.payer+entry.nonPayer))/100});
                }
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(Purchaser_Bar, BarGraph);