import AnalyticsContext from "../AnalyticsContext";
import BarGraph from "./Graphs/BarGraph";
import CachedGraph from "./Graphs/CachedGraph";

class AdPlacementViewRates_Bar {
    static getId() {
        return "AdPlacementViewRates_Bar";
    }

    static getGraphProps(_props) {
        return {
            title: "Ad View Rates",
            xAxis: "dataCategory",
            yAxis: "number",
            stacking: "percent"
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.day = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("adStats", {type : "analytics_v3", subType: "es_adImpression_v3", group: ["placement"], filter: mergedFilters});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let placements = {};
                for (let i = 0; i < _data.adStats.result.result.length; i++) {
                    const entry = _data.adStats.result.result[i];
                    if (!placements[entry.placement]) {
                        placements[entry.placement] = {viewed : 0, dismissed : 0};
                    }
                    placements[entry.placement].viewed += parseInt(entry.impressions,10);
                    placements[entry.placement].dismissed += parseInt(entry.discards,10);
                }
                let viewsData = [];
                let dismissedData = [];
                for (let adName in placements) {
                    const entry = placements[adName];
                    if (entry.viewed > 0 || entry.dismissed > 0) {
                        viewsData.push({name: adName, y: entry.viewed});
                        dismissedData.push({name: adName, y: entry.dismissed});
                    }
                }
                let graphData = [{name: "Dismissed", data: dismissedData}, {name: "Viewed", data: viewsData}];
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(AdPlacementViewRates_Bar, BarGraph);