import React, {Fragment} from "react";
import withURLParams from "../common/SiteWithUrlParams";
import withFilter from "./SiteWithFilter";
import {Button, Input, InputGroup} from "reactstrap";
import AnalyticsContext from "./AnalyticsContext";
import createTaskStateUI from "./TaskStateUI";
import {formatDate} from "../common/TimeUtil";
import {mergeFilters} from "./Filters/FilterLogic";
import Events_Pil_Bar from "./Components/Events_Pil_Bar";
import Events_Pil_Component from "./Components/Events_Pil_Component";

class MaxImpressionsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            updateCounter : 1,
            isLocked : false,
            eventName: ""
        };

        this.onEventNameChanged = this.onEventNameChanged.bind(this);
        this.retrieveEventData = this.retrieveEventData.bind(this);
    }

    onEventNameChanged(_event) {
        let newName = _event.target.value;
        return this.setState(() => {
            return {eventName : newName};
        })
    }

    retrieveEventData() {
        this.setState(() => {
            return {isLocked: true};
        });
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        let retrieveButton = null;
        if (!this.state.isLocked) {
            retrieveButton = (<Button color="primary" onClick={this.retrieveEventData}>Retrieve Event Data</Button>);
        }
        let graph = null;
        if (this.state.isLocked) {
            const startDate = formatDate(this.props.dateSelection.start);
            const endDate = formatDate(this.props.dateSelection.end);
            let filter = mergeFilters({game_id: this.props.urlParams.gameId}, this.props.filter);
            graph = (<Events_Pil_Component eventName={this.state.eventName} startDate={startDate} endDate={endDate} filter={filter}/>)
        }
        return (
            <Fragment>
                <br/>
                <InputGroup>
                    <Input type="text" name="comment" id="comment" placeholder={"Enter event names here..."} value={this.state.eventName} onChange={this.onEventNameChanged} disabled={this.state.isLocked} />
                </InputGroup>
                <br/>
                {retrieveButton}
                {graph}
                <br/>
                <br/>
                <br/>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(MaxImpressionsPage,["time","filter"], (_props)=> {return "Events: "+_props.urlParams.gameId;}));