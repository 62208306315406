import React from "react";
import withURLParams from "../common/SiteWithUrlParams";
import {Alert, Container} from "reactstrap";
import FirebuildContext from "./FirebuildContext";
import {HeaderMain} from "../routes/components/HeaderMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import FirebuildBuildEntry from "./FirebuildBuildEntry";
import DeleteBuildModal from "./DeleteBuildModal";
import EditBuildModal from "./EditBuildModal";



class FirebuildBuildPage extends React.Component {
    constructor(_props) {
        super(_props);


        this.state = {
            isLoading: true,
            updateCounter : 1
        };

        this.onDeleteBuild = this.onDeleteBuild.bind(this);
        this.onEditBuild = this.onEditBuild.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.loadingBanner = this.loadingBanner.bind(this);
        this.errorBanner = this.errorBanner.bind(this);
        this.loadBuild = this.loadBuild.bind(this);
    }

    componentDidMount() {
        this.loadBuild();
    }

    componentWillUnmount() {

    }

    loadBuild() {
        this.setState((_prevState, _opts) => {
            return {isLoading: true};
        });
        FirebuildContext.sendAuthenticated({type : "builds", subType: "get", buildId : this.props.urlParams.buildId}, (_err, _response) => {
            if (_err) {
                this.setState((_prevState, _props) => {
                    return {
                        error: {title: "Couldn't retrieve build", text: _err.code + ": " + _err.message},
                        isLoading: false
                    };
                });
            } else {
                this.setState((_prevState, _opts) => {
                    return {isLoading: false, build: _response.build};
                });
            }
        });
    }

    onDeleteBuild(_buildId) {
        this.setState((_prevState, _props) => {
            return {
                selectedBuildId : _buildId,
                modalType : "delete"
            }
        });
    }

    onEditBuild(_buildId) {
        this.setState((_prevState, _props) => {
            return {
                selectedBuildId : _buildId,
                modalType : "edit"
            }
        });
    }

    onModalClose(_shouldUpdate) {
        this.setState((_prevState, _props) => {
            return {
                selectedBuildId : "invalid",
                modalType : "invalid"
            }
        });
        if (_shouldUpdate) {
            this.setState((_prevState, _props) => {
                return {
                    build: null
                };
            });
            this.loadBuilds();
        }
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (
                <Alert color="info">
                    <FontAwesomeIcon icon={faSync} spin/>
                    <span>
                        <strong className="alert-heading"> Loading Builds</strong>&nbsp;Please wait...
                    </span>
                </Alert>
            );
        }
    }

    errorBanner() {
        if (this.state.error) {
            return (
                <Alert color="danger">
                    <i className="fa fa-times-circle mr-1 alert-icon" />
                    <span>
                        <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                    </span>
                </Alert>
            );
        }
    }

    render() {
        let selectedBuild;
        let buildUI;
        if (this.state.selectedBuildId && this.state.build) {
            selectedBuild = this.state.build;
        }
        if (this.state.build) {
            buildUI =(<FirebuildBuildEntry project={FirebuildContext.getProjectConfig(this.state.build.projectId)} build={this.state.build}
                                           canEdit={this.state.build.canEdit} canDelete={this.state.build.canDelete}
                                           onDelete={this.onDeleteBuild} onEdit={this.onEditBuild}
            />);
        }
        return (
            <Container>
                <HeaderMain className="mb-5 mt-4" title={this.props.urlParams.buildId} />
                <DeleteBuildModal isOpen={this.state.modalType === "delete"} build={selectedBuild} onClose={this.onModalClose}/>
                <EditBuildModal isOpen={this.state.modalType === "edit"} build={selectedBuild} onClose={this.onModalClose}/>
                {this.loadingBanner()}
                {this.errorBanner()}
                {buildUI}
            </Container>
        );
    }
}

export default withURLParams(FirebuildBuildPage);