import {HeaderMain} from "../routes/components/HeaderMain";
import {Col, Container, Row} from "reactstrap";
import React, {Fragment} from "react";
import TextDropdown from "../routes/Dashboards/Overview/TextDropdown";
import TimeFramePicker from "../routes/Dashboards/Overview/TimeFramePicker";
import FilterSelection from "./Filters/FilterSelection";
import {getDayWithOffset} from "../common/TimeUtil";
import {getDefaultGroup, getGroups, getGroupStatement} from "./Filters/GroupLogic";
import {withRouter} from "react-router";

function parseSearchParams(_text) {
    if(_text[0] === "?") {
        _text = _text.substr(1);
    }
    const tokens = _text.split("&");
    let params = {};
    for (let i = 0; i < tokens.length; i++) {
        let pt = tokens[i].split("=");
        if (pt.length === 1) {
            if (pt[0] && pt[0] !== "") {
                params[pt[0]] = true;
            }
        } else {
            params[pt[0]] = decodeURIComponent(pt[1]);
        }
    }
    return params;
}

function parseDate(_date) {
    const tokens = _date.split("-");
    const year = parseInt(tokens[0], 10);
    const month = parseInt(tokens[1], 10);
    const day = parseInt(tokens[2], 10);
    return new Date(year, month-1, day);
}

function convertToUTC(_date) {
    return new Date(Date.UTC(_date.getFullYear(), _date.getMonth(), _date.getDate()));
}
function withFilter(_wrappedComponent, _types, _titleFn) {
    let withTimePicker = false;
    let timePickerDays = 28;
    let withFilter = false;
    let withGroups = false;


    if (_types && Array.isArray(_types)) {
        for(let i = 0; i < _types.length; i++) {
            if (_types[i] === "time") {
                withTimePicker = true;
            }
            if (_types[i] === "time-1week") {
                withTimePicker = true;
                timePickerDays = 7;
            }
            if (_types[i] === "time-2week") {
                withTimePicker = true;
                timePickerDays = 14;
            }
            if (_types[i] === "filter") {
                withFilter = true;
            }
            if (_types[i] === "group") {
                withGroups = true;
            }
        }
    }

    return class extends React.Component {
        static getDerivedStateFromProps(_props, _state) {
            let path;
            if (_props.location)  {
                path = _props.location.pathname;
            }
            if (_state.path !== path) {
                let dateSelection =  {
                    start : getDayWithOffset(-timePickerDays),
                    end : getDayWithOffset(-1)
                };
                if (_props && _props.location && _props.location.search) {
                    const queryParams = parseSearchParams(_props.location.search);
                    if (queryParams.start && queryParams.end) {
                        dateSelection.start = parseDate(queryParams.start);
                        dateSelection.end = parseDate(queryParams.end);
                    }
                }
                return {
                    path: path,
                    updateCounter : _state.updateCounter+1,
                    dateSelection : dateSelection,
                    group: getDefaultGroup(),
                    filter : {},
                    extraOptions : {},
                };
            }
            return null;
        }
        constructor(_props) {
            super(_props);
            this.state = {
                updateCounter : 0,
                filter : {},
                dateSelection : {
                    start : getDayWithOffset(-timePickerDays),
                    end : getDayWithOffset(-1)
                },
                group : getDefaultGroup(),
                extraOptions : {},
                title : _titleFn(_props)
            };
            if (_props && _props.location && _props.location.search) {
                const queryParams = parseSearchParams(_props.location.search);
                if (queryParams.start && queryParams.end) {
                    this.state.dateSelection.start = new Date(parseInt(queryParams.start, 10));
                    this.state.dateSelection.end = new Date(parseInt(queryParams.end, 10));
                }
            }
            this.onDateSelectionChanged = this.onDateSelectionChanged.bind(this);
            this.onFilterUpdated = this.onFilterUpdated.bind(this);
            this.addExtraFilter = this.addExtraFilter.bind(this);
            this.changeTitle = this.changeTitle.bind(this);
            this.onExtraFilterChanged = this.onExtraFilterChanged.bind(this);
            this.onGroupChanged = this.onGroupChanged.bind(this);
        }

        onDateSelectionChanged(_event) {
            this.setState((_prevState, _props) => {
                return {dateSelection : _event, updateCounter : _prevState.updateCounter+1}
            });
        }

        onGroupChanged(_event) {
            this.setState((_prevState, _prop) => {
                return { group : _event, updateCounter: _prevState.updateCounter+1};
            });
        }

        onFilterUpdated(_filter) {
            this.setState((_prevState, _props) => {
                return {filter : _filter, updateCounter : _prevState.updateCounter+1}
            });
        }

        changeTitle(_title) {
            this.setState((_prevState, _props) => {
                return {title : _title};
            });
        }

        addExtraFilter(_data) {
            this.setState((_prevState, _props) => {
                let clone = JSON.parse(JSON.stringify(_prevState.extraOptions));
                for(let key in _data) {
                    let oldSelected;
                    if (clone[key] && clone[key].selected) {
                        oldSelected = clone[key].selected;
                    }
                    clone[key] = {
                        selected : oldSelected,
                        optionList : _data[key]
                    };
                }

                return {
                    extraOptions : clone,
                };
            });
        }

        onExtraFilterChanged(_key, _value) {
            this.setState((_prevState, _props) => {
                if (_prevState.extraOptions[_key] && _prevState.extraOptions[_key].selected === _value) {
                    return null;
                }
                let clone = JSON.parse(JSON.stringify(_prevState.extraOptions));
                if (clone[_key]) {
                    clone[_key].selected = _value;
                }
                return {
                    extraOptions : clone,
                    updateCounter : _prevState.updateCounter+1
                };
            });
        }


        render() {

            let extraFilterProps = {};

            let extraFilters = [];
            for(let key in this.state.extraOptions) {
                let options = this.state.extraOptions[key];
                extraFilters.push(
                    <Col key={this.state.path+"_"+key} lg={ 3 } md={ 3 }>
                        <TextDropdown options={options.optionList} selected={options.selected} onChange={(_val)=>{this.onExtraFilterChanged(key, _val);}}/>
                    </Col>
                );
                extraFilterProps[key] = this.state.extraOptions[key].selected;
            }

            let dateSelection = {
                start: convertToUTC(this.state.dateSelection.start),
                end : convertToUTC(this.state.dateSelection.end)
            };

            return (
                <Container>
                    <HeaderMain className="mb-5 mt-4" title={this.state.title} />
                    <Row>
                        {(()=>{
                            if (withTimePicker) {
                                return (
                                    <Col lg={ 8 } md={ 10}>
                                        <TimeFramePicker key={this.state.path} timeFrame={this.state.dateSelection} onChange={this.onDateSelectionChanged} />
                                    </Col>
                                );
                            }
                            return null;
                        })()}
                        {(()=>{
                            if (withGroups) {
                                return (
                                    <Col key={this.state.path} lg={ 3 } md={ 2 }>
                                        <TextDropdown options={getGroups()} selected={this.state.group} onChange={this.onGroupChanged}/>
                                    </Col>
                                );
                            }
                            return null;
                        })()}
                        {extraFilters}
                    </Row>
                    {(()=> {
                        if (withFilter) {
                            return (
                                <Row className="mt-2">
                                    <FilterSelection key={this.state.path} onChange={this.onFilterUpdated}/>
                                </Row>
                            );
                        }
                        return null;
                    })()}

                    <_wrappedComponent extraFilters={this.addExtraFilter} changeTitle={this.changeTitle} key={this.state.updateCounter}
                                       dateSelection={ withTimePicker ? dateSelection : undefined}
                                       filter={ withFilter ? this.state.filter : {}}
                                       group={ withGroups ? getGroupStatement(this.state.group) : undefined}
                                       {...extraFilterProps}
                                       {...this.props}/>
                </Container>
            );
        }
    }
}

export default withFilter;