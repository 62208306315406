import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import InstallsPie from "./Installs_Pie";
import RetentionLine from "./Retention_Line";
import SessionDurationPilLine from "./SessionDuration_Pil_Line";
import SessionCountPilLine from "./SessionCount_Pil_Line";

class AB_Users extends React.Component {
    constructor(_props) {
        super(_props);
    }

    render() {
        return(
            <Fragment>
                <Row>
                    <h3 className="text-center mt-3">User Statistics</h3>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <RetentionLine group="ab_group" filter={this.props.filter}/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <InstallsPie legend={true} group="ab_group" filter={this.props.filter}/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <InstallsPie group="country" filter={this.props.filter} maxCount={10}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <SessionDurationPilLine group="ab_group" filter={this.props.filter} average/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <SessionCountPilLine group="ab_group" filter={this.props.filter} average/>
                    </Col>
                </Row>
            </Fragment>
        );
    }

}


export default AB_Users;