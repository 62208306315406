import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker/es";
import {Button, Col, Container, Row} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class TimeFramePicker extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            start : _props.timeFrame.start,
            end : _props.timeFrame.end,
            backup : {
                start : _props.timeFrame.start,
                end : _props.timeFrame.end
            },
            hasChanges : false
        };

        this.onStartChanged = this.onStartChanged.bind(this);
        this.onEndChanged = this.onEndChanged.bind(this);
        this.onApply = this.onApply.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onStartChanged(_event) {
        this.setState({start : _event, hasChanges : true});
    }

    onEndChanged(_event) {
        this.setState({end : _event, hasChanges : true});
    }

    onApply(_event) {
        this.setState({
            hasChanges : false,
            backup : {
                start : this.state.start,
                end : this.state.end
            }
        });
        if (this.props.onChange && typeof this.props.onChange === "function") {
            this.props.onChange({start : this.state.start, end : this.state.end});
        }
    }

    onCancel(_event) {
        this.setState({
            start : this.state.backup.start,
            end : this.state.backup.end,
            hasChanges : false
        });
    }


    render() {
        return (
            <Fragment>
                <Row>
                    <Col md="4">
                        <p className="mb-1"><FontAwesomeIcon icon={faCalendarAlt} /> Start</p>
                    </Col>
                    <Col md="4">
                        <p className="mb-1"><FontAwesomeIcon icon={faCalendarAlt} /> End</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <DatePicker selected={this.state.start} onChange={this.onStartChanged}/>
                    </Col>
                    <Col md="4">
                        <DatePicker selected={this.state.end} onChange={this.onEndChanged}/>
                    </Col>
                    <Col md="2">
                        {
                            (() => {
                                if (this.state.hasChanges) {
                                    return (
                                        <Fragment>
                                            <Button color="success" size="sm" className="mr-1" onClick={this.onApply}><FontAwesomeIcon icon={faCheck}/></Button>
                                            <Button color="danger" size="sm" onClick={this.onCancel}><FontAwesomeIcon icon={faTimes}/></Button>
                                        </Fragment>
                                    );
                                } else {
                                    return null;
                                }
                            })()
                        }
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default TimeFramePicker;