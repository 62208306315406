import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import GraphUtil from "./Graphs/GraphUtil";

class Installs_Line {
    static getId() {
        return "Installs_Line";
    }

    static getGraphProps(_props) {
        return {
            title : "Installs",
            xAxis : "time",
            yAxis : "number",
            withSum : _props.withSum
        };
    }

    static getRequiredProps() {
        return ["startDate","endDate"];
    }

    static getGraphData(_props, _cb) {
        let ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        ctx.sendAsync("installs", {type:"analytics_v3", subType: "es_user_v3", group: ["install_date",_props.group], filter: mergedFilters});
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let report = GraphUtil.generateTimeSeries(_data.installs.result.result, "install_date","installs", _props.group);
                return _cb(null, report);
            }
        });
    }
}

export default CachedGraph(Installs_Line, LineGraph);