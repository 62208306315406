import AnalyticsContext from "../AnalyticsContext";
import {mergeFilters} from "../Filters/FilterLogic";
import CachedGraph from "./Graphs/CachedGraph";
import PieGraph from "./Graphs/PieGraph";

class Purchaser_Pie {
    static getId() {
        return "Purchaser_Pie";
    }

    static getGraphProps(_props) {
        return {
            title: "Purchaser",
            yAxis: "Users",
            small: true,
            legend: true
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if(_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        ctx.sendAsync("payer", {type: "analytics_v3", subType: "es_user_v3", group: _props.group, filter: mergeFilters(mergedFilters, {is_payer: true}) });
        ctx.sendAsync("nonPayer", {type: "analytics_v3", subType: "es_user_v3", group: _props.group, filter: mergeFilters(mergedFilters, {is_payer: false}) });
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let graphData = [{data: [
                    {name: "Payer", y: parseFloat(_data.payer.result.result[0].installs)},
                    {name: "Non-Payer", y: parseFloat(_data.nonPayer.result.result[0].installs)}
                    ]}];
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(Purchaser_Pie, PieGraph);