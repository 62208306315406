const groups = {
    "User Type" : "ua_type",
    "Platform" : "platform",
    "Install Version": "install_version"
};

function getGroups() {
    let groupList = [];
    for (let group in groups) {
        groupList.push(group);
    }
    return groupList;
}

function getGroupStatement(_group) {
    if (groups[_group]) {
        return groups[_group];
    }
    return null;
}

function getDefaultGroup() {
    return "User Type";
}

export {
    getGroups,
    getGroupStatement,
    getDefaultGroup
}