import React, {Fragment} from "react";
import AnalyticsContext from "../AnalyticsContext";
import {Col, Row} from "reactstrap";
import AdPlacementsPie from "./AdPlacements_Pie";
import AdPlacementViewRatesBar from "./AdPlacementViewRates_Bar";
import ErrorBannerUI from "../../common/ErrorBannerUI";
import LoadingBannerUI from "../../common/LoadingBannerUI";
import GraphCache from "./Graphs/GraphCache";

class AB_AdsDetailed extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : true
        }

    }

    componentDidMount() {
        const hash = GraphCache.getHash("AB_AdsDetailed", this.props);
        const cached = GraphCache.getData(hash);
        if (cached) {
            return this.setState((_state, _props) => {
                return {
                    isLoading: false,
                    abGroups : cached
                };
            });
        }
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("abGroups", {type : "analytics_v3", subType: "abGroups_v3", filter : this.props.filter});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return this.setState((_state, _props) => {
                    return {
                        isLoading: false,
                        abGroups: null,
                        error: {title : "Couldn't retrieve AB-Groups", text : _err.code+": "+_err.message}
                    };
                });
            }
            let groups = [];
            for(let i = 0; i < _data.abGroups.result.result.length; i++) {
                groups.push(_data.abGroups.result.result[i].ab_group);
            }
            groups.sort((_a,_b) => {return _a.localeCompare(_b)});
            GraphCache.storeData(hash, groups);
            return this.setState((_state, _props) => {
                return {
                    error: null,
                    isLoading: false,
                    abGroups: groups
                };
            });
        });
    }

    componentWillUnmount() {
    }

    render() {

        if (this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>)
        }
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Retrieving AB-Groups..."/>)
        }

        let rows = [];
        if (this.state.abGroups) {
            for (let i = 0; i < this.state.abGroups.length; i++) {
                const abGroup = this.state.abGroups[i];
                rows.push(
                <Row key={"header-"+i}>
                    <h5 className="text-center mt-3">{abGroup}</h5>
                </Row>);
                let abFilter = JSON.parse(JSON.stringify(this.props.filter));
                abFilter.ab_group = abGroup;
                rows.push(
                    <Row key={"entry-"+i}>
                        <Col lg={ 4} md={ 6 } className="mb-3">
                            <AdPlacementsPie filter={abFilter}/>
                        </Col>
                        <Col lg={ 8} md={ 6 } className="mb-3">
                            <AdPlacementViewRatesBar filter={abFilter}/>
                        </Col>
                    </Row>
                )
            }
        }

        return (
            <Fragment>
                {rows}
            </Fragment>
        );
    }
}

export default AB_AdsDetailed;