import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

import React from "react";
import EventBus from "./EventBus";

class HierarchyNode {
    constructor(_name, _icon, _link) {
        this._name = _name;
        this._icon = _icon;
        this._link = _link;
        this._childs = [];
        this._isSyncing = false
    }

    getName() { return this._name; }
    getIcon() {
        if (this._isSyncing) {
            return (<FontAwesomeIcon icon={faSync} pull="left" spin />);
        }
        else if (this._icon) {
            return (<FontAwesomeIcon icon={this._icon} pull="left" />);
        }
    }
    getLink() { return this._link; }

    addNode(_name, _icon, _link) {
        const subNode = new HierarchyNode(_name, _icon, _link);
        this._childs.push(subNode);
        //this._childs.sort((a, b) => a._name.localeCompare(b._name));
        //EventBus.notify("UPDATE_NAVIGATION", {});
        return subNode;
    }

    getNode(_name) {
        for(let i = 0; i < this._childs.length; i++) {
            if (this._childs[i]._name === _name) {
                return this._childs[i];
            }
        }
        return null;
    }

    redrawUI()  {
        EventBus.notify("UPDATE_NAVIGATION", {});
    }

    clearChilds() {
        this._childs = [];
        //EventBus.notify("UPDATE_NAVIGATION", {});
    }

    getChilds() {
        return this._childs;
    }

    setSyncMode(_val) {
        this._isSyncing = _val === true;
        EventBus.notify("UPDATE_NAVIGATION", {});
    };

    getRoutes(_prefix) {
        let routes = [];
        /*const path = _prefix+"/"+this._name.toLowerCase();
        if(this._component) {
            routes.push({path : path, component : this._component, params: this._params});
        }
        for(let i = 0; i < this._childs.length; i++) {
            const subRoutes = this._childs[i].getRoutes(path);
            for(let k = 0; k < subRoutes.length; k++) {
                routes.push(subRoutes[k]);
            }
        }*/
        return routes;
    }
}

const instance = new HierarchyNode("root");
export default instance;