
function getDayWithOffset(_offset) {
    let d = new Date();
    d.setDate(d.getDate() + _offset);
    return d;
}

function pad(num, size) {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

function formatDate(_date) {
    return pad(_date.getUTCFullYear(),4)+"-"+pad(_date.getUTCMonth()+1,2)+"-"+pad(_date.getUTCDate(),2);
}

function getUnixTime() {
    return Math.floor(Date.now() / 1000);
}

export {
    getDayWithOffset,
    formatDate,
    getUnixTime
};