import React from "react";
import EventBus from "../common/EventBus";
import MeteorContext from "../meteor/meteorContext";
import jwt from "jsonwebtoken";
import Hierarchy from "../common/Hierarchy";
import Routes from "../common/Routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'

import AnalyticsAdmin from "../routes/Pages/AnalyticsAdmin/AnalyticsAdmin";
import GameOverviewPage from "./GameOverviewPage";
import DisposableCallback from "../common/DisposableCallback";
import WSConnection from "../common/WSConnection";
import AnalyticsSubContext from "./AnalyticsSubContext";
import ABTestPage from "./ABTestPage";
import CampaignPage from "./CampaignPage";
import * as Config from "../config";
import AllCampaignPage from "./allCampaignPage";
import RevenuePage from "./RevenuePage";
import ErrorStatsPage from "./ErrorStatsPage";
import UsersPage from "./UsersPage";
import RatingsPage from "./RatingsPage";
import PnLPage from "./PnLPage";
import CampaignExportPage from "./CampaignExportPage";
import MaxImpressionsPage from "./MaxImpressionsPage";
import MaxCountryImpressionsPage from "./MaxCountryImpressionsPage";
import {getUnixTime} from "../common/TimeUtil";
import CampaignDetailPage from "./CampaignDetailPage";
import LRUMemCache from "../common/LRUMemCache";
import EventsPage from "./EventsPage";
import DebugEventsPage from "./DebugEventsPage";
import PrivacyPage from "./PrivacyPage";
import DevicesPage from "./DevicesPage";
import FunnelsPage from "./FunnelsPage";

const objectHash = require("object-hash");

let globalAsyncCallbacks = {};

let lruDataCache = new LRUMemCache("Analytics-Cache", 50*1024 * 1024); // 50MB

function handler(_ctx) {
    let wrapper = {};

    let tempData = {};
    let callback = null;

    function checkForCompletion() {
        if (callback === null) {
            return;
        }
        for(let key in tempData) {
            if (tempData[key].isRunning === true) {
                return;
            }
        }
        for(let key in tempData) {
            if (tempData[key].error) {
                return callback(tempData[key].error);
            }
        }

        return callback(null, tempData);
    }

    wrapper.sendAsync = (_id, _params) => {
        tempData[_id] = {isRunning : true};
        let hash = objectHash(_params);
        let needsSend = false;
        if (!globalAsyncCallbacks[hash]) {
            globalAsyncCallbacks[hash] = [];
            needsSend = true;
        }
        let callback =  (_err, _res) => {
            tempData[_id] = {isRunning: false, error: _err, result : _res};
            checkForCompletion();
        };
        globalAsyncCallbacks[hash].push(callback);
        if (needsSend) {
            const handler = (_err, _res) => {
                let callbackList = globalAsyncCallbacks[hash];
                Config.logDebug("Async-API","Calling "+callbackList.length+" callbacks : ", hash);
                delete globalAsyncCallbacks[hash];
                for(let i = 0; i < callbackList.length; i++) {
                    let errClone;
                    if (_err !== undefined) {
                        try {
                            errClone = JSON.parse(JSON.stringify(_err));
                        } catch (ex) {
                            Config.logErr("Async-API", "Could not clone ErrorResponse: ",_err);
                        }
                    }
                    let resClone;
                    if (_res !== undefined) {
                        try {
                            resClone = JSON.parse(JSON.stringify(_res));
                        } catch (ex) {
                            Config.logErr("Async-API", "Could not clone Response: ",_res);
                        }
                    }
                    callbackList[i](errClone, resClone);
                }
            }
            const cached = lruDataCache.get(hash);
            if (cached && _params.noCache !== true) {
                return handler(null, cached);
            }
            Config.logDebug("Async-API","Sending request: ", hash);
            if (Config.cachingEnabled === false) {
                _params.noCache = true;
            }
            return _ctx.sendAuthenticated(_params, (_err, _res) => {
                if (_err) {
                    return handler(_err, _res);
                }
                lruDataCache.store(hash, _res);
                return handler(_err, _res);
            });
        } else {
            Config.logDebug("Async-API","Attached to active request: ", hash);
        }
    };

    wrapper.waitForCompletion = (_cb) => {
        callback = _cb;
        checkForCompletion();
    }

    return wrapper;
}

class AnalyticsContext {
    constructor() {
        let url = "wss://analytics.coldfire.io/test";
        if (Config.useLiveEnvironment) {
            url = "wss://analytics.coldfire.io/live";
        }
        this._connection = new WSConnection(url);
        this._games = [];
        this._gameSettings = null;

        EventBus.registerHandler("METEOR_AUTHENTICATION_CHANGED", this.onMeteorAuthenticationChanged.bind(this));

        this.onMeteorAuthenticationChanged();
    }

    getAsyncContext() {
        return handler(this);
    }

    waitForAuth(_cb) {
        if (this.isAuthenticated()) {
            return _cb();
        } else {
            this._authCb = _cb;
        }
    }

    isAuthenticated() {
        if (this._token) {
            const decoded = jwt.decode(this._token);
            const now = Math.floor(new Date().getTime()/1000);
            if (decoded && decoded.exp && decoded.exp > now) {
                return true;
            }
        }
        return false
    };

    getPermissions() {
        if (this._token) {
            try {
                const decoded = jwt.decode(this._token);
                if (decoded && decoded.permissions) {
                    return decoded.permissions;
                }
            } catch(ex) {}
        }
        return {};
    }

    isAdmin() {
        const permissions = this.getPermissions();
        return permissions && permissions.admin === true;
    };

    hasPermission(_permission) {
        const permissions = this.getPermissions();
        return permissions && permissions[_permission] === true;
    }

    listUsers(_cb) {
        return this._connection.send({type : "auth", subType : "list", authToken : this._token}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, _response.users);
        });
    };

    changePermissions(_id, _permissions, _cb) {
        return this._connection.send({type : "auth", subType: "changePermission", authToken: this._token, sid: _id, permissions : _permissions}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb();
        });
    };

    getMeta(_cb) {
        return this._connection.send({type : "auth", subType : "getMeta", authToken : this._token}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, {validPermissions : _response.permissions});
        });
    };

    cancelRequest(_uuid) {
        this._connection.cancelRequest(_uuid);
    }

    getGameName(_id) {
        if (this._games) {
            for(let i = 0 ; i < this._games.length; i++) {
                if (this._games[i].id === _id) {
                    return this._games[i].name;
                }
            }
        }
        return _id;
    }

    getReportV2(_report, _group, _filter, _cb) {
        return this._connection.send({type : "analytics", subType: "report_v2", authToken : this._token, report : _report, group : _group, filter: _filter}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, _response.result);
        });
    }

    sendAuthenticated(_params, _cb) {
        let params = JSON.parse(JSON.stringify(_params));
        params.authToken = this._token;
        return this._connection.send(params, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.errorCode, message: _response.errorText});
            }
            return _cb(null, _response);
        });
    }

    onMeteorAuthenticationChanged() {
        let node = Hierarchy.getNode("Analytics");
        if (!node) {
            node = Hierarchy.addNode("Analytics",faChartBar);
        }
        node.clearChilds();
        node.setSyncMode(true);
        if(MeteorContext.isAuthenticated()) {
            const meteorToken = MeteorContext.getToken();
            this._connection.send({type: "auth", subType : "loginMeteor", authToken: meteorToken}, (_response) => {
                if (_response.type === "auth" && _response.subType === "authToken" && _response.token) {
                    this._token = _response.token;
                }
                return this._connection.send({type : "game", subType : "listConfig", authToken : this._token}, (_response) => {
                    if (_response.type === 'game' && _response.subType === 'listConfig' && _response.games) {
                        this._games = _response.games;
                    }
                    node = Hierarchy.getNode("Analytics");
                    Routes.addRoute("/analytics/campaigns", AllCampaignPage);
                    Routes.addRoute("/analytics/:gameId/overview", GameOverviewPage);
                    //Routes.addRoute("/analytics/:gameId/users", UsersPage);
                    //Routes.addRoute("/analytics/:gameId/revenue", RevenuePage);
                    //Routes.addRoute("/analytics/:gameId/errors", ErrorStatsPage);
                    //Routes.addRoute("/analytics/:gameId/ratings", RatingsPage);
                    Routes.addRoute("/analytics/:gameId/abtest", ABTestPage);
                    Routes.addRoute("/analytics/:gameId/campaigns", CampaignPage);
                    Routes.addRoute("/analytics/:gameId/pnl", PnLPage);
                    Routes.addRoute("/analytics/:gameId/events", EventsPage);
                    Routes.addRoute("/analytics/:gameId/funnels", FunnelsPage);
                    Routes.addRoute("/analytics/:gameId/privacy", PrivacyPage)
                    Routes.addRoute("/analytics/:gameId/devices/:platform", DevicesPage)
                    //Routes.addRoute("/analytics/:gameId/pnlcohort", PnLCohortPage);
                    Routes.addRoute("/analytics/:gameId/campaigns/:campaignId", CampaignDetailPage);
                    //Routes.addRoute("/analytics/:gameId/campaignsExport/:campaignId", CampaignExportPage);
                    Routes.addRoute("/analytics/:gameId/debugEvents", DebugEventsPage);
                    Routes.addRoute("/analytics/:gameId/max/:platform/impressions", MaxImpressionsPage);
                    Routes.addRoute("/analytics/:gameId/max/:platform/impressions/:country", MaxCountryImpressionsPage);
                    Routes.addRoute("/analytics/admin", AnalyticsAdmin);
                    node.addNode("All Campaigns", null, "/analytics/campaigns");
                    for(let i = 0 ; i < this._games.length; i++) {
                        const gameNode = node.addNode(this._games[i].name, null, null);

                        gameNode.addNode("Overview",null, "/analytics/"+this._games[i].id+"/overview");
                        gameNode.addNode("Events",null, "/analytics/"+this._games[i].id+"/events");
                        //gameNode.addNode("User",null, "/analytics/"+this._games[i].id+"/users");
                        //gameNode.addNode("Revenue",null, "/analytics/"+this._games[i].id+"/revenue");
                        gameNode.addNode("P&L Daily",null, "/analytics/"+this._games[i].id+"/pnl");
                        //gameNode.addNode("P&L Cohort",null, "/analytics/"+this._games[i].id+"/pnlcohort");
                        //gameNode.addNode("Errors",null, "/analytics/"+this._games[i].id+"/errors");
                        //gameNode.addNode("Ratings",null, "/analytics/"+this._games[i].id+"/ratings");
                        gameNode.addNode("Campaigns",null, "/analytics/"+this._games[i].id+"/campaigns");
                        gameNode.addNode("AB-Test",null, "/analytics/"+this._games[i].id+"/abtest");
                        gameNode.addNode("Funnels",null, "/analytics/"+this._games[i].id+"/funnels");
                        gameNode.addNode("Privacy",null, "/analytics/"+this._games[i].id+"/privacy");
                        let deviceNode = gameNode.addNode("Devices");
                        deviceNode.addNode("Devices Android", null, "/analytics/"+this._games[i].id+"/devices/android")
                        deviceNode.addNode("Devices iOS", null, "/analytics/"+this._games[i].id+"/devices/ios")
                        //gameNode.addNode("AB-Test",null, ABTestPage, {gameId : this._games[i].id, gameName : this._games[i].name})
                        if (this.hasPermission("raw-data-read")) {
                            gameNode.addNode("Debug-Events", null, "/analytics/"+this._games[i].id+"/debugEvents");
                        }
                        if(this.hasPermission("max-reports")) {
                            let maxNode = gameNode.addNode("MAX");
                            maxNode.addNode("Impressions iOS",null, "/analytics/"+this._games[i].id+"/max/ios/impressions");
                            maxNode.addNode("Impressions Android",null, "/analytics/"+this._games[i].id+"/max/android/impressions");
                        }
                    }
                    if (this.isAdmin()) {
                        node.addNode("Admin",null, "/analytics/admin");
                    }
                    //node.addNode("Test", null, TestPage);
                    node.setSyncMode(false);
                    Hierarchy.redrawUI();
                    if (this._authCb) {
                        let cb = this._authCb;
                        delete this._authCb;
                        cb();
                    }
                });
            });
        }
    }

    getSubContext() {
        return new AnalyticsSubContext(this);
    }

    getGameSettings(_gameId, _cb) {
        const retrieveGame = (_list) => {
            for(let i = 0; i < _list.length; i++) {
                if(_list[i].id === _gameId) {
                    return _list[i];
                }
            }
            return {};
        };
        if (this._gameSettings !== null) {
            return _cb(null, retrieveGame(this._gameSettings));
        } else {
            this._connection.send({type: "game", subType: "listSettings", authToken: this._token}, (_response) => {
                if (_response.type === "error") {
                    return _cb({code: _response.code, message: _response.errorText});
                }
                if (_response.type === "game" && _response.subType === "listSettings") {
                    this._gameSettings = _response.games;
                }
                return _cb(null, retrieveGame(this._gameSettings));
            })
        }
    }
}

const instance = new AnalyticsContext();
export default instance;