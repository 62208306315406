import React from 'react';
import { Link } from 'react-router-dom';

import {
    Navbar,
    Nav,
    NavItem
} from "reactstrap";

import { SidebarTrigger } from './../../components';

import { NavbarUser } from './NavbarUser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeteor } from '@fortawesome/free-solid-svg-icons'


export const DefaultNavbar = () => (
    <Navbar dark color="none" expand="xs">
        <Nav navbar>
            <NavItem className="mr-xl-3 mr-lg-3">
                <SidebarTrigger/>
            </NavItem>
            <NavItem className="navbar-brand d-lg-none">
                <Link to="/">
                    { /* START "SPIN" Logo (Navbar Version) */ }
                    <FontAwesomeIcon icon={faMeteor} />
                    <span className="h5 mb-0 fw-900 ml-2">Meteor</span>
                    { /* END "SPIN" Logo (Navbar Version) */ }
                </Link>
            </NavItem>
        </Nav>
        <Nav navbar className="ml-auto">
            <NavbarUser className="ml-2" />
        </Nav>
    </Navbar>
);
