import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import Privacy_Pie from "./Privacy_Pie";
import Privacy_Daily_Line from "./Privacy_Daily_Line";
import {mergeFilters} from "../Filters/FilterLogic";


class Privacy_Comp extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {};
    }

    render() {
        let organicFilter = mergeFilters(this.props.filter, {ua_type: "organic"});
        let nonOrganicFilter = mergeFilters(this.props.filter, {ua_type: "non-organic"});
        return (
            <Fragment>
                <Row>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <Privacy_Pie title={"organic"} filter={organicFilter} dateSelection={this.props.dateSelection}/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <Privacy_Pie title={"UA"} filter={nonOrganicFilter} dateSelection={this.props.dateSelection}/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <Privacy_Daily_Line filter={this.props.filter} dateSelection={this.props.dateSelection}/>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default Privacy_Comp;