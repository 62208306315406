import React from 'react';
import {Alert, Button, CardText, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import _ from 'lodash';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock, faCodeBranch, faHashtag, faSync, faUser} from "@fortawesome/free-solid-svg-icons";

import FirebuildContext from "./FirebuildContext";
import {faGitlab} from "@fortawesome/free-brands-svg-icons";

function format(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return _num;
}

function formatDate(_date) {
    return format(_date.getDate())+"."+format(_date.getMonth()+1)+"."+_date.getFullYear();
}

function formatTime(_date) {
    return format(_date.getHours())+":"+format(_date.getMinutes())+":"+format(_date.getSeconds());
}

class DeleteBuildModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLocked : false,
            error : null
        };

        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onCancel(event) {
        this.closeModal(false);
    }

    onDelete(event) {
        this.setState({isLocked: true});
        return FirebuildContext.sendAuthenticated({type: "builds",subType: "delete", buildId: this.props.build.buildId}, (_err) => {
            if(_err) {
                this.setState({
                    isLocked : false,
                    error : {
                        title : _err.code+": "+_err.message,
                        text : ""
                    }
                });
            } else {
                this.closeModal(true)
            }

        });
    }

    closeModal(_val) {
        this.setState(() => {return {isLocked: false, error: null}});
        if (this.props.onClose && typeof this.props.onClose === 'function') {
            this.props.onClose(_val);
        }
    }



    render() {
        const modalProps = _.omit(this.props, ['target']);
        if(!this.props.build) {
            return null;
        }
        const timestamp = new Date(this.props.build.timestamp * 1000);

        return (
            <Modal { ...modalProps } isOpen={ this.props.isOpen } toggle={ this.onCancel } >
                <ModalHeader tag="h6">
                    <span className="text-info">
                        Delete Build
                    </span>
                </ModalHeader>
                <ModalBody>
                    <span className="text-white ml-1">
                        Do you really want to delete Build: {this.props.build.buildId}
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faCalendar} /> &nbsp; {formatDate(timestamp)} &nbsp; &nbsp;
                        <FontAwesomeIcon icon={faClock} /> &nbsp; {formatTime(timestamp)}
                        <br/>
                        <FontAwesomeIcon icon={faCodeBranch}/> &nbsp; {this.props.build.branchName ? this.props.build.branchName : "N/A"} &nbsp; &nbsp;
                        <FontAwesomeIcon icon={faGitlab}/> &nbsp; {this.props.build.buildNumber ? this.props.build.buildNumber : "N/A"}
                        <br/>
                        <FontAwesomeIcon icon={faUser}/> &nbsp; {this.props.build.owner} &nbsp; &nbsp;
                        <FontAwesomeIcon icon={faHashtag}/> &nbsp; {this.props.build.scmHash} &nbsp; &nbsp;
                        <br/>
                        <br/>
                        {this.props.build.comment}
                    </span>
                    {
                        (() => {
                            if (this.state.error) {
                                return (
                                    <Alert color="danger">
                                        <i className="fa fa-times-circle mr-1 alert-icon"></i>
                                        <span>
                                            <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                                        </span>
                                    </Alert>
                                );
                            }
                        })()
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" tag = { Link } disabled={this.state.isLocked} onClick={this.onCancel}>
                        Cancel
                    </Button>
                    <Button color="info" tag={ Link }  disabled={this.state.isLocked} onClick={this.onDelete}>
                        {(() => {
                            if (this.state.isLocked) {
                                return (<FontAwesomeIcon icon={faSync} spin pull="left"/>);
                            }
                        })()}
                        { this.state.isLocked ? 'Deleting ...' : "Delete" }
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteBuildModal;