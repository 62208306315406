import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import RevenuePie from "./Revenue_Pie";
import LTVPilLine from "./LTV_Pil_Line";
import PurchaserBar from "./Purchaser_Bar";


class AB_Revenue extends React.Component {
    constructor(_props) {
        super(_props);
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <h3 className="text-center mt-3">Revenue Statistics</h3>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <LTVPilLine group="ab_group" filter={this.props.filter} average/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <PurchaserBar group="ab_group" filter={this.props.filter} />
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <RevenuePie group="ab_group" filter={this.props.filter} legend/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <LTVPilLine group="ab_group" filter={this.props.filter} onlyAds />
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <LTVPilLine group="ab_group" filter={this.props.filter} onlyIAP />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default AB_Revenue;