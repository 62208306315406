import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";

class TextDropdown extends React.Component {
    constructor(_props) {
        super(_props);

        this.onChanged = this.onChanged.bind(this);
    }

    onChanged(_key) {
        if (this.props.onChange && typeof this.props.onChange === "function") {
            this.props.onChange(_key);
        }
    }

    render() {
        let selected = this.props.selectText ? this.props.selectText : "Please select an option";
        let renderedOptions = [];
        for (let i = 0;i < this.props.options.length; i++) {
            const current = this.props.options[i];
            if (current === this.props.selected) {
                selected = this.props.selected;
            }
            renderedOptions.push(<DropdownItem key={current} onClick={()=>{this.onChanged(current);}}>{current}</DropdownItem>);
        }

        return (
            <UncontrolledButtonDropdown >
                <DropdownToggle caret color="secondary" outline>
                    {selected}
                </DropdownToggle>
                <DropdownMenu persist >
                    {renderedOptions}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }
}

export default TextDropdown;