import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router';

// ----------- Pages Imports ---------------
import OverviewDashboards from "./Dashboards/Overview/OverviewDashboards";
import Login from './Pages/Login';
import DefaultPage from "./Pages/DefaultPage";

// ----------- Layout Imports ---------------
import { DefaultNavbar } from './../layout/components/DefaultNavbar';
import { SidebarASidebar } from './../layout/components/SidebarASidebar';

import Hierarchy from "../common/Hierarchy";
import Routes from "../common/Routes";
import MeteorContext from "../meteor/meteorContext";
import EventBus from "../common/EventBus";

const PrivateRoute = ({ component: Component, extraParams: ExtraParams, ...rest }) => (
    <Route {...rest} render={(props) => (
        MeteorContext.isAuthenticated() === true
            ? <Component {...props} {...ExtraParams} />
            : <Redirect to='/pages/login' />
    )} />
)

//------ Route Definitions --------
// eslint-disable-next-line no-unused-vars
class MeteorRoutes extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = { counter : 1}

        this.onNavigationChanged = this.onNavigationChanged.bind(this);
    }

    onNavigationChanged(event) {
        let oldVal = this.state.counter;
        this.setState({ counter: oldVal +1});
    }

    componentDidMount() {
        EventBus.registerHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }

    componentWillUnmount() {
        EventBus.unregisterHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }


    render() {
        let routesList = Hierarchy.getRoutes("");
        let routes = [];
        for (let i = 0; i < routesList.length; i++) {
            const entry = routesList[i];
            routes.push(<PrivateRoute key={"route_"+i} path={entry.path.replace("/root","")} exact component={entry.component} extraParams={entry.params} />);
        }
        let pureRoutes = Routes.getRoutes();
        for (let i = 0; i < pureRoutes.length; i++) {
            const entry = pureRoutes[i];
            routes.push(<PrivateRoute key={"routepure_"+i} path={entry.path} exact component={entry.component} />);
        }

        return (
            <Switch>

                {routes}

                <Route path="/pages/login" component={ Login } />

                {(()=> {
                    if (!MeteorContext.isAuthenticated()) {
                        return (<Route component={ Login } />);
                    } else {
                        return (<Route component={ DefaultPage} />);
                    }
                })()}
            </Switch>
        );
    }
}

//------ Custom Layout Parts --------
const getNavbars = () => (
    <Switch>
        { /* Default Navbar: */}
        <Route
            component={ DefaultNavbar }
        />
    </Switch>
);

const getSidebars = () => (
    <Switch>
        { /* Other Sidebars: */}
        <Route
            component={ SidebarASidebar }
            path="/layouts/sidebar-a"
        />
        { /* Default Sidebar: */}
        <Route
            component={ SidebarASidebar }
        />
    </Switch>
);

export {
    MeteorRoutes,
    getNavbars,
    getSidebars
};
