import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import React from "react";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import StatisticUtil from "../StatisticUtil";
import GraphUtil from "./Graphs/GraphUtil";
import {mergeFilters} from "../Filters/FilterLogic";

class Privacy_Daily_Line {
    constructor() {
    }

    static getId() {
        return "Privacy_Daily_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "Privacy",
            xAxis: "time",
            yAxis: "number"
        };
    }

    static getRequiredProps() {
        return ["filter","dateSelection"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilter = mergeFilters(_props.filter, { day: { from : _props.dateSelection.start, to: _props.dateSelection.end}});
        ctx.sendAsync("users", {type:"analytics_v3", subType: "es_sessionDuration_v3", group:["limit_ad_tracking","day"], filter: mergedFilter});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let idfaEnabledData = AnalyticsUtil.filterBy(_data.users.result.result, (_entry) => _entry.limit_ad_tracking === false);
                let idfaEnabledReport = GraphUtil.generateNamedTimeseries("IDFA enabled", idfaEnabledData, "day", "users");

                let idfaDisabledData = AnalyticsUtil.filterBy(_data.users.result.result, (_entry) => _entry.limit_ad_tracking === true);
                let idfaDisabledReport = GraphUtil.generateNamedTimeseries("IDFA disabled", idfaDisabledData, "day", "users");

                let idfaUnknownData = AnalyticsUtil.filterBy(_data.users.result.result, (_entry) => _entry.limit_ad_tracking === null);
                let idfaUnknownReport = GraphUtil.generateNamedTimeseries("IDFA unknown", idfaUnknownData, "day", "users");

                let graphData = GraphUtil.mergeReports(idfaEnabledReport, idfaDisabledReport);
                graphData = GraphUtil.mergeReports(graphData, idfaUnknownReport);
                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(Privacy_Daily_Line, LineGraph);