import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import GraphUtil from "./Graphs/GraphUtil";

class SessionDuration_Pil_Line  {

    constructor() {
    }

    static getId() {
        return "SessionDuration_Pil_Line";
    }

    static getGraphProps(_props) {
        return {
            title: "Session Length",
            xAxis: "retention",
            yAxis: "time",
            withSum: _props.withSum
        };
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.install_date = {from: _props.startDate, to: _props.endDate};
        }
        ctx.sendAsync("duration", {type: "analytics_v3", subType: "es_sessionDuration_v3", group: ["install_date","day",_props.group], filter: mergedFilters });
        ctx.sendAsync("installs", {type: "analytics_v3", subType: "es_user_v3", group: ["install_date",_props.group], filter: mergedFilters });
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                const retData = AnalyticsUtil.generatePilStats(_data.duration.result.result, _data.installs.result.result, _props.group, "duration", 28,true);
                let graphData = GraphUtil.generateRetentionSeries(retData, 0, 28, false);
                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(SessionDuration_Pil_Line, LineGraph);