import AnalyticsContext from "../AnalyticsContext";
import ErrorBannerUI from "../../common/ErrorBannerUI";
import LineGraph from "./Graphs/LineGraph";
import React from "react";
import PropTypes from "prop-types";

class ErrorMetric_daily extends React.Component {
    constructor(_props) {
        super(_props);

        this.state  = {};

    }

    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
        let mergedFilters = JSON.parse(JSON.stringify(this.props.filter));
        mergedFilters.day = { from : this.props.startDate, to: this.props.endDate };
        this._ctx.getReportV2("errorMetric_daily", this.props.group, mergedFilters, (_err, _report) => {
            if (_err) {
                this.setState({error: {title: "errorMetric_daily", text: _err.code+": "+_err.message}});
            } else {
                this.setState({ data: _report });
            }
        });
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    render() {
        if (this.state.error) {
            return (<ErrorBannerUI error={this.state.error}/>);
        }
        return (<LineGraph title="Errors" xAxis="time" yAxis="number" data={this.state.data} withSum={this.props.withSum}/>);
    }
}

ErrorMetric_daily.propTypes = {
    startDate : PropTypes.string.isRequired,
    endDate : PropTypes.string.isRequired,
};

export default ErrorMetric_daily;