class KeyGenerator {
    constructor(_prefix) {
        this._prefix = _prefix;
        this._idx = 0;
    }

    getKey() {
        const id = this._idx;
        this._idx +=1;
        return this._prefix+"_"+id;
    }
}

export default KeyGenerator;