import React from 'react';
import "../../styles/toggles.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faUser} from '@fortawesome/free-solid-svg-icons'

import MeteorContext from "../meteorContext";
import {Alert, Button,Col, Container, Row} from "reactstrap";
import {HeaderMain} from "../../routes/components/HeaderMain";

import MeteorAdminUser from "./MeteorAdminUser";
import CreateUserModel from "./CreateUserModal";
import {Link} from "react-router-dom";

class MeteorAdmin extends React.Component {

    constructor(_props) {
        super(_props);
        this.state = {
            isLoading : false,
            createUserModelIsOpen : false,
            error : null,
            users : []
        };

        this.onRefresh = this.onRefresh.bind(this);
        this.onCreateUser = this.onCreateUser.bind(this);
        this.onCreateUserClosed = this.onCreateUserClosed.bind(this);
    }

    componentDidMount() {
        this.onRefresh();
    }

    onRefresh() {
        this.setState({isLoading : true, users: []});
        MeteorContext.listUsers((_err, _users) => {
            if (_err) {
                this.setState({error: {title : "Couldn't retrieve Users", text : _err.code+": "+_err.message}, isLoading : false});
            } else {
                MeteorContext.ensureValidPermissions(() => {
                    this.setState({users: _users, isLoading: false});
                });
            }
        });
    }

    onCreateUser() {
        this.setState({createUserModelIsOpen : true});
    }

    onCreateUserClosed() {
        this.setState({createUserModelIsOpen : false});
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (
                <Alert color="info">
                    <FontAwesomeIcon icon={faSync} spin/>
                    <span>
                        <strong className="alert-heading"> Loading User Data</strong>Please wait...
                    </span>
                </Alert>
            );
        }
    }

    errorBanner() {
        if (this.state.error) {
            return (
                <Alert color="danger">
                    <i className="fa fa-times-circle mr-1 alert-icon" />
                    <span>
                        <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                    </span>
                </Alert>
            );
        }
    }

    userCard(_user) {
        return (
            <Row className="mb-5" key={"userid_"+_user.id}>
                <Col lg={ 12 }>
                    <MeteorAdminUser user={_user} />
                </Col>
            </Row>
        );
    }

    render() {
        let users = [];
        if (this.state.users && this.state.users.length > 0) {
            for(let i = 0; i < this.state.users.length; i++) {
                users.push(this.userCard(this.state.users[i]));
            }
        }

        return (
            <Container>
                <CreateUserModel isOpen={this.state.createUserModelIsOpen} onClose={this.onCreateUserClosed}/>
                <HeaderMain className="mb-5 mt-4" title="Meteor Users" />
                <Row>
                    <Col lg={ 3 }>
                        <Button color="secondary" className="text-left" block tag={ Link } onClick={this.onRefresh} disabled={this.state.isLoading}>
                            <FontAwesomeIcon icon={faSync} pull="left" />Refresh
                        </Button>
                        <Button color="secondary" className="text-left"  block tag={ Link } onClick={this.onCreateUser} disabled={this.state.isLoading}>
                            <FontAwesomeIcon icon={faUser} pull="left" />Create User
                        </Button>
                    </Col>
                    <Col lg={ 6 }>
                        {this.loadingBanner()}
                        {this.errorBanner()}
                        {users}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default MeteorAdmin;