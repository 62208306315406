import EventBus from "../common/EventBus";
import jwt from "jsonwebtoken";
import Hierarchy from "../common/Hierarchy";
import Routes from "../common/Routes";

import { faMeteor } from '@fortawesome/free-solid-svg-icons'

import MeteorAdmin from "./MeteorAdmin/MeteorAdmin";
import WSConnection from "../common/WSConnection";
import * as Config from "../config";

class MeteorContext{
    constructor() {
        let url = "wss://meteor-api.coldfire.io/live";
        if (Config.isDebug) {
            url = "wss://meteor-api.coldfire.io/test";
        }
        this._connection = new WSConnection(url);
        this._validPermissions = null;

        this.updateNavigation();
    }

    updateNavigation() {
        let node = Hierarchy.getNode("Meteor");
        if (!node) {
            node = Hierarchy.addNode("Meteor", faMeteor);
        }
        node.clearChilds();
        Routes.addRoute("/meteor/admin", MeteorAdmin);
        if (this.isAdmin()) {
            node.addNode("Admin",null, "/meteor/admin");
        }
        Hierarchy.redrawUI();
    }

    getToken() {
        return localStorage.getItem("meteor_auth_token");
    }

    setToken(_token) {
        localStorage.setItem("meteor_auth_token", _token);
    }

    getPermissions() {
        const token = this.getToken();
        if (token) {
            const decoded = jwt.decode(token);
            if (decoded && decoded.permissions) {
                return decoded.permissions;
            }
        }
        return {};
    }

    isAdmin() {
        const permissions = this.getPermissions();
        return permissions && permissions.admin === true;
    }

    ensureValidPermissions(_cb) {
        if (this._validPermissions !== null) {
            return _cb(null);
        }
        if (!this.isAdmin()) {
            return _cb(null);
        }
        return this._connection.send({type : "auth", subType : "getMeta", authToken : this.getToken()}, (_response) => {
            if (_response.type === "auth" && _response.subType === "meta") {
                this._validPermissions = _response.permissions;
            }
            return _cb(null);
        });
    };

    getValidPermissions = (_cb) => {
        if (this._validPermissions !== null) {
            return this._validPermissions;
        }
        return [];
    };

    isAuthenticated() {
        const token = this.getToken();
        if (token) {
            const decoded = jwt.decode(token);
            const now = Math.floor(new Date().getTime()/1000);
            if (decoded && decoded.exp && decoded.exp > now) {
                return true;
            }
        }
        return false
    };

    authenticate(_user, _pass, _cb) {
        return this._connection.send({type : "auth", subType : "login", userId : _user, password : _pass}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            if (_response.type === "auth" && _response.subType === "authToken" && _response.token) {
                this.setToken(_response.token);
                EventBus.notify("METEOR_AUTHENTICATION_CHANGED", {});
                EventBus.notify("UPDATE_NAVIGATION", {});
            }
            this.updateNavigation();
            return _cb(null, true);
        });
    };

    changePassword(_newPass, _cb) {
        return this._connection.send({type : "auth", subType : "changePassword", authToken : this.getToken(), password : _newPass}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, true);
        });
    };

    setPassword(_userId, _newPass, _cb) {
        return this._connection.send({type : "auth", subType : "setPassword", authToken : this.getToken(), userId : _userId, password : _newPass}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, true);
        });
    };

    createUser(_userId, _password, _cb) {
        return this._connection.send({type : "auth", subType : "createUser", authToken : this.getToken(), userId : _userId, password : _password}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, true);
        });
    };

    listUsers(_cb) {
        return this._connection.send({type : "auth", subType : "list", authToken : this.getToken()}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, _response.users);
        });
    };

    changeLockState(_userId, _locked, _cb) {
        return this._connection.send({type : "auth", subType : "lockUser", authToken : this.getToken(), userId : _userId, locked: _locked}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null);
        });
    };

    changePermissions(_userId, _permissions, _cb) {
        return this._connection.send({type : "auth", subType : "changePermission", authToken : this.getToken(), userId : _userId, permissions: _permissions}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null);
        });
    };

    deleteUser(_userId, _cb) {
        return this._connection.send({type : "auth", subType : "deleteUser", authToken : this.getToken(), userId : _userId}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null);
        });

    };

    logout() {
        this.setToken("");
        this.updateNavigation();
        EventBus.notify("METEOR_AUTHENTICATION_CHANGED", {});
    };

}

const instance = new MeteorContext();
export default instance;