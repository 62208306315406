import React, {Fragment} from "react";
import {Alert, Col, Row} from "reactstrap";
import AnalyticsContext from "./AnalyticsContext";
import {formatDate} from "../common/TimeUtil";
import LoadingBannerUI from "../common/LoadingBannerUI";
import withURLParams from "../common/SiteWithUrlParams";
import withFilter from "./SiteWithFilter";
import CampaignPLDetailedCohortBar from "./Components/CampaignPLDetailed_Cohort_Bar";
import RetentionLine from "./Components/Retention_Line";
import AdImpressionsPilLine from "./Components/AdImpressions_Pil_Line";
import SessionDurationPilLine from "./Components/SessionDuration_Pil_Line";
import LTVPilLine from "./Components/LTV_Pil_Line";
import AdLatencyDailyLine from "./Components/AdLatency_Daily_Line";
import {mergeFilters} from "./Filters/FilterLogic";
import FPSDailyLine from "./Components/FPS_Daily_Line";
import AdImpressionCohortLine from "./Components/AdImpression_Cohort_Line";
import SessionDurationCohortLine from "./Components/SessionDuration_Cohort_Line";
import PurchaserPie from "./Components/Purchaser_Pie";
import RevenueSplitPie from "./Components/RevenueSplit_Pie";
import PurchaseCountPilBar from "./Components/PurchaseCount_Pil_Bar";
import CampaignPLDetailedROAS_Line from "./Components/CampaignPLDetailedROAS_Line";

class CampaignDetailPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading : true,
            error : null,
            updateCounter : 1,
            campaignData : null,
            campaignName : _props.urlParams.campaignId,
        };

        this.loadingBanner = this.loadingBanner.bind(this);
        this.errorBanner = this.errorBanner.bind(this);
    }


    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    loadingBanner() {
        if (this.state.isLoading) {
            return (<LoadingBannerUI text="Loading Campaign"/>);
        }
        return null;
    }


    errorBanner() {
        if(this.state.error) {
            return (
                <Alert color="danger">
                    <i className="fa fa-times-circle mr-1 alert-icon" />
                    <span>
                        <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                    </span>
                </Alert>
            );
        }
        else return null;
    }

    render() {
        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);
        const campaignId = this.props.urlParams.campaignId;
        const filter = mergeFilters({ua_campaign : this.props.urlParams.campaignId}, this.props.filter);
        return (
            <Fragment>
                <Row className="mt-3">
                    <Col lg={ 12} md={ 12 } className="mb-3">
                        <CampaignPLDetailedCohortBar pils={[0,3,7]} from={startDate} to={endDate} campaignId={campaignId} filter={filter}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={ 6} md={ 6 } className="mb-3">
                        <CampaignPLDetailedROAS_Line pils={[0,3,7]} from={startDate} to={endDate} campaignId={campaignId} filter={filter}/>
                    </Col>
                    <Col lg={ 6} md={ 6 } className="mb-3">
                        <CampaignPLDetailedCohortBar pils={[0,3,7]} byUser from={startDate} to={endDate} campaignId={campaignId} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <RetentionLine startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <LTVPilLine startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <LTVPilLine onlyAds startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <LTVPilLine onlyIAP startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <AdImpressionsPilLine startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <AdImpressionCohortLine startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <SessionDurationPilLine startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <SessionDurationCohortLine startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <PurchaserPie startDate={startDate} endDate={endDate} filter={filter} legend/>
                    </Col>
                    <Col lg={ 3} md={ 6 } className="mb-3">
                        <RevenueSplitPie startDate={startDate} endDate={endDate} filter={filter} legend/>
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <PurchaseCountPilBar startDate={startDate} endDate={endDate} filter={filter}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <AdLatencyDailyLine startDate={startDate} endDate={endDate} filter={filter} />
                    </Col>
                    <Col lg={ 6} md={ 12 } className="mb-3">
                        <FPSDailyLine startDate={startDate} endDate={endDate} filter={filter} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(CampaignDetailPage,["time", "filter"], (_props)=> {return "Campaign Detail: "+_props.queryParams.name;}));