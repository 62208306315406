import AnalyticsContext from "../AnalyticsContext";
import LineGraph from "./Graphs/LineGraph";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import GraphUtil from "./Graphs/GraphUtil";
import StatisticUtil from "../StatisticUtil";

class LTV_Pil_Line {
    static getId() {
        return "LTV_Pil_Line";
    }

    static getGraphProps(_props) {
        let title = "LTV"
        if (_props.onlyAds) {
            title += " (Ads)";
        }
        if (_props.onlyIAP) {
            title += " (IAP)";
        }
        return {
            title: title,
            xAxis : "retention",
            yAxis : "euro",
            withSum : _props.withSum
        }
    }

    static getRequiredProps() {
        return ["filter"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        let mergedFilters = JSON.parse(JSON.stringify(_props.filter));
        if (_props.startDate && _props.endDate) {
            mergedFilters.install_date = { from : _props.startDate, to: _props.endDate };
        }
        const all = (_props.onlyAds === undefined && _props.onlyIAP === undefined);
        if (all || _props.onlyAds) {
            ctx.sendAsync("ad", {type: "analytics_v3", subType: "es_adImpression_v3", group: ["install_date","day",_props.group], filter: mergedFilters });
        }
        if(all || _props.onlyIAP) {
            ctx.sendAsync("iap", {type: "analytics_v3", subType: "es_premiumPurchase_v3", group: ["install_date","day",_props.group], filter: mergedFilters });
        }
        ctx.sendAsync("installs", {type: "analytics_v3", subType: "es_user_v3", group: ["install_date",_props.group], filter: mergedFilters });
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return _cb(_err);
            } else {
                let rev;
                if (all) {
                    rev = AnalyticsUtil.join(_data.ad.result.result, _data.iap.result.result);
                    rev = AnalyticsUtil.aggregateBy(rev,["install_date","day", _props.group],"revenue", StatisticUtil.sum);
                }
                if (_props.onlyAds) {
                    rev = _data.ad.result.result;
                }
                else if (_props.onlyIAP) {
                    rev = _data.iap.result.result;
                }
                const retData = AnalyticsUtil.generatePilStats(rev, _data.installs.result.result, _props.group, "revenue", 28,true);
                let graphData = GraphUtil.generateRetentionSeries(retData, 0, 28, false);

                return _cb(null, graphData);
            }
        });
    }

}

export default CachedGraph(LTV_Pil_Line, LineGraph)