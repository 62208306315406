import React, {Fragment} from "react";
import AnalyticsContext from "./AnalyticsContext";
import {formatDate } from "../common/TimeUtil";
import withURLParams from "../common/SiteWithUrlParams";
import withFilter from "./SiteWithFilter";
import AllCampaignPL from "./Components/AllCampaignPL";

class AllCampaignPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
        };

    }

    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
    }

    componentWillUnmount() {
        this._ctx.clear();
    }

    render() {
        let from = formatDate(this.props.dateSelection.start);
        let to = formatDate(this.props.dateSelection.end);

        return (
            <Fragment>
                <AllCampaignPL from={from} to={to}/>
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(AllCampaignPage,["time"], (_props)=> {return "All Campaigns P&L";}));