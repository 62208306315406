import {Alert} from "reactstrap";
import React from "react";

function ErrorBannerUI(_props) {
    return (
        <Alert color="danger">
            <i className="fa fa-times-circle mr-1 alert-icon" />
            <span>
                <strong className="alert-heading">{_props.error.title}</strong> {_props.error.text}
            </span>
        </Alert>
    );
}

export default ErrorBannerUI;