import React, {Fragment} from "react";
import {Alert, Button} from "reactstrap";
import AnalyticsContext from "./AnalyticsContext";
import {formatDate} from "../common/TimeUtil";
import KeyGenerator from "../common/KeyGenerator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import withURLParams from "../common/SiteWithUrlParams";
import withFilter from "./SiteWithFilter";
import {mergeFilters} from "./Filters/FilterLogic";
import Table from "reactstrap/es/Table";
import TextDropdown from "../routes/Dashboards/Overview/TextDropdown";

const { Parser } = require('json2csv');

function formatTime(_seconds) {
    if (!_seconds) {
        _seconds = 0;
    }
    _seconds = Math.trunc(_seconds);
    let hours   = Math.floor(_seconds / 3600);
    let minutes = Math.floor((_seconds - (hours * 3600)) / 60);
    let seconds = _seconds - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (hours > 0) {
        return hours+'h '+minutes+'m '+seconds+'s';
    } else {
        return minutes+'m '+seconds+'s';
    }
}



class CampaignExportPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            updateCounter : 1,
            campaignData : null,
            campaignName : _props.urlParams.campaignId,
        };

        this.onCategoryChanged = this.onCategoryChanged.bind(this);
        this.updateExportSettings = this.updateExportSettings.bind(this);
        this.retrieveSiteIds = this.retrieveSiteIds.bind(this);
        this.doExport = this.doExport.bind(this);
        this.drawTable = this.drawTable.bind(this);
        this.downloadContent = this.downloadContent.bind(this);
        this.clearTasks = this.clearTasks.bind(this);
        this.startTask = this.startTask.bind(this);
        this.markTasksCompleted = this.markTasksCompleted.bind(this);
    }

    downloadContent() {
        let atag = document.createElement("a");
        let group = "siteId";
        if (this.state.category === "Country") {
            group = "country";
        }
        const fields = [group,"users",
            "retentionD1","retentionD3","retentionD7","retentionD14","retentionD28",
            "adImpressionD0","adImpressionD3","adImpressionD7","adImpressionD14","adImpressionD28",
            "adLtvD0","adLtvD3","adLtvD7","adLtvD14","adLtvD28",
            "iapLtvD0","iapLtvD3","iapLtvD7","iapLtvD14","iapLtvD28",
            "ltvD0","ltvD3","ltvD7","ltvD14","ltvD28",
            "sessionLengthD0","sessionLengthD3","sessionLengthD7","sessionLengthD14","sessionLengthD28",
            "adEngagement","iapEngagement"
        ];
        const json2csvParser = new Parser({ fields });
        let clone = JSON.parse(JSON.stringify(this.state.exportData));
        for(let i = 0; i < clone.length; i++) {
            let line = clone[i];
            for(let key in line) {
                if (key.indexOf("sessionLength") === 0) {
                    line[key] /= 60;
                }
            }
        }
        const csv = json2csvParser.parse(clone);
        let data = "sep=,\n"+csv.toString();

        let file = new Blob([data], {type: 'text/csv'});
        atag.href = URL.createObjectURL(file);
        atag.download = this.props.urlParams.campaignId+".csv";
        atag.click();
    }
    componentDidMount() {
        this._ctx = AnalyticsContext.getSubContext();
    }

    clearTasks() {
        this.setState((_prevState, _props) => {
            let tasks = {};
            if (_prevState.tasks) {
                tasks = JSON.parse(JSON.stringify(_prevState.tasks));
            }
            tasks.working = false;
            tasks.log = [];
            tasks.error = null;
            return {tasks : tasks};
        });
    }

    startTask(_text) {
        this.setState((_prevState, _props) => {
            let tasks = {};
            if (_prevState.tasks) {
                tasks = JSON.parse(JSON.stringify(_prevState.tasks));
            }
            tasks.working = true;
            if (!tasks.log) {
                tasks.log = [];
            }
            tasks.log.push(_text);
            tasks.error = null;
            return {tasks : tasks};
        });
    }

    markTasksCompleted() {
        this.setState((_prevState, _props) => {
            let tasks = {};
            if (_prevState.tasks) {
                tasks = JSON.parse(JSON.stringify(_prevState.tasks));
            }
            tasks.working = false;
            return {tasks : tasks};
        });
    }

    doExport() {
        this.clearTasks();

        let retrieveData = (_ref, _cb) => {
            return _ref.retrieveRetentionData("Retention", 0,"retention","retention",{},()=> {
                return _ref.retrieveRetentionData("Ad Impressions", 0,"adImpression_pil_avg","adImpression",{} ,()=> {
                    return _ref.retrieveRetentionData("Session Length", 0,"sessionDuration_pil_avg","sessionLength",{},()=> {
                        return _ref.retrieveRetentionData("LTV", 0,"ltv_pil_avg","ltv",{},()=> {
                            return _ref.retrieveRetentionData("Ad LTV", 0, "ltv_pil_avg","adLtv",{revenueType: "ad"},()=> {
                                return _ref.retrieveRetentionData("IAP LTV", 0,"ltv_pil_avg","iapLtv",{revenueType:"iap"},()=> {
                                    return _ref.retrieveRetentionData(" Ad Engagement", 0, "adViewer","adEngagement",{},()=> {
                                        return _ref.retrieveRetentionData("IAP Conversion", 0, "purchaser","iapEngagement",{},()=> {
                                            _ref.markTasksCompleted();
                                            return _cb();
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        };

        this.updateExportSettings();
        if (this.state.category === "Country") {
            this.startTask("Retrieve Countries ...");
            return this.retrieveCountries(() => {
                return retrieveData(this,() => {});
            });
        } else {
            this.startTask("Retrieve SiteIds ...");
            return this.retrieveSiteIds(() => {
                return retrieveData(this, () => {});
            });
        }
    }

    onCategoryChanged(_type) {
        if ((!this.state.tasks || this.state.tasks.working !== true) && !this.state.exportData) {
            this.setState((_prevState, _props) => {
                return {category: _type};
            });
        }
    }

    updateExportSettings() {
        const startDate = formatDate(this.props.dateSelection.start);
        const endDate = formatDate(this.props.dateSelection.end);
        const mergedFilter = mergeFilters({game_id : this.props.urlParams.gameId, ua_campaign: this.props.urlParams.campaignId, install_date : {from: startDate, to: endDate}}, this.props.filter);
        this._exportFilter = mergedFilter;
    }

    retrieveSiteIds(_cb) {
        this.updateExportSettings();
        // update status

        this._ctx.sendAuthenticated({subType: "campaignSiteId", filter: this._exportFilter}, (_err, _response) => {
            if (_err) {
                this.setState({
                    error: {title: "Couldn't retrieve Campaigns", text: _err.code + ": " + _err.message}
                });
            } else {
                this.setState((_prevState, _props) => {
                    let data = [];
                    for (let i = 0; i < _response.result.length; i++) {
                        let current = _response.result[i];
                        data.push({siteId: current.siteId, users: current.userCount});
                    }
                    return {exportData : data}
                });
            }
            return _cb();
        });
    }

    retrieveCountries(_cb) {
        this.updateExportSettings();
        // update status

        this._ctx.sendAuthenticated({subType: "campaignCountry", filter: this._exportFilter}, (_err, _response) => {
            if (_err) {
                this.setState({
                    error: {title: "Couldn't retrieve Campaigns", text: _err.code + ": " + _err.message}
                });
            } else {
                this.setState((_prevState, _props) => {
                    let data = [];
                    for (let i = 0; i < _response.result.length; i++) {
                        let current = _response.result[i];
                        data.push({country: current.country, users: current.userCount});
                    }
                    return {exportData : data}
                });
            }
            return _cb();
        });
    }

    retrieveRetentionData(_name, _retryCount, _reportName, _propertyName,_extraFilter, _cb) {
        if (_retryCount > 0) {
            this.startTask("Retrieve "+_name+"... (Retry "+_retryCount+")");
        } else {
            this.startTask("Retrieve "+_name+"...");
        }
        let filter = JSON.parse(JSON.stringify(this._exportFilter));
        if(_extraFilter) {
            filter = mergeFilters(filter, _extraFilter);
        }
        let group = "ua_adset";
        if (this.state.category === "Country") {
            group = "country";
        }
        this._ctx.getReportV2(_reportName, group, filter, (_err, _report) => {
            if (_err) {
                return this.retrieveRetentionData(_name, _retryCount +1, _reportName, _propertyName, _extraFilter, _cb);
            } else {
                let property = "siteId";
                if (this.state.category === "Country") {
                    property = "country";
                }
                this.setState((_prevState, _props) => {
                    let data = JSON.parse(JSON.stringify(_prevState.exportData));
                    function findeEntry(_siteId) {
                        for(let i = 0; i < data.length; i++) {
                            if(data[i][property] === _siteId) {
                                return data[i];
                            }
                        }
                    }
                    if(_report.length === 1 && _report[0].data && Array.isArray(_report[0].data) && !_report[0].name) {
                        _report = _report[0].data;
                    }
                    for (let i = 0; i < _report.length; i++) {
                        let current = _report[i];
                        let entry = findeEntry(current.name);
                        if(entry) {
                            if(current.data) {
                                for (let k = 0;k < current.data.length; k++) {
                                    const retEntry = current.data[k];
                                    entry[_propertyName+"D"+retEntry.x] = retEntry.y;
                                }
                            }
                            if (current.y) {
                                entry[_propertyName] = current.y;
                            }
                        }
                    }
                    return {exportData : data}
                });
            }
            return _cb();
        });

    }


    componentWillUnmount() {
        this._ctx.clear();
    }

    drawTable() {
        let tableData = [];
        let property = "siteId";
        let propertyName = "Site Id";
        if (this.state.category === "Country") {
            property = "country";
            propertyName = "Country";
        }
        if (this.state.exportData &&  this.state.exportData.length > 0) {
            for(let i = 0; i < this.state.exportData.length; i++) {
                const current = this.state.exportData[i];
                tableData.push(
                    <tr className="text-white">
                        <td>{current[property]}</td>
                        <td>{current.users}</td>
                        <td>{current.retentionD1}%</td>
                        <td>{current.retentionD3}%</td>
                        <td>{current.retentionD7}%</td>
                        <td>{current.adImpressionD0}</td>
                        <td>{current.adImpressionD3}</td>
                        <td>{current.adImpressionD7}</td>
                        <td>{formatTime(current.sessionLengthD0)}</td>
                        <td>{formatTime(current.sessionLengthD3)}</td>
                        <td>{formatTime(current.sessionLengthD7)}</td>
                        <td>{current.adEngagement}%</td>
                        <td>{current.iapEngagement}%</td>
                    </tr>
                )
            }
        } else {
            return null;
        }
        return (
            <Table className="table">
                <thead>
                    <tr>
                        <th colSpan={2}></th>
                        <th colSpan={3}>Retention</th>
                        <th colSpan={3}>Ad Impressions</th>
                        <th colSpan={3}>Session Length</th>
                        <th colSpan={2}>Conversion</th>
                    </tr>
                    <tr>
                        <th>{propertyName}</th>
                        <th>Users</th>
                        <th>D1</th>
                        <th>D3</th>
                        <th>D7</th>
                        <th>D0</th>
                        <th>D3</th>
                        <th>D7</th>
                        <th>D0</th>
                        <th>D3</th>
                        <th>D7</th>
                        <th>Ads</th>
                        <th>IAP</th>
                    </tr>
                </thead>
                <tbody>
                {tableData}
                </tbody>
            </Table>
        )
    }

    drawTaskState() {
        let lines = [];
        if (this.state.tasks) {
            const tasks = this.state.tasks;
            if (tasks.log) {
                const logs = tasks.log;
                for (let i = 0; i < logs.length; i++) {
                    let icon = (<FontAwesomeIcon icon={faCheckCircle} />);
                    if (tasks.working === true && i === logs.length-1) {
                        icon = (<FontAwesomeIcon icon={faSync} spin/>);
                    }
                    lines.push(
                        <Fragment>
                            {icon}
                            <span>
                                <strong className="alert-heading">&nbsp;&nbsp;{logs[i]}</strong>
                            </span>
                            <br/>
                        </Fragment>
                    )
                }
            }
            if (tasks.working !== true) {
                lines.push(
                    <Fragment>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span>
                            <strong className="alert-heading">&nbsp;&nbsp;Done</strong>
                        </span>
                        <br/>
                    </Fragment>
                );
            }
        }
        if (lines.length === 0) {
            lines.push(
                <Fragment>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span>
                        <strong className="alert-heading">&nbsp;&nbsp;Ready</strong>
                    </span>
                </Fragment>
            )
        }
        return (
            <Alert color="info">
                {lines}
            </Alert>
        )
    }

    render() {

        const keyGen = new KeyGenerator("campaigns_"+this.state.updateCounter);

        let retrieveButton = null;
        let downloadButton = null;
        if ((!this.state.tasks || this.state.tasks.working !== true) && !this.state.exportData) {
            retrieveButton = (<Button color="primary" onClick={this.doExport}>Retrieve Data</Button>);
        }
        if (this.state.tasks && this.state.tasks.working !== true && this.state.exportData) {
            downloadButton = (<Button color="primary" onClick={this.downloadContent}>Download</Button>);
        }
        return (
            <Fragment>
                <br/>
                <TextDropdown options={["Site_Id","Country"]} selected={this.state.category} onChange={this.onCategoryChanged} />
                <br/>
                <br/>
                {this.drawTaskState()}
                {retrieveButton}{downloadButton}
                <br/>
                <br/>
                <br/>
                {this.drawTable()}
            </Fragment>
        );
    }
}

export default withURLParams(withFilter(CampaignExportPage,["time", "filter"], (_props)=> {return "Campaign Export: "+_props.urlParams.campaignId;}));