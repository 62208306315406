import BarGraph from "./Graphs/BarGraph";
import AnalyticsContext from "../AnalyticsContext";
import CachedGraph from "./Graphs/CachedGraph";
import AnalyticsUtil from "../AnalyticsUtil";
import StatisticUtil from "../StatisticUtil";

class CampaignPL_Bar {
    static getId() {
        return "CampaignPL_Bar";
    }

    static getGraphProps(_props) {
        return {
            title: "Spend & Revenue",
            xAxis: "none",
            yAxis: "euro",
            small: true
        };
    }

    static getRequiredProps() {
        return ["from","to","campaignId"];
    }

    static getGraphData(_props, _cb) {
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("iap",{type: "analytics_v3", subType: "es_adImpression_v3", group: ["install_date"],filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.sendAsync("ad",{type: "analytics_v3", subType: "es_premiumPurchase_v3", group: ["install_date"], filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.sendAsync("cpi",{type: "analytics_v3", subType: "es_user_v3", group: ["install_date"], filter : {ua_campaign : _props.campaignId, install_date : {from: _props.from, to: _props.to}}});
        ctx.waitForCompletion((_err, _data) => {
            if(_err) {
                return _cb(_err);
            } else {
                const revenueData = AnalyticsUtil.join(_data.ad.result.result, _data.iap.result.result);
                const revenue = AnalyticsUtil.aggregateAll(revenueData, "revenue", StatisticUtil.sum);
                const cost = AnalyticsUtil.aggregateAll(_data.cpi.result.result, "cpi", StatisticUtil.sum);
                let graphData = [];
                graphData.push({name : "Cost", data : [{y: cost}]});
                graphData.push({name : "Revenue", data : [{y: revenue}]});
                return _cb(null, graphData);
            }
        });
    }
}

export default CachedGraph(CampaignPL_Bar, BarGraph);