import React, {Fragment} from "react";
import AnalyticsContext from "../AnalyticsContext";
import LoadingBannerUI from "../../common/LoadingBannerUI";
import ErrorBannerUI from "../../common/ErrorBannerUI";
import AnalyticsUtil from "../AnalyticsUtil";
import GraphCache from "./Graphs/GraphCache";
import BarGraph from "./Graphs/BarGraph";
import {Col, Row} from "reactstrap";

class Events_Pil_Component extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoading: true
        };

        this.setError = this.setError.bind(this);
    }

    setError(_title, _text) {
        return this.setState(() => {
            return { error: {title: _title, text: _text}};
        });
    }

    componentDidMount() {
        const hash = GraphCache.getHash("Events_Pil_Component", this.props);
        const cached = GraphCache.getData(hash);
        if (cached) {
            return this.setState(() => {
                return {
                    isLoading: false,
                    graphData: cached
                };
            });
        }
        if (!this.props.filter) {
            return this.setError("Graph Error", "Missing filter property!");
        }
        if (!this.props.startDate) {
            return this.setError("Graph Error", "Missing startDate property!");
        }
        if (!this.props.endDate) {
            return this.setError("Graph Error","Missing endDate property!");
        }
        if (!this.props.eventName || this.props.eventName === "") {
            return this.setError("Graph Error","Missing eventName property!");
        }
        let filter = JSON.parse(JSON.stringify(this.props.filter));
        filter.install_date = { from: this.props.startDate, to: this.props.endDate};
        filter.name = this.props.eventName;
        const ctx = AnalyticsContext.getAsyncContext();
        ctx.sendAsync("events", {type : "analytics_v3", subType: "es_event_v3", group: ["install_date","day","name"], filter: filter});
        ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                return this.setError("API Error", JSON.stringify(_err));
            }

            let pilEvents = AnalyticsUtil.addPil(_data.events.result.result);
            let distinctEvents = AnalyticsUtil.getDistinct(pilEvents, "name");
            distinctEvents.sort((a,b) => {
                if (a.length !== b.length) {
                    return a.length-b.length;
                }
                return a.localeCompare(b)
            });
            let graphData = [];
            for (let eventIdx = 0; eventIdx < distinctEvents.length; eventIdx++) {
                const eventName = distinctEvents[eventIdx];
                let filtered = AnalyticsUtil.filterBy(pilEvents, (_e) => _e.name === eventName);
                let pilStats = [];
                for(let i = 0; i <= 28; i++) {
                    pilStats[i] = 0;
                }
                for(let i = 0; i < filtered.length; i++) {
                    let pil = filtered[i].pil;
                    if (pil >= 0 && pil <= 28) {
                        pilStats[pil] += filtered[i].value;
                    }
                }
                let series = {name: eventName, data : []};
                for(let i = 0; i < pilStats.length; i++) {
                    series.data.push({x:i, y: pilStats[i]});
                }
                graphData.push({name: eventName, data: series});
            }
            GraphCache.storeData(hash, graphData);
            return this.setState(() => {
                return {
                    isLoading: false,
                    graphData: graphData
                };
            });
        });
    }

    render() {
        if (this.state.error) {
            return (<ErrorBannerUI error={this.state.error} />);
        }
        let loadingBanner = null;
        if (this.state.isLoading) {
            loadingBanner = (<LoadingBannerUI text={"Retrieving Event data..."} />);
        }


        let graphs = [];
        if (this.state.graphData) {
            for(let i = 0; i < this.state.graphData.length; i++) {
                let current = this.state.graphData[i];
                graphs.push(
                    <Row key={"graph_"+i}>
                        <Col lg={ 8} md={ 12 } className="mb-3">
                            <BarGraph title={current.name} xAxis={"retention"} yAxis={"number"} data={[current.data]}/>
                        </Col>
                    </Row>);
            }
        }


        return (<Fragment>
            {loadingBanner}
            {graphs}
            </Fragment>
        );
    };


}

export default Events_Pil_Component;