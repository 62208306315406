import React from 'react';
import { hot } from 'react-hot-loader'
import { BrowserRouter as Router} from 'react-router-dom';

import AppLayout from './../../layout/default';
import { MeteorRoutes } from './../../routes';

const AppClient = () => {
    return (
        <Router>
            <AppLayout>
                { <MeteorRoutes />}
            </AppLayout>
        </Router>
    );
}

export default hot(module)(AppClient);