import React from 'react';
import { Link } from 'react-router-dom';

import {
    Form,
    FormGroup,
    Input,
    Button,
    Label,
} from "reactstrap";

import {
    EmptyLayout,
} from './../../../components';

import { HeaderAuth } from "../../components/Pages/HeaderAuth";
import { FooterAuth } from "../../components/Pages/FooterAuth";


import {Redirect} from "react-router";

import MeteorContext from "../../../meteor/meteorContext";
import {Alert} from "reactstrap";

class Login extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            userName : "",
            password : "",
            isLoggingIn : false,
            error : null
        };

        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    handleChangeUsername(event) {
        this.setState({userName : event.target.value});
    }
    handleChangePassword(event) {
        this.setState({password : event.target.value});
    }

    handleLogin(event) {
        this.setState({isLoggingIn : true, error : null});
        MeteorContext.authenticate(this.state.userName, this.state.password, (_err) => {
            let error = null;
            if(_err) {
                error =  {title : "Login failed!"};
                if (_err.code === 401) {
                    error.text = _err.message;
                } else {
                    error.text = _err.code +": "+_err.message;
                }
            } else if (!MeteorContext.isAuthenticated()) {
                error = {title : "Login failed!", text : "Please try again and check local time settings."};
            }
            this.setState({isLoggingIn : false, error : error});
        });
    }

    render() {
        if (MeteorContext.isAuthenticated()) {
            return (
                <Redirect to="/" />
            );
        }
        return(
            <EmptyLayout>
                <EmptyLayout.Section center>
                    { /* START Header */}
                    <HeaderAuth
                        title="Sign In to Meteor"
                    />

                    {
                        (() => {
                            if (this.state.error) {
                                return (
                                    <Alert color="danger">
                                        <i className="fa fa-times-circle mr-1 alert-icon"></i>
                                        <span>
                                            <strong className="alert-heading">{this.state.error.title}</strong> {this.state.error.text}
                                        </span>
                                    </Alert>
                                );
                            }
                        })()
                    }
                    { /* END Header */}
                    { /* START Form */}
                    <Form className="mb-3">
                        <FormGroup>
                            <Label for="emailAdress">
                                User Id
                            </Label>
                            <Input type="email" name="email" id="emailAdress" placeholder="Enter userId..." value={this.state.userName} onChange={this.handleChangeUsername} disabled={this.state.isLoggingIn} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">
                                Password
                            </Label>
                            <Input type="password" name="password" id="password" placeholder="Password..." value={this.state.password} onChange={this.handleChangePassword} disabled={this.state.isLoggingIn} />
                        </FormGroup>
                        <div className="mb-2" >&nbsp; </div>
                        <Button color="primary" block  onClick={this.handleLogin} disabled={this.state.isLoggingIn}>
                            {(() => {
                                if (this.state.isLoggingIn) {
                                    return (<i className="fa fa-fw fa-refresh" style={{animation: "fa-spin 1.5s linear infinite"}} />);
                                }
                            })()}
                            { this.state.isLoggingIn ? 'Signing in ...' : "Sign In" }
                        </Button>
                    </Form>
                    <div className="mb-4 text-center">
                    </div>
                    { /* END Form */}
                    { /* START Bottom Links */}
                    { /* END Bottom Links */}
                    { /* START Footer */}
                    <FooterAuth />
                    { /* END Footer */}
                </EmptyLayout.Section>
            </EmptyLayout>
        );

    }
}

export default Login;
