import React from 'react';
import { render } from 'react-dom';

import { App } from './components';

import Hierarchy from "./common/Hierarchy";
import AnalyticsContext from "./analytics/AnalyticsContext";
import FirebuildContext from "./firebuild/FirebuildContext";

require("element-qsa-scope");


render(
    <App.Client />,
    document.querySelector('#root')
);